<!-- Entête de la page -->
<header class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <h2 class="page-header__title">Projet</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <!-- Message d'information read only -->
  <div class="card--info card--blue" *ngIf="showReadOnlyMessage()">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    Vous êtes en lecture seule sur cette page. Vous ne pouvez pas éditer son contenu.
  </div>

  <lib-card-info
    *ngIf="isProjectUpdatableByUser && demandesComplementsEncours?.length"
    color="yellow"
    message="Informations sur les documents complémentaires."
    content='Des documents complémentaires vous ont été demandés. Vous pouvez les ajouter dans la section "documents complémentaires" en bas de page.'
  ></lib-card-info>

  <!-- Section informations générales  -->
  <section class="page-section">
    <lib-card-title title="Informations générales"></lib-card-title>

    <lib-projet-infos-generales
      [projet]="projet"
      [showCard]="!showDiv"
      [inputText]="'Vous devez renseigner le titre, l\'acronyme et la description du projet'"
      [isProjectUpdatableByUser]="isProjectUpdatableByUser"
      (goToProjetInfoFormEmitter)="onGoToProjetInfoGene()"
    >
    </lib-projet-infos-generales>
  </section>
  <!-- FIN de Section informations générales -->

  <!-- Section Enquête -->
  <section class="page-section" *ngIf="this.aap.enqueteProjetPresent">
    <h3 class="page-section__title">Enquête projet</h3>
    <div
      id="card-enquete"
      class="card card--message cursor-pointer"
      (click)="onGoToEnquete()"
      [ngClass]="{
        'card--warning': this.aap.enqueteProjetObligatoire && !projetValidationFunction.enqueteIsCorrect(projet)
      }"
      [class.card--inactive]="!isProjectUpdatableByUser"
    >
      <ng-container *ngIf="!projetValidationFunction.enqueteIsCorrect(projet); else enqueteComplete">
        <p class="card__title">Vous {{ this.aap.enqueteProjetObligatoire ? 'devez' : 'pouvez' }} renseigner l'enquête du projet</p>
      </ng-container>
      <ng-template #enqueteComplete>
        <div id="enquete-complete-container">
          <lib-pxl-icon class="green-checkmark" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
          <p class="card__title">Enquête projet renseignée</p>
        </div>
      </ng-template>

      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatableByUser"
      ></lib-pxl-icon>
      <lib-pxl-icon class="icon visibility_icon" icon="icon-visibility" aria-hidden="true" *ngIf="!isProjectUpdatableByUser"></lib-pxl-icon>
    </div>
  </section>
  <!-- Fin de section thématiques  -->

  <!-- Section thématiques  -->
  <section class="page-section" *ngIf="this.aap.thematiquesPresent">
    <h3 class="page-section__title">Thématique</h3>
    <div
      id="card-info"
      class="card card--message"
      *ngIf="!projetValidationFunction.thematiquesIsCorrect(projet)"
      (click)="onGoToThematiqueInfo()"
      [ngClass]="{ 'card--warning': this.aap.thematiquesObligatoire }"
      [class.card--inactive]="!isProjectUpdatableByUser"
    >
      <p class="card__title">Vous {{ this.aap.thematiquesObligatoire ? 'devez' : 'pouvez' }} renseigner la thématique de votre projet</p>
      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatableByUser"
      ></lib-pxl-icon>
    </div>
    <div
      id="thematique-info"
      class="card card--message thematique_card_overwrite"
      *ngIf="projetValidationFunction.thematiquesIsCorrect(projet)"
      (click)="onGoToThematiqueInfo()"
      [class.card--inactive]="!isProjectUpdatableByUser"
    >
      <div class="div_noms_thematique_choisie">
        <span class="div_thematique_item_name">
          {{ thematique.libelleThematique }}
        </span>
      </div>
      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatableByUser"
      ></lib-pxl-icon>
    </div>
  </section>
  <!-- Fin de section thématiques  -->

  <!-- Section section sous-domaines  -->
  <section class="page-section" *ngIf="this.aap.domainePresent">
    <h3 class="page-section__title">Domaine(s) technologique(s)</h3>
    <div
      id="card-info"
      class="card card--message"
      (click)="onGoToDomainesInfo()"
      [ngClass]="{ 'card--warning': this.aap.domaineObligatoire }"
      [class.card--inactive]="!isProjectUpdatableByUser"
      *ngIf="!projetValidationFunction.domainesTechnologiquesIsCorrect(projet)"
    >
      <p class="card__title">
        Vous {{ this.aap.domaineObligatoire ? 'devez' : 'pouvez' }} renseigner le(s) domaine(s) technologique(s) de votre projet
      </p>
      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatableByUser"
      ></lib-pxl-icon>
    </div>
    <div
      id="domaines-info"
      class="card card--message domaines_card_overwrite"
      [class.card--inactive]="!isProjectUpdatableByUser"
      *ngIf="projetValidationFunction.domainesTechnologiquesIsCorrect(projet)"
      (click)="onGoToDomainesInfo()"
    >
      <div class="div_noms_domaines_choisis">
        <ng-container *ngFor="let domaine of this.domaines">
          <span *ngFor="let sousDomaine of domaine.sousDomaines" class="div_domaine_item_name">
            {{ sousDomaine.libelleSousDomaine }}
          </span>
        </ng-container>
      </div>
      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatableByUser"
      ></lib-pxl-icon>
    </div>
  </section>
  <!-- Fin de section sous-domaines  -->

  <!-- Section section application  -->
  <section class="page-section" *ngIf="this.aap.secteursPresent">
    <h3 class="page-section__title">Secteur(s) d'application(s)</h3>
    <div
      id="card-info"
      class="card card--message"
      *ngIf="!projetValidationFunction.secteursApplicationIsCorrect(projet)"
      (click)="onGoToSecteursInfo()"
      [ngClass]="{ 'card--warning': this.aap.secteursObligatoire }"
      [class.card--inactive]="!isProjectUpdatableByUser"
    >
      <p class="card__title">
        Vous {{ this.aap.secteursObligatoire ? 'devez' : 'pouvez' }} renseigner le(s) secteur(s) d'application(s) de votre projet
      </p>
      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatableByUser"
      ></lib-pxl-icon>
    </div>
    <div
      id="secteurs-info"
      class="card card--message secteurs_card_overwrite"
      *ngIf="projetValidationFunction.secteursApplicationIsCorrect(projet)"
      (click)="onGoToSecteursInfo()"
      [class.card--inactive]="!isProjectUpdatableByUser"
    >
      <div class="div_noms_secteurs_choisis">
        <ng-container *ngFor="let secteur of this.secteurs">
          <span *ngFor="let sousSecteur of secteur.sousSecteurs" class="div_secteur_item_name">
            {{ sousSecteur.libelleSousSecteur }}
          </span>
        </ng-container>
      </div>
      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatableByUser"
      ></lib-pxl-icon>
    </div>
  </section>
  <!-- Fin de section section application  -->

  <!-- Section grille d'impacts  -->
  <section class="page-section" *ngIf="isKpiProjetNeeded">
    <h3 class="page-section__title">Grille d'impacts du projet</h3>
    <div
      id="card-info"
      class="card card--message cursor-pointer"
      (click)="onGoToGrilleImpacts()"
      [ngClass]="{ 'card--warning': !isGrilleImpactsValid }"
      [class.card--inactive]="!isProjectUpdatableByUser"
    >
      <ng-container *ngIf="!isGrilleImpactsValid; else kpiComplete">
        <p class="card__title">Vous devez renseigner les éléments de la grille d'impacts</p>
      </ng-container>
      <ng-template #kpiComplete>
        <div id="kpi-complete-container">
          <lib-pxl-icon class="green-checkmark" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
          <p class="card__title">Grille d'impacts du projet renseignée</p>
        </div>
      </ng-template>

      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatableByUser && isGrilleImpactUpdatable"
      ></lib-pxl-icon>
      <lib-pxl-icon
        class="icon visibility_icon"
        icon="icon-visibility"
        aria-hidden="true"
        *ngIf="!isProjectUpdatableByUser || !isGrilleImpactUpdatable"
      ></lib-pxl-icon>
    </div>
  </section>
  <!-- Fin de section grille d'impacts  -->

  <!-- Section fiche de com  -->
  <section class="page-section" *ngIf="aap.ficheComPresent">
    <h3 class="page-section__title">Fiche pour communication publique</h3>
    <div
      id="card-info"
      class="card card--message cursor-pointer"
      (click)="onGoToFicheComInfo()"
      [ngClass]="{ 'card--warning': !isFicheComValid }"
      [class.card--inactive]="!isProjectUpdatableByUser"
    >
      <ng-container *ngIf="!isFicheComValid; else ficheComComplete">
        <p class="card__title">Vous devez renseigner les éléments de la fiche de communication</p>
      </ng-container>
      <ng-template #ficheComComplete>
        <div id="fiche-com-complete-container" class="d-flex align-items-center">
          <lib-pxl-icon class="green-checkmark" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
          <p class="card__title">Fiche de communication renseignée</p>
        </div>
      </ng-template>

      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatableByUser"
      ></lib-pxl-icon>
      <lib-pxl-icon class="icon visibility_icon" icon="icon-visibility" aria-hidden="true" *ngIf="!isProjectUpdatableByUser"></lib-pxl-icon>
    </div>
  </section>
  <!-- Fin de section fiche de com  -->

  <section class="page-section">
    <h3 class="page-section__title">Budget(s)</h3>
    <!-- Si le budget est manquant -->
    <section *ngIf="this.aap.budgetEstime">
      <div
        id="budget-info"
        class="card card--message"
        (click)="etapeProjet === EnumProjetEtape.PRE_DEPOT ? onGoToBudgetInfo() : null"
        *ngIf="!projetValidationFunction.isBudgetSet(projet)"
        [ngClass]="{ 'card--alert': !projet?.budget }"
        [class.card--inactive]="!isProjectUpdatableByUser"
      >
        <p class="card__title">Vous devez renseigner l'estimation de budget du projet</p>
        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isProjectUpdatableByUser"
        ></lib-pxl-icon>
      </div>
      <!-- Si toutes les informations sont remplies -->
      <div
        id="budget-div"
        class="card card--generic-content"
        [class.card--inactive]="!isProjectUpdatableByUser || !(etapeProjet === EnumProjetEtape.PRE_DEPOT)"
        *ngIf="projetValidationFunction.isBudgetSet(projet)"
        (click)="etapeProjet === EnumProjetEtape.PRE_DEPOT ? onGoToBudgetInfo() : null"
      >
        <div class="card__content-wrapper project-info">
          <div class="projet-info-budget__main">
            <h4 class="projet-info-budget__label">Budget présenté estimé (pré-dépôt)</h4>
            <p class="projet-info-budget__value">{{ projet?.budget?.montant | currency : 'EUR' : 'symbol' : '1.0-0' }}</p>
          </div>
        </div>

        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isProjectUpdatableByUser && etapeProjet === EnumProjetEtape.PRE_DEPOT"
        >
        </lib-pxl-icon>
      </div>
    </section>

    <div
      id="budget-declare-div"
      class="card card--generic-content card--inactive"
      *ngIf="etapeProjet === EnumProjetEtape.DEPOT || !this.aap.budgetEstime"
      (click)="projet.partenaireType === EnumTypePartenaire.INDIV ? onGoToBudgetChefDeFile() : null"
      [ngClass]="{ clickable: projet.partenaireType === EnumTypePartenaire.INDIV }"
    >
      <div class="card__content-wrapper project-info">
        <div class="projet-info-budget__main">
          <h4 class="projet-info-budget__label">Budget présenté</h4>
          <p class="projet-info-budget__value">{{ declaredBudgetSum | currency : 'EUR' : 'symbol' : '1.0-0' }}</p>
        </div>
        <br />
        <ng-container *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI">
          <ng-container *ngFor="let structure of structureList">
            <div class="clickable" (click)="onGoToBudgetStructure(structure.id)">
              <h4 class="projet-info-budget__labels">
                {{ structure.raisonSocial }}
                <ng-container *ngIf="structure.budgetDepot?.besoin === false">
                  <span class="card__tag card__tag_budget"> Ne demande pas de financement </span>
                </ng-container>
              </h4>

              <p class="projet-info-budget__values" *ngIf="structure.budgetDepot?.montant">
                {{ structure.budgetDepot.montant }} € ({{ calculatePurcent(structure.budgetDepot.montant) }}%)
              </p>
              <p class="projet-info-budget__values" *ngIf="structure.budgetDepot?.besoin === null">non déclaré</p>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </section>

  <!-- Section dépenses  -->
  <section class="page-section" *ngIf="etapeProjet === EnumProjetEtape.DEPOT && this.aap.depensesProjetPresent">
    <h3 class="page-section__title">Dépenses du projet</h3>
    <div
      id="card-info"
      class="card card--message"
      (click)="onGoToExpensesInfo()"
      [ngClass]="{ 'card--warning': !isExpensesValid }"
      [class.card--inactive]="!isProjectUpdatableByUser"
    >
      <ng-container *ngIf="!isExpensesValid; else expensesComplete">
        <p class="card__title">Vous devez renseigner les dépenses de votre projet</p>
      </ng-container>
      <ng-template #expensesComplete>
        <div id="expenses-complete-container">
          <lib-pxl-icon class="green-checkmark" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
          <p class="card__title">Dépenses du projet renseignée</p>
        </div>
      </ng-template>

      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatableByUser"
      ></lib-pxl-icon>
    </div>
  </section>
  <!-- Fin de section dépenses  -->

  <section class="page-section" *ngIf="this.aap.polePresent">
    <h3 class="page-section__title">Pôle(s) de compétitivité</h3>
    <!-- Si les poles ne sont pas encore séléctionnés -->
    <div
      id="poles-info"
      class="card card--message"
      *ngIf="this.poles.length < 1"
      (click)="onGoToPolesInfo()"
      [ngClass]="{ 'card--warning': this.aap.poleObligatoire }"
      [class.card--inactive]="!isProjectUpdatableByUser"
    >
      <p class="card__title">Vous {{ this.aap.poleObligatoire ? 'devez' : 'pouvez' }} renseigner le ou les pôles du projet</p>
      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatableByUser"
      ></lib-pxl-icon>
    </div>

    <!-- Si les poles sont séléctionnés -->
    <ng-container *ngIf="this.poles.length > 0">
      <p class="info-size p-document-size">Poids maximum par fichier {{ FILE_SIZE_LIMIT }}Mo</p>

      <p class="document-size-align">L’ajout d’une lettre de labélisation est optionnel</p>
      <div id="poles-info" class="card card--message poles_card_overwrite" (click)="onGoToPolesInfo()">
        <div class="div_noms_poles_choisis">
          <div *ngFor="let pole of this.poles" class="div_pole_item_name">
            <span>{{ pole.nomCommercial | uppercase }}</span>
          </div>
        </div>
        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isProjectUpdatableByUser"
        ></lib-pxl-icon>
      </div>

      <!-- Carte liste 'Document' -->
      <ng-container *ngFor="let document of listDocumentPoles">
        <div class="card card--list-file" [ngClass]="{ 'card--warning': isScanedDocument(document) && !isValidDocument(document) }">
          <p class="card__title" *ngIf="!document.id && document.nom">
            <lib-pxl-icon class="icon" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></lib-pxl-icon>
            Présentation projet
          </p>

          <p class="card__title" *ngIf="document.id">
            <lib-pxl-icon class="icon" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></lib-pxl-icon>
            {{ getDocumentTypeLabel(document.type) }} {{ getPoleName(document.poleId) | uppercase }}
          </p>

          <div class="card__text">
            <span class="card__doc--name card--document--name--overwrite">
              {{ document.nom }}
            </span>

            <br />
            Chargé le {{ document.dateCreation | date : 'dd/MM/yyyy' }} {{ getNomCreateurDocument(document) }}
          </div>
          <div class="card--actions">
            <ng-container>
              <div class="card__action-button-wrapper">
                <button
                  class="card__action-button"
                  [ngClass]="{
                    'card__action-button--error': isScanedDocument(document) && !isValidDocument(document)
                  }"
                  *ngxPermissionsOnly="['PROJET_READ']"
                  (click)="downloadDocument(document)"
                  [disabled]="!isValidDocument(document) || !isScanedDocument(document)"
                >
                  <lib-pxl-icon class="icon" style="height: 23px" icon="icon-action-save-alt" aria-hidden="true"></lib-pxl-icon>
                </button>
                <div class="card__action-button-tooltip" *ngIf="!isScanedDocument(document)">
                  <p>Le test antimalware est en cours.</p>
                </div>
                <div class="card__action-button-tooltip" *ngIf="isScanedDocument(document) && !isValidDocument(document)">
                  <p>Ce fichier a été identifié comme étant potentiellement dangereux et ne peut donc pas être téléchargé.</p>
                </div>
              </div>
            </ng-container>
            <ng-container *ngxPermissionsOnly="['PROJET_WRITE']">
              <div class="card__action-button-wrapper action-button-delete_overwrite">
                <button
                  class="card__action-button"
                  *ngIf="isProjectUpdatableByUser"
                  (click)="onDeleteDocument(document)"
                  [disabled]="document.etape !== sharedFunction.getProjectEtapeName(projet)"
                >
                  <lib-pxl-icon class="icon" icon="icon-action-delete" aria-hidden="true"></lib-pxl-icon>
                </button>
                <div class="card__action-button-tooltip" *ngIf="document.etape !== sharedFunction.getProjectEtapeName(projet)">
                  <p>Vous ne pouvez pas supprimer ce fichier à cette étape.</p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngFor="let pole of this.poles; let i = index">
        <div
          class="card card--list-file"
          *ngIf="!pole.hasDoc"
          [ngClass]="{
            'card--alert': pole.fileTooBig || pole.fileNotSupported
          }"
        >
          <p class="card__title">
            <lib-pxl-icon class="icon" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></lib-pxl-icon>
            Lettre de labélisation {{ pole.nomCommercial | uppercase }}
          </p>

          <div></div>

          <button type="button" class="ds-btn ds-btn--secondary" (click)="files.click()" *ngIf="isProjectUpdatableByUser">
            Sélectionner un fichier PDF
          </button>
          <div class="input-file">
            <input type="file" id="files" (change)="onUploadPoleDocument($event, i)" accept=".pdf" #files />
          </div>
        </div>
        <div id="error-logo-size" class="file-error-message" style="margin-bottom: 8px" *ngIf="pole.fileTooBig">
          Le fichier sélectionné est trop lourd, merci de sélectionner un autre fichier.
        </div>
        <div id="error-logo-type" class="file-error-message" style="margin-bottom: 8px" *ngIf="pole.fileNotSupported">
          Le type du document sélectionné n'est pas supporté, format accepté ( pdf ), merci de sélectionner un autre fichier.
        </div>
      </ng-container>
    </ng-container>
  </section>

  <!-- Document de la projet -->
  <section class="page-section" [ngClass]="{ disabled: documentConfigs.length < 1 }">
    <h3 class="page-section__title" [ngClass]="{ disabled: documentConfigs.length < 1 }">Document(s) du projet</h3>

    <p class="info-size p-document-size">Poids maximum par fichier {{ FILE_SIZE_LIMIT }}Mo <span *ngIf="aap?.ilab">*</span></p>

    <p *ngIf="showDossierCandidatureLink()" class="document-size-align">
      Merci d’ajouter ici tous vos documents en rapport avec le projet. Le dossier de candidature est à télécharger
      <a [href]="dossier" target="_blank" class="lien_dossier_candidature">ici</a>
    </p>

    <lib-card-info
      *ngIf="documentConfigs.length > 0"
      message="Les documents de la rubrique projet sont chargés par les contacts de la structure Chef de file et/ou Mandataire. Le contenu de tous
      les documents est accessible en lecture seule à l'ensemble des contacts invités sur le projet"
    ></lib-card-info>

    <lib-card-info *ngIf="documentConfigs.length < 1" color="green" message="Aucun document à fournir."></lib-card-info>

    <!-- Carte liste 'Document' -->
    <lib-document-list
      [scope]="EnumScope.PROJET"
      [aap]="aap"
      [projet]="projet"
      [documents]="listDocumentsProjet"
      [canUserWrite]="isProjectUpdatableByUser"
      [projectEtapeName]="sharedFunction.getProjectEtapeName(projet)"
      [utilisateur]="utilisateur"
      [showTag]="isProjectUpdatableByUser"
      [documentConfigs]="documentConfigs"
      (uploadDocument)="onUploadDocumentDnd($event)"
      [fileTooBig]="fileTooBig"
      [fileNotSupported]="fileNotSupported"
      [connaissanceClientFileNotSupported]="connaissanceClientFileNotSupported"
      [videoTooBig]="videoTooBig"
      [nameFileNotSupported]="nameFileNotSupported"
      [isCandidat]="true"
      (deleteDocument)="updateDocumentsAfterDelete($event)"
    ></lib-document-list>
  </section>

  <!-- Documents complémentaires -->
  <section class="page-section" *ngIf="isProjectUpdatableByUser && demandesComplements?.length">
    <h3 class="page-section__title">Document(s) complémentaire(s)</h3>
    <p class="info-size p-document-size">
      Poids maximum par fichier {{ FILE_SIZE_LIMIT }}Mo<br />Les documents disponibles dans cette rubrique ne sont visibles que de Bpifrance
    </p>

    <lib-document-list
      [scope]="EnumScope.PROJET"
      [projet]="projet"
      [documents]="documentsComplementaires"
      [documentConfigs]="documentConfigsForComplement"
      [canUserWrite]="isProjectUpdatableByUser"
      [projectEtapeName]="sharedFunction.getProjectEtapeName(projet)"
      [utilisateur]="utilisateur"
      [showTag]="isProjectUpdatableByUser"
      (uploadDocument)="onUploadDocumentDnd($event)"
      [fileTooBig]="fileTooBig"
      [fileNotSupported]="fileNotSupported"
      [connaissanceClientFileNotSupported]="connaissanceClientFileNotSupported"
      [videoTooBig]="videoTooBig"
      [nameFileNotSupported]="nameFileNotSupported"
      [isCandidat]="true"
      (deleteDocument)="updateDocumentsAfterDelete($event)"
    ></lib-document-list>
  </section>
  <!-- FIN de Informations de la structure -->

  <div id="rgpd" class="rgpd">
    Pour en savoir plus sur la gestion de vos données personnelles, <a (click)="downloadRGPD()">cliquez ici</a>
    <br />
    Tout UTILISATEUR effectuant une (des) Demande(s) dans l’Extranet s’engage à informer les personnes dont il pourrait mentionner les
    données à caractère personnel au titre de la (des) Demande(s) des traitements de données à caractère personnel conformément au présent
    article.
  </div>
  <div *ngIf="aap?.ilab">
    <p>*: Exclusion du type "présentation vidéo" qui lui est limité à 500 Mo.</p>
  </div>
</div>
<!-- FIN de Contenu de la page -->
