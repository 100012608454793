import { enumKeys } from '../../utils/enum-utils';

export enum EnumStatutSignature {
  REFUSEE = 'REFUSEE',
  TERMINEE = 'TERMINEE',
  EN_COURS = 'EN_COURS',
  A_SIGNER = 'A_SIGNER',
  ERREUR = 'ERREUR',
}

export namespace EnumStatutSignature {
  export function toString(value: EnumStatutSignature): string {
    switch (value) {
      case EnumStatutSignature.EN_COURS:
        return 'En cours de signature';
      case EnumStatutSignature.REFUSEE:
        return 'Refus de signature';
      case EnumStatutSignature.TERMINEE:
        return 'Signé';
      default:
        return 'En cours de signature';
    }
  }

  export function all(): EnumStatutSignature[] {
    return enumKeys(EnumStatutSignature) as unknown as EnumStatutSignature[];
  }
}
