<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToProjetInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Projet
  </a>
  <h2 class="page-header__title">Informations générales</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <form id="projet-form" class="form" [formGroup]="projetForm" (ngSubmit)="modifyProjetInfo()">
    <section class="form-step">
      <div id="anchor1" class="form-step__anchor"></div>
      <header class="form-step__header"></header>

      <div class="form-step__content">
        <!-- Nom du projet -->
        <div
          id="input-nom"
          class="form-step__nom ds-input-group"
          [class.ds-input-group--error]="!projetForm.controls['nom'].valid && projetForm.controls['nom'].touched"
        >
          <label for="nom" class="ds-input-group__label">Titre du projet<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="creation_projet_nom"
              name="nom"
              type="text"
              maxlength="50"
              pattern=".*[^ ].*"
              placeholder="Titre du projet"
              class="ds-input-group__input"
              formControlName="nom"
              #nameInput
            />
          </span>
          <div class="form-input__length-hint" [class.form-input__length-hint--full]="nameInput.value?.length === 50">
            <span>{{ nameInput.value?.length || 0 }}/50</span>
          </div>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-nom" *ngIf="!projetForm.controls['nom'].valid && projetForm.controls['nom'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un titre du projet</div>
          </ng-container>
        </div>
        <!-- FIN de Nom du projet -->

        <!-- Acronyme du projet -->
        <div
          id="input-acronyme"
          class="form-step__acronyme ds-input-group"
          [class.ds-input-group--error]="!projetForm.controls['acronyme'].valid && projetForm.controls['acronyme'].touched"
        >
          <label for="creation_projet_acronyme" class="ds-input-group__label">Acronyme du projet<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="creation_projet_acronyme"
              name="creation_projet_acronyme"
              type="text"
              maxlength="16"
              placeholder="Acronyme du projet"
              class="ds-input-group__input"
              pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ0-9_\s-]+"
              formControlName="acronyme"
              (input)="inputValidator($event)"
              #acronymeInput
            />
          </span>
          <div class="form-input__length-hint" [class.form-input__length-hint--full]="acronymeInput.value?.length === 16">
            <span>{{ acronymeInput.value?.length || 0 }}/16</span>
          </div>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-acronyme" *ngIf="!projetForm.controls['acronyme'].valid && projetForm.controls['acronyme'].touched">
            <div class="ds-input-group__feedback">
              <ng-container *ngIf="projetForm.controls['acronyme'].hasError('required')">
                Merci de bien vouloir renseigner un acronyme du projet
              </ng-container>
              <ng-container *ngIf="projetForm.controls['acronyme'].hasError('incorrectSpaces')">
                L'acronyme projet ne doit pas commencer ou se terminer par des espaces et ne peut comporter plus de deux espaces successifs
              </ng-container>
            </div>
          </ng-container>
        </div>
        <!-- FIN  Acronyme du projet -->
        <!-- Description du projet -->
        <div
          id="input-description"
          class="form-step__description ds-input-group"
          [class.ds-input-group--error]="!projetForm.controls['description'].valid && projetForm.controls['description'].touched"
        >
          <label for="description" class="ds-input-group__label">Description<lib-asterisk></lib-asterisk></label>
          <textarea
            id="creation_projet_description"
            name="description"
            rows="5"
            col="0"
            [maxlength]="DESCRIPTION_SIZE"
            pattern="(\n|.)*\S+(\n|.)*"
            placeholder="Décrire et classifier brièvement le projet"
            class="ds-input-group__textarea"
            formControlName="description"
            #descriptionInput
          ></textarea>
          <div class="form-input__length-hint" [class.form-input__length-hint--full]="descriptionInput.value?.length === DESCRIPTION_SIZE">
            <span>{{ descriptionInput.value?.length || 0 }}/{{ DESCRIPTION_SIZE }}</span>
          </div>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-description"
            *ngIf="!projetForm.controls['description'].valid && projetForm.controls['description'].touched"
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une description du projet</div>
          </ng-container>
        </div>
        <!-- FIN de Description du projet -->
      </div>
    </section>

    <div class="form-actions">
      <button id="creation_projet_btn_annuler" type="button" class="ds-btn ds-btn--secondary" (click)="onGoToProjetInfo()">Annuler</button>
      <button
        id="creation_projet_btn_ajouter"
        type="submit"
        class="ds-btn ds-btn--primary"
        *ngxPermissionsOnly="['PROJET_WRITE']"
        [disabled]="!sharedFunction.isProjectUpdatable(aap, projet)"
      >
        <span *ngIf="!isProjectHasInfo">Ajouter</span>
        <span *ngIf="isProjectHasInfo">Modifier</span>
      </button>
    </div>
  </form>
</div>
