import { EnumRoleStructure } from '@shared-ui';
import { EnumMotifNonRattachement } from '../enums/enum.motifNonRattachement';
import { EnumTypeStructure } from '../enums/enum.typeStructure';
import { InformationSiegeInterface } from '../interfaces/information-siege.interface';
import { Adresse } from './adresse.model';
import { InformationsReferentiel } from './informationsReferentiel.model';

export class InformationSiege implements InformationSiegeInterface {
  siret: string;
  raisonSociale: string;
  activite: string;
  organisationPId: number;
  numeroCP: number;
  address: Adresse = new Adresse();
  creationDate: string;
}

export class InformationSiegeRequest {
   siret: string;
   raisonSiret: EnumMotifNonRattachement;
   raisonSocial: string;
   adresse: Adresse;
   creationDate: string;
   effectifSiege: string;
   effectifGroupe: string;
   activite: string;
   informationsReferentiel: InformationsReferentiel;
   typeStructure?: EnumTypeStructure;
   role: EnumRoleStructure
}
