import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavProjetComponent } from './sidenav.projet.component';
import { SharedModule } from '@shared-candidat/shared.module';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, SharedUiModule],
  declarations: [SidenavProjetComponent],
  exports: [SidenavProjetComponent],
  providers: [],
})
export class SidenavProjetModule {}
