import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  EnumProjetEtape,
  EnumScope,
  EnumStatutGrilleImpactsProjet,
  GrilleImpactComponent,
  GrilleImpactHelperService,
  GrilleImpactHttpService,
  GrilleImpacts,
  GrilleImpactsVersion,
  KpisByCategorie,
  Projet,
  ShowToastrService,
  StatutGrilleImpacts,
  Structure,
  StructureRoleType,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-projet-consortium-grille-impacts',
  templateUrl: './projet-consortium-grille-impacts.component.html',
  styleUrls: ['./projet-consortium-grille-impacts.component.scss'],
})
export class ProjetConsortiumGrilleImpactsComponent extends SubscriptionDestroyerComponent implements OnInit {
  readonly TOUT: StructureRoleType = 'TOUT';

  projetId: string;
  structureId: string;
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  grille: GrilleImpacts[];
  role: string;
  structure: Structure;
  utilisateur: Utilisateur;
  kpisByCategorieList: KpisByCategorie[] = [];
  grilleImpactsLoaded = false;
  grilleImpactsResponsesLoaded = false;
  grilleImpacts: GrilleImpacts;

  isStructureUpdatableByUser = false;
  isGrilleImpactUpdatable = false;

  viewVersionGI = false;
  selectedVersion: GrilleImpactsVersion = null;
  kpiVersions: GrilleImpactsVersion[] = [];
  finalisedGI: GrilleImpacts[] = [];
  completedDraftGI: GrilleImpacts;
  index: number;

  StatutGrilleImpacts = StatutGrilleImpacts;

  @ViewChild(GrilleImpactComponent) grilleImpactComponent: GrilleImpactComponent;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.grilleImpactComponent ? this.grilleImpactComponent.canDeactivate() : true;
  }

  constructor(
    private projetService: ProjetService,
    private structureService: StructureService,
    private aapService: AapService,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    public dialog: MatDialog,
    public grilleImpactHttpService: GrilleImpactHttpService,
    public userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public sharedFunction: SharedFunction,
    private grilleImpactHelperService: GrilleImpactHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getProjet();
    this.loadGrilleImpacts();
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    this.projetId = this.route.snapshot.parent.params.id;
    this.structureId = this.route.snapshot.params.structureId;
    if (this.projetId) {
      this.projetService
        .getProjetObservable()
        .pipe(
          concatMap(responseProjet => {
            if (responseProjet) {
              this.projet = responseProjet;
              return this.aapService.loadAapSubject();
            }
          })
        )
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: responseAap => {
            if (responseAap) {
              this.aap = responseAap.body;
              this.loadKpisByCategorie(responseAap.body.id);
              this.getStructure();
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  getStructure(): void {
    this.structureService
      .getStructuresListObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe((structures: Structure[]) => {
        if (structures) {
          this.structure = structures.find(structure => structure.id === this.structureId);
          this.role = this.structure?.role.toString();
          this.getUser();
        }
      });
  }

  getUser(): void {
    this.userService
      .getUserObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: this.handleUserUpdate.bind(this),
      });
  }

  handleUserUpdate(response: Utilisateur): void {
    if (response) {
      this.utilisateur = response;
      this.isStructureUpdatableByUser = this.sharedFunction.isStructureUpdatableByUser(
        this.aap,
        this.projet,
        this.structure,
        this.utilisateur
      );
      this.setGrilleImpactUpdatable();
    }
  }

  setGrilleImpactUpdatable(): void {
    this.isGrilleImpactUpdatable =
      (this.sharedFunction.isStructureNotMandantaireAndHasUserInContacts(this.structure, this.utilisateur) ||
        this.sharedFunction.isUserMandataire(this.projet.id, this.utilisateur)) &&
      this.isStructureUpdatableByUser &&
      !this.isGrilleImpactsProjetValid;
  }

  loadKpisByCategorie(aapId: string): void {
    this.grilleImpactHttpService
      .getKpisByCategorie(aapId, EnumScope.STRUCTURE)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: response => {
          this.kpisByCategorieList = response?.body
            ?.filter(categorie => categorie.categorieKPI.typeStructure === this.role || categorie.categorieKPI.typeStructure === this.TOUT)
            .sort((a, b) => a.categorieKPI.ordre - b.categorieKPI.ordre);
          this.kpisByCategorieList.forEach(kpc => {
            kpc.refKPIDTOList.sort((a, b) => a.ordre - b.ordre);
          });
          this.grilleImpactsLoaded = true;
          this.changeDetectorRef.detectChanges();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  private loadGrilleImpacts(): void {
    this.kpiVersions = [];
    this.finalisedGI = [];
    this.grilleImpactHttpService
      .getGrilleImpactByFilters(this.projetId, this.structureId, EnumScope.STRUCTURE)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: response => {
          response.body.forEach((k: GrilleImpacts) => {
            if (k.statut === StatutGrilleImpacts.FINALISED) {
              this.finalisedGI.push(k);
              this.kpiVersions.push({ ...k.version, statut: k.statut });
            } else {
              this.completedDraftGI = k;
            }
          });
          if (this.kpiVersions.length > 0) this.viewVersionGI = true;

          this.kpiVersions = GrilleImpactHelperService.sortGrilleImpact(this.kpiVersions);
          this.selectedVersion = this.kpiVersions[this.index] ? this.kpiVersions[this.index] : null;
          this.grilleImpacts = this.grilleImpacts ? this.grilleImpacts : this.completedDraftGI;
          this.grilleImpactsResponsesLoaded = true;
          this.changeDetectorRef.detectChanges();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  onChangeKpiVersion(): void {
    this.grilleImpacts = this.finalisedGI.find(
      (gi: GrilleImpacts) =>
        gi.statut === this.selectedVersion.statut &&
        gi.version.dateValidation === this.selectedVersion.dateValidation &&
        gi.version.typeVersion === this.selectedVersion.typeVersion
    );

    this.index = this.kpiVersions.findIndex(
      (kpi: GrilleImpactsVersion) =>
        kpi.statut === this.selectedVersion.statut &&
        kpi.dateValidation === this.selectedVersion.dateValidation &&
        kpi.typeVersion === this.selectedVersion.typeVersion
    );

    this.changeDetectorRef.detectChanges();
    this.grilleImpactHelperService.setGrillesImpactsData(this.grilleImpacts);
  }

  restoreKpiVersion() {
    this.grilleImpacts = this.completedDraftGI;
    this.selectedVersion = null;
    this.index = -1;
    this.changeDetectorRef.detectChanges();
    this.grilleImpactHelperService.setGrillesImpactsData(this.grilleImpacts);
  }

  get isGrilleImpactsProjetValid() {
    return (
      this.projet.grilleImpacts?.validation?.statut === EnumStatutGrilleImpactsProjet[EnumStatutGrilleImpactsProjet.VALIDEE.toString()]
    );
  }

  /*
   * Cette méthode permet de retourner à la page d'information projet
   * */
  onGoToConsortiumInfo(): void {
    this.router.navigate(['projet-creation', this.projetId, 'projet-consortium-info', this.structureId]);
  }

  protected readonly EnumProjetEtape = EnumProjetEtape;
}
