import {BudgetInterface, BudgetRequestInterface } from '../interfaces/budget.interface';

export class Budget implements BudgetInterface {
  montant?: string;
  besoin? = true;
  montantAideDemande?: string;
  budgetTotal?: string | number;
}

export class BudgetRequest implements BudgetRequestInterface {
  isPredepot: boolean;
  budget: Budget;
}
