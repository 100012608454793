import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import { Aap, Projet, ProjetInfo, Secteur, ShowToastrService, SousSecteur } from '@shared-ui';
import { Observable, Subscription } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-projet-info-secteurs',
  templateUrl: './projet-info-secteurs.component.html',
  styleUrls: ['./projet-info-secteurs.component.scss'],
})
export class ProjetInfoSecteursComponent implements OnInit, OnDestroy {
  disableValidBtn = false;
  isProjectHasBudget = false;
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  subscriptions: Subscription[] = [];
  projetInfo: ProjetInfo = new ProjetInfo();

  aapsecteurs: any[];
  selectedsecteurs: any[] = [];
  InitialSelectedsecteurs: any[] = [];
  selectedSousSecteurs: any[] = [];
  initialSelectedSousSecteurs: any[] = [];
  secteursNonSelectione = false;
  maxChecked = 0;

  constructor(
    private projetService: ProjetService,
    private aapService: AapService,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    public sharedFunction: SharedFunction
  ) {}

  ngOnInit(): void {
    this.getProjet();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !(
      this.initialSelectedSousSecteurs.filter(o1 => !this.selectedSousSecteurs.some(o2 => o1.libelleSousSecteur === o2.libelleSousSecteur))
        .length > 0 ||
      this.selectedSousSecteurs.filter(o1 => !this.initialSelectedSousSecteurs.some(o2 => o1.libelleSousSecteur === o2.libelleSousSecteur))
        .length > 0
    );
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    this.subscriptions.push(
      this.projetService
        .getProjetObservable()
        .pipe(
          concatMap(responseProjet => {
            if (responseProjet) {
              this.projet = responseProjet;

              return this.aapService.loadAapSubject();
            }
          })
        )
        .subscribe({
          next: responseAap => {
            if (responseAap) {
              this.aap = responseAap.body;
              this.getsecteurs();
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        })
    );
  }

  /*
   * Cette méthode récupère les secteurs de l'AAP
   * */
  getsecteurs(): void {
    this.maxChecked = 0;
    this.aapsecteurs = this.aap.secteurs;
    this.selectedsecteurs = this.projet.secteurs;

    this.InitialSelectedsecteurs = [];

    this.projet.secteurs.forEach(sect => {
      this.InitialSelectedsecteurs.push(JSON.parse(JSON.stringify(sect)));
    });

    this.aapsecteurs.forEach(secteur => {
      const projetSousSecteur = this.projet.secteurs.filter(sect => sect.codeSecteur === secteur.codeSecteur);

      return secteur.sousSecteurs.forEach(sousSecteur => {
        if (projetSousSecteur.length > 0) {
          if (projetSousSecteur[0].sousSecteurs.find(sousSect => sousSect.codeSousSecteur === sousSecteur.codeSousSecteur)) {
            sousSecteur.checked = true;
            this.maxChecked++;
          } else {
            sousSecteur.checked = false;
          }
        } else {
          sousSecteur.checked = false;
        }
      });
    });
  }

  onSelectCheckbox(secteur: Secteur, sousSecteur: SousSecteur): void {
    if (sousSecteur.checked) {
      sousSecteur.checked = false;
      this.maxChecked--;

      this.selectedsecteurs.forEach(sect => {
        if (sect.libelleSecteur === secteur.libelleSecteur) {
          sect.sousSecteurs = sect.sousSecteurs.filter(sousSect => sousSect.libelleSousSecteur !== sousSecteur.libelleSousSecteur);
        }
      });

      this.selectedsecteurs = this.selectedsecteurs.filter(sect => {
        return sect.sousSecteurs.length > 0;
      });
    } else {
      sousSecteur.checked = true;
      this.maxChecked++;

      const isSecteurAlreadyExist = this.selectedsecteurs.filter(sect => sect.libelleSecteur === secteur.libelleSecteur);
      if (isSecteurAlreadyExist.length > 0) {
        this.selectedsecteurs.forEach(sect => {
          if (sect.libelleSecteur === secteur.libelleSecteur) {
            sect.sousSecteurs.push(sousSecteur);
          }
        });
      } else {
        const addSecteur: Secteur = new Secteur();
        addSecteur.codeSecteur = secteur.codeSecteur;
        addSecteur.libelleSecteur = secteur.libelleSecteur;
        addSecteur.sousSecteurs = [sousSecteur];
        this.selectedsecteurs.push(addSecteur);
      }
    }
    this.initialSelectedSousSecteurs = [];
    this.selectedSousSecteurs = [];

    this.InitialSelectedsecteurs.forEach(sect => {
      sect.sousSecteurs.forEach(sousSect => {
        this.initialSelectedSousSecteurs.push(sousSect);
      });
    });

    this.selectedsecteurs.forEach(sect => {
      sect.sousSecteurs.forEach(sousSect => {
        this.selectedSousSecteurs.push(sousSect);
      });
    });
  }

  /*
   * Cette méthode permet de retourner à la page d'information projet
   * */
  onGoToProjetInfo(): void {
    this.router.navigate(['../projet-info'], { relativeTo: this.route });
  }

  onClickBack(): void {
    this.onGoToProjetInfo();
  }

  onValidateChoice(): void {
    if (this.selectedsecteurs.length === 0) {
      this.secteursNonSelectione = true;
    } else {
      this.projet.secteurs = this.selectedsecteurs;
      this.projetService
        .putSecteurs(this.projet.id, this.projet.secteurs)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.showToastrService.success('Projet modifié');
            this.initialSelectedSousSecteurs = this.selectedSousSecteurs;
            this.onGoToProjetInfo();
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }
}
