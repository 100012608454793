<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <a class="page-header__back" (click)="onGoToConsortiumInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Ma Structure
  </a>
  <h2 class="page-header__title">Prévisions économiques</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <section class="page-section">
    <div class="card donnees">
      <div id="dstab" class="ds-tabs ds-tabs--underline">
        <ul class="ds-tabs-list d-flex justify-content-between onglets" role="tablist" aria-label="nom du système d'onglet">
          <div class="d-flex">
            <li>
              <button
                id="tab_a"
                (click)="selectTab('produits')"
                class="ds-tabs-list__tab ds-tabs-list__tab--active"
                role="tab"
                [tabindex]="0"
                aria-controls="panela"
                aria-selected="true"
              >
                <span>Produits</span>
              </button>
            </li>
            <li>
              <button
                id="tab_b"
                (click)="selectTab('effectif')"
                class="ds-tabs-list__tab"
                role="tab"
                [tabindex]="1"
                aria-controls="panelb"
                aria-selected="false"
              >
                <span>Effectif</span>
              </button>
            </li>
          </div>
        </ul>

        <div [tabindex]="0" role="tabpanel" id="panela" class="ds-tabs-panel" aria-labelledby="tab_a">
          <lib-card-info
            *ngIf="!isStructureUpdatableByUser"
            message="Vous êtes en lecture seule sur cette page. Vous ne pouvez pas éditer son contenu."
          ></lib-card-info>
          <lib-card-info
            [message]="infoMessage"
            *ngIf="dfEtPeForm && dfEtPeForm.get('previsionEconomique').value.periodes.length"
          ></lib-card-info>

          <ng-container *ngIf="currentTab === 'produits'">
            <pxl-projet-consortium-pe-produit
              *ngIf="dfEtPeForm && dfEtPeForm.get('previsionEconomique').value"
              [form]="dfEtPeForm"
              formName="previsionEconomique"
              [structure]="structure"
              [isStructureUpdatableByUser]="isStructureUpdatableByUser"
              [btnProduit]="btnProduit"
            ></pxl-projet-consortium-pe-produit>
          </ng-container>
        </div>

        <div [tabindex]="1" role="tabpanel" id="panelb" class="ds-tabs-panel" aria-labelledby="tab_b" hidden="">
          <lib-card-info
            *ngIf="!isStructureUpdatableByUser"
            message="Vous êtes en lecture seule sur cette page. Vous ne pouvez pas éditer son contenu."
          ></lib-card-info>
          <lib-card-info [message]="infoMessage"></lib-card-info>

          <ng-container *ngIf="currentTab === 'effectif'">
            <pxl-projet-consortium-pe-effectif
              *ngIf="dfEtPeForm && dfEtPeForm.get('previsionEconomique').value"
              [form]="dfEtPeForm"
              formName="previsionEconomique"
              [structure]="structure"
              [isStructureUpdatableByUser]="isStructureUpdatableByUser"
            ></pxl-projet-consortium-pe-effectif>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
  <div class="buttons-container" *ngIf="isStructureUpdatableByUser">
    <button
      *ngIf="structure?.previsionsEconomiques && structure.previsionsEconomiques?.statut === 'VALIDE'"
      id="modify-btn"
      (click)="validBtn('NON_VALIDE', true)"
      class="ds-btn ds-btn--secondary"
    >
      Modifier
    </button>
    <ng-container
      *ngIf="
        !structure?.previsionsEconomiques ||
        !structure?.previsionsEconomiques?.produits ||
        structure?.previsionsEconomiques?.statut === 'NON_VALIDE'
      "
    >
      <button id="cancel-btn" type="button" (click)="cancelBtn()" class="ds-btn ds-btn--tertiary">Annuler</button>
      <button id="save-btn" (click)="validBtn('NON_VALIDE', false)" class="ds-btn ds-btn--secondary">Enregistrer</button>
      <button id="valid-btn" (click)="validBtn('VALIDE', true)" class="ds-btn ds-btn--primary">Valider</button>
    </ng-container>
  </div>
</div>
