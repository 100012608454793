import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedUiModule } from '@shared-ui';
import { projetConsortiumPeProduitComponent } from './projet-consortium-pe-produit.component';
import { SharedModule } from '@shared-candidat/shared.module';

@NgModule({
  declarations: [projetConsortiumPeProduitComponent],
  imports: [
    CommonModule,
    SharedUiModule,
    SharedModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    MatTableModule,
  ],
  exports: [projetConsortiumPeProduitComponent],
})
export class ProjetConsortiumPeProduitModule {}
