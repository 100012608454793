<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <a class="page-header__back" (click)="onGoToConsortiumInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Ma Structure
  </a>
  <h2 class="page-header__title">Eléments financiers</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <section class="page-section">
    <div class="card donnees">
      <div id="dstab" class="ds-tabs ds-tabs--underline">
        <ul class="ds-tabs-list d-flex justify-content-between onglets" role="tablist" aria-label="nom du système d'onglet">
          <div class="d-flex">
            <li>
              <button
                id="tab_a"
                class="ds-tabs-list__tab ds-tabs-list__tab--active"
                role="tab"
                [tabindex]="0"
                aria-controls="panela"
                aria-selected="true"
              >
                Comptes de résultats
              </button>
            </li>
            <li>
              <button id="tab_b" class="ds-tabs-list__tab" role="tab" [tabindex]="1" aria-controls="panelb" aria-selected="false">
                Plan de financement
              </button>
            </li>
            <li>
              <button id="tab_c" class="ds-tabs-list__tab" role="tab" [tabindex]="2" aria-controls="panelc" aria-selected="false">
                Plan de trésorerie
              </button>
            </li>
            <li>
              <button id="tab_d" class="ds-tabs-list__tab" role="tab" [tabindex]="3" aria-controls="paneld" aria-selected="false">
                Vérif. situation financière
              </button>
            </li>
          </div>
        </ul>

        <div [tabindex]="0" role="tabpanel" id="panela" class="ds-tabs-panel" aria-labelledby="tab_a">
          <lib-projet-consortium-df-comptes-resultats
            [isComptesResultats]="true"
            [periodesFinancieres]="comptesResultats"
            [status]="status"
            [upTitleRow]="false"
          >
          </lib-projet-consortium-df-comptes-resultats>
        </div>

        <div [tabindex]="1" role="tabpanel" id="panelb" class="ds-tabs-panel" aria-labelledby="tab_b" hidden="">
          <lib-card-info [message]="EUROS_AMOUNTS_MESSAGE"></lib-card-info>
          <lib-projet-consortium-df-plan-financement [periodesFinancieres]="plansFinancement" [status]="status" [upTitleRow]="false">
          </lib-projet-consortium-df-plan-financement>
        </div>

        <div [tabindex]="2" role="tabpanel" id="panelc" class="ds-tabs-panel" aria-labelledby="tab_c" hidden="">
          <lib-card-info [message]="EUROS_AMOUNTS_MESSAGE"></lib-card-info>
          <lib-card-info *ngIf="!isQuestionEnabled && isOlderThanHeightYears" [message]="OLDER_THAN_HEIGHT_MESSAGE"></lib-card-info>
          <section class="label-slide-toggle">
            <label>Etes-vous une entreprise créée il y a plus de 8 ans ?</label>
            <mat-slide-toggle [disabled]="!isQuestionEnabled" [color]="'primary'" [(ngModel)]="isOlderThanHeightYears">
              {{ isOlderThanHeightYears ? 'Oui' : 'Non' }}
            </mat-slide-toggle>
          </section>
          <lib-projet-consortium-df-plan-tresorerie
            [periodesFinancieres]="plansTresorerie"
            [status]="status"
            [upTitleRow]="false"
            [isDisabled]="isOlderThanHeightYears"
          >
          </lib-projet-consortium-df-plan-tresorerie>
        </div>

        <div [tabindex]="3" role="tabpanel" id="paneld" class="ds-tabs-panel" aria-labelledby="tab_d" hidden="">
          Vérif. situation financière
        </div>
      </div>
    </div>
  </section>
  <lib-cancel-save-validate-buttons (saveEventEmitter)="onSave()"> </lib-cancel-save-validate-buttons>
</div>
