import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ShowToastrService } from '@shared-ui';
import { filter } from 'rxjs/operators';
import { AppConfig } from '../app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'pxl_front_candidat';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private showToastrService: ShowToastrService,
    public appConfig: AppConfig
  ) {}

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);

      rt.data.subscribe(data => {
        this.titleService.setTitle(data.title);
      });
    });

    // If there is an error param from evaluateur, we display a toastr
    this.activatedRoute.queryParams.subscribe(params => {
      const errorParam = params.error;

      if (errorParam) {
        this.showToastrService.checkCodeError(errorParam);
      }
    });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
