import { EnumPermissionScope, Utilisateur } from '@shared-ui';

export class UtilisateurUtils {
  public static hasUserRoleOnProjet(utilisateur: Utilisateur, projetId: string, roles: string[]): boolean {
    return Boolean(
      (utilisateur?.perms ?? [])
        ?.filter(perm => perm.scope === EnumPermissionScope.PROJET && perm.scopeId === projetId)
        ?.some(perm => roles?.includes(perm.role))
    );
  }

  public static getUserRoleOnProjet(utilisateur: Utilisateur, projetId: string): string {
    return (utilisateur?.perms ?? [])
      ?.filter(perm => perm.scope === EnumPermissionScope.PROJET && perm.scopeId === projetId)
      ?.map(perm => perm.role)[0];
  }

  public static getUserProjetsIds(utilisateur: Utilisateur): string[] {
    return (utilisateur?.perms ?? [])?.filter(perm => perm.scope === EnumPermissionScope.PROJET)?.map(perm => perm.scopeId) as string[];
  }

  public static hasUserAnyGroupe(utilisateur: Utilisateur): boolean {
    return !!utilisateur?.groupe;
  }
}
