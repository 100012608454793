import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';

import { ProjetService } from '@services-candidat/projet.service';

import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import { Aap, Projet, ShowToastrService, Thematique } from '@shared-ui';
import { Observable, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-projet-info-thematique',
  templateUrl: './projet-info-thematique.component.html',
  styleUrls: ['./projet-info-thematique.component.scss'],
})
export class ProjetInfoThematiqueComponent implements OnInit, OnDestroy {
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  subscriptions: Subscription[] = [];

  aapThematiques: Thematique[];
  initialSelectedThematique: Thematique;
  thematiqueNonSelectione = false;
  checkRadio: Thematique;

  constructor(
    private projetService: ProjetService,
    private aapService: AapService,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    public sharedFunction: SharedFunction
  ) {}

  ngOnInit(): void {
    this.getProjet();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.initialSelectedThematique?.codeThematique === this.checkRadio?.codeThematique;
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    this.subscriptions.push(
      this.projetService
        .getProjetObservable()
        .pipe(
          concatMap(responseProjet => {
            if (responseProjet) {
              this.projet = responseProjet;
              return this.aapService.loadAapSubject();
            }
          })
        )
        .subscribe({
          next: responseAap => {
            if (responseAap) {
              this.aap = responseAap.body;
              this.getThematiques();
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        })
    );
  }

  /*
   * Cette méthode récupère les thematiques de l'AAP
   * */
  getThematiques(): void {
    this.aapThematiques = this.aap.thematiques;
    if (this.projet.thematique?.codeThematique != null) {
      this.initialSelectedThematique = this.projet.thematique;

      this.aapThematiques.forEach(thematique => {
        if (thematique.codeThematique === this.initialSelectedThematique.codeThematique) {
          this.checkRadio = thematique;
        }
      });
    }
  }

  onSelectRadio(thematique: Thematique): void {
    if (this.checkRadio?.codeThematique === thematique?.codeThematique) {
      this.checkRadio = null;
    }
  }

  /*
   * Cette méthode permet de retourner à la page d'information projet
   * */
  onGoToProjetInfo(): void {
    this.router.navigate(['../projet-info'], { relativeTo: this.route });
  }

  onClickBack(): void {
    this.onGoToProjetInfo();
  }

  onValidateChoice(): void {
    if (this.checkRadio == null && this.aap.thematiquesObligatoire) {
      this.thematiqueNonSelectione = true;
    } else {
      this.projet.thematique = this.checkRadio;
      this.subscriptions.push(
        this.projetService.changeThematiqueProjet(this.projet).subscribe(() => {
          this.showToastrService.success('Projet modifié');
          this.initialSelectedThematique = this.checkRadio;
          this.onGoToProjetInfo();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }
}
