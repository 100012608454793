export enum EnumProcedureSignatureStatut {
  EN_COURS = 'EN_COURS',
  TERMINEE = 'TERMINEE',
  ANNULEE = 'ANNULEE',
  REFUSEE = 'REFUSEE',
  EXPIREE = 'EXPIREE',
  ERREUR = 'ERREUR',
}

export namespace EnumProcedureSignatureStatut {
  export function toString(status: EnumProcedureSignatureStatut): string {
    switch (status) {
      case EnumProcedureSignatureStatut.EN_COURS:
        return 'En cours';
      case EnumProcedureSignatureStatut.TERMINEE:
        return 'Terminée';
      case EnumProcedureSignatureStatut.ANNULEE:
        return 'Annulée';
      case EnumProcedureSignatureStatut.REFUSEE:
        return 'Refusée';
      case EnumProcedureSignatureStatut.EXPIREE:
        return 'Expirée';
      case EnumProcedureSignatureStatut.ERREUR:
        return 'Terminée avec erreur';
      default:
        return '';
    }
  }
}
