import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EnumProcedureSignatureStatut,
  EnumStatutSignature,
  EnumTypeSignataire,
  ProcedureSignatureStructure,
  SyntheseContractualisationStructure,
} from '@shared-ui';
import { map, Observable, of, switchMap } from 'rxjs';
import { StructureService } from './structure.service';
import { UserService } from './user.service';

@Injectable()
export class ContractualisationService {
  constructor(private structureService: StructureService, private userService: UserService) {}

  canUserSeeContractPage(): Observable<boolean> {
    return this.structureService.loadSyntheseContractualisationStructuresSubject().pipe(
      map((response: HttpResponse<SyntheseContractualisationStructure[]>) => response?.body),
      map((syntheses: SyntheseContractualisationStructure[]) => {
        // Peut voir la page contrat si on a un historique
        if (syntheses?.some(synthese => synthese.proceduresSignatures.length > 1)) {
          return of(true);
        }
        // Ou si le signataire interne a signé au moins un contrat en cours
        return this.hasSignataireInterneSignedAtLeastOneContract();
      }),
      switchMap(result => result)
    );
  }

  hasSignataireInterneSignedContractOfStructure(structureId: string): Observable<boolean> {
    return this.structureService.loadSyntheseContractualisationStructuresSubject().pipe(
      map((response: HttpResponse<SyntheseContractualisationStructure[]>) => response?.body),
      map((syntheseContractualisationStructures: SyntheseContractualisationStructure[]) => {
        const synthese = syntheseContractualisationStructures.find(s => s.id === structureId);
        if (!synthese) return false;
        const procedures = synthese.proceduresSignatures.filter(procedure =>
          [EnumProcedureSignatureStatut.EN_COURS, EnumProcedureSignatureStatut.TERMINEE].includes(procedure.statut)
        );
        return this.hasSignataireInterneSigned(procedures);
      })
    );
  }

  hasSignataireInterneSignedAtLeastOneContract(): Observable<boolean> {
    return this.structureService.loadSyntheseContractualisationStructuresSubject().pipe(
      map((response: HttpResponse<SyntheseContractualisationStructure[]>) => response?.body),
      map((syntheseContractualisationStructures: SyntheseContractualisationStructure[]) => {
        return syntheseContractualisationStructures?.some(synthese => {
          const procedures = synthese.proceduresSignatures.filter(procedure =>
            [EnumProcedureSignatureStatut.EN_COURS, EnumProcedureSignatureStatut.TERMINEE].includes(procedure.statut)
          );
          return this.hasSignataireInterneSigned(procedures);
        });
      })
    );
  }

  private hasSignataireInterneSigned(procedures: ProcedureSignatureStructure[]): boolean {
    return procedures.some(procedure => {
      const signataireInterne = procedure.signataires.find(signataire => signataire.type === EnumTypeSignataire.SIGNATAIRE_INTERNE);
      return signataireInterne?.signature?.statut === EnumStatutSignature.TERMINEE;
    });
  }
}
