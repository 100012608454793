import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Step } from './step.interface';
import { StepStatus } from './step-status.enum';

@Component({
  selector: 'lib-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  @Input() steps: Step[];
  @Output() stepSelected = new EventEmitter<number>();

  isStepInProgress(status: string): boolean {
    return status === StepStatus.IN_PROGRESS;
  }

  onStepClicked(index: number): void {
    this.stepSelected.emit(index);
  }
}
