import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { FeatureFlagService } from '../services/_public_services';

@Directive({
  selector: '[libMyRemoveIfFeatureOff]',
})
export class MyRemoveIfFeatureOffDirective implements OnInit {
  @Input('libMyRemoveIfFeatureOff') featureName: string | string[];

  constructor(private el: ElementRef, private featureFlagService: FeatureFlagService) {}

  ngOnInit(): void {
    if (Array.isArray(this.featureName)) {
      this.featureName.forEach(feature => {
        this.checkAndRemoveElement(feature);
      });
    } else {
      this.checkAndRemoveElement(this.featureName);
    }
  }

  checkAndRemoveElement(featureName: string): void {
    if (this.featureFlagService.featureOff(featureName)) {
      this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    }
  }
}
