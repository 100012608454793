import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'lib-pxl-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit, OnChanges {
  @Output() changeToggleEvent: EventEmitter<string> = new EventEmitter<string>();

  @Input() labels: string[] = [];
  @Input() defaultLabel: string;
  @Input() lockToggle = false;
  selectedIndex = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultLabel']) {
      this.selectedIndex = this.labels.indexOf(this.defaultLabel);
    }
  }

  ngOnInit(): void {
    if (!this.defaultLabel) {
      this.defaultLabel = this.labels[0];
    } else {
      this.selectedIndex = this.labels.indexOf(this.defaultLabel);
    }
  }

  toggleOptionClick(index: number) {
    if (this.lockToggle || this.selectedIndex === index) {
      return;
    }
    this.selectedIndex = index;
    this.changeToggleEvent.emit(this.labels[index]);
  }
}
