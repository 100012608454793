import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureContratComponent } from './signature-contrat.component';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [SignatureContratComponent],
  imports: [CommonModule, SharedUiModule],
  exports: [SignatureContratComponent],
})
export class SignatureContratModule {}
