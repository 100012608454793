import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments-candidat/environment';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UploadDocumentService } from '@services-candidat/upload-document.service';
import { UserService } from '@services-candidat/user.service';
import { ConfirmModalComponent } from '@shared-candidat/components/modals/confirm-modal/confirm-modal.component';
import { URL_PUBLIC_DOC } from '@shared-candidat/utils/constants';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';

import {
  Aap,
  DateTimeUtils,
  DocumentHelper,
  DocumentProjet,
  EnumGenre,
  EnumScanDocument,
  EnumScope,
  EnumTypeDocument,
  EnumTypeFinancePublic,
  EnumTypeNiveauFormation,
  EnumTypeNiveauFormationTech,
  EnumTypeSecteurPublic,
  EnumTypeSecteurPublicTech,
  EnumTypeSituationPro,
  GenreItem,
  Invitation,
  MembreEquipe,
  Projet,
  ShowToastrService,
  Structure,
  URL_NETVA,
  Utilisateur,
} from '@shared-ui';
import { Observable, Subject, Subscription } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { ProjetConsortiumMembreEquipeHelperService } from './projet-consortium-membre-equipe.helper.service';

@Component({
  selector: 'app-projet-consortium-membre-equipe',
  templateUrl: './projet-consortium-membre-equipe.component.html',
  styleUrls: ['./projet-consortium-membre-equipe.component.scss'],
})
export class ProjetConsortiumMembreEquipeComponent implements OnInit, OnDestroy {
  readonly EMPLOYE_DU_SECTEUR_PUBLIC = EnumTypeSituationPro.EMPLOYE_DU_SECTEUR_PUBLIC;
  readonly ETUDIANT_DOCTORANT = EnumTypeSituationPro.ETUDIANT_DOCTORANT;
  readonly AUTRE = EnumTypeSituationPro.AUTRE;
  readonly CHERCHEUR = EnumTypeSecteurPublicTech[EnumTypeSecteurPublicTech.CHERCHEUR];
  readonly ENSEIGNANT_CHERCHEUR = EnumTypeSecteurPublicTech[EnumTypeSecteurPublicTech.ENSEIGNANT_CHERCHEUR];
  readonly NON_DIPLOME = EnumTypeNiveauFormationTech[EnumTypeNiveauFormationTech.NON_DIPLOME];
  readonly AUTRE_DIPLOME = EnumTypeNiveauFormationTech[EnumTypeNiveauFormationTech.AUTRE];

  maxLengthChampLibre = 51;
  maxLengthSiren = 9;
  maxLenghtAnnee = 4;
  maxLenghtAnnees = 50;

  destroy$: Subject<boolean> = new Subject<boolean>();

  projet: Projet = new Projet();
  structure: Structure;
  structures: Structure[];
  utilisateur: Utilisateur;
  membre: MembreEquipe = new MembreEquipe();
  contactForm: UntypedFormGroup;
  projetId: string;
  structureId: string;
  membreId: string;
  targetedCreation: string;
  aap: Aap = new Aap();
  isFormValid = false;
  invitation: Invitation = new Invitation();
  subscriptions: Subscription[] = [];
  genres: Array<GenreItem> = EnumGenre.all().map(item => ({ valeur: item, checked: false }));
  public isExpired = false;
  public dateExpirationInvitation = new Date();
  countrySelectDirty: boolean;
  situationProType = EnumTypeSituationPro;
  secteurPublicType = EnumTypeSecteurPublic;
  financementPublicType = EnumTypeFinancePublic;
  niveauFormationType = EnumTypeNiveauFormation;

  docNonFourni = false;
  isUploading = false;
  fileToUpload: File;
  fileTooBig = false;
  fileNotSupported = false;
  documentCv: DocumentProjet;
  oldDocumentCv: DocumentProjet;

  canEditConsortium: boolean;

  defaultValue: any = {
    alpha2Code: 'FR',
    name: 'France',
  };

  isReponsableProjet = false;
  enqueteRP = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public projetService: ProjetService,
    private structureService: StructureService,
    private showToastrService: ShowToastrService,
    private aapService: AapService,
    public uploadDocumentService: UploadDocumentService,
    private userService: UserService,
    public sharedFunction: SharedFunction,
    public helperService: ProjetConsortiumMembreEquipeHelperService
  ) {}

  ngOnInit(): void {
    this.createForms();
    this.getProjet();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.contactForm.dirty || this.isFormValid;
  }

  createForms(): void {
    this.contactForm = this.formBuilder.group({
      nom: [null, Validators.required],
      prenom: [null, Validators.required],
      genre: [null, Validators.required],
      dateNaissance: [null, Validators.required],
      responsableProjet: [false, Validators.required],
      roleEquipe: [null, Validators.required],
      formation: [null, Validators.required],
      cv: [null, Validators.required],
    });
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    this.projetId = this.route.snapshot.parent.params.id;
    this.structureId = this.route.snapshot.params.structureId;
    this.membreId = this.route.snapshot.params.membreId;

    if (this.structureId && this.projetId) {
      this.subscriptions.push(
        this.projetService
          .getProjetObservable()
          .pipe(
            concatMap(responseProjet => {
              if (responseProjet) {
                this.projet = responseProjet;

                this.subscriptions.push(
                  this.userService.getUserObservable().subscribe(response => {
                    if (response) {
                      this.utilisateur = response;
                    }
                  })
                );

                return this.aapService.loadAapSubject();
              }
            })
          )
          .subscribe({
            next: responseAap => {
              if (responseAap) {
                this.aap = responseAap.body;
                this.enqueteRP = this.aap.equipeEnqueteRPPresent;
                this.setMembreForm();
              }
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          })
      );
    }
  }

  setMembreForm(): void {
    this.subscriptions.push(
      this.structureService.getStructureById(this.structureId).subscribe(response => {
        if (response) {
          this.structure = response.body;
          if (this.membreId) {
            this.membre = this.structure.equipe[this.membreId];
            this.isReponsableProjet = this.membre?.identite?.responsableProjet;

            this.contactForm.get('nom').setValue(this.structure.equipe[this.membreId].identite.nom);
            this.contactForm.get('prenom').setValue(this.structure.equipe[this.membreId].identite.prenom);
            this.contactForm.get('dateNaissance').setValue(this.structure.equipe[this.membreId].identite.dateNaissance);
            this.contactForm.get('genre').setValue(this.structure.equipe[this.membreId].identite.genre);
            this.contactForm.get('responsableProjet').setValue(this.structure.equipe[this.membreId].identite.responsableProjet);
            this.contactForm.get('roleEquipe').setValue(this.structure.equipe[this.membreId].infosProjet.roleEquipe);
            this.contactForm.get('formation').setValue(this.structure.equipe[this.membreId].formation.niveauFormation);

            if (this.isReponsableProjet && this.enqueteRP) {
              this.helperService.addEnqueteResponsableProjetForms(this.contactForm);
              this.formValueChange();
              this.helperService.populateEnqueteResponsableProjetForm(this.structure.equipe[this.membreId].parcoursRP, this.contactForm);
              this.contactForm.controls['dateCreationEntrepriseCree'] &&
                this.contactForm
                  .get('dateCreationEntrepriseCree')
                  .setValue(this.contactForm.controls['dateCreationEntrepriseCree'].value.split('T')[0]);

              this.contactForm.controls['dateCreationEntrepriseCreeAutreProjet'] &&
                this.contactForm
                  .get('dateCreationEntrepriseCreeAutreProjet')
                  .setValue(this.contactForm.controls['dateCreationEntrepriseCreeAutreProjet'].value.split('T')[0]);
            }

            this.setGenreFormFieldValue();

            this.projetService.getDocumentsStructure(this.projet.id, this.structureId, [EnumTypeDocument.CV]).subscribe((resp: any) => {
              const listDocumentStructure = resp.body;
              this.documentCv = listDocumentStructure.find(d => d.id === this.membre.idDocumentCV);
              this.oldDocumentCv = this.documentCv;
              this.contactForm.get('cv').setValue(this.oldDocumentCv);
            });
          }

          this.canEditConsortium = this.sharedFunction.isStructureUpdatableByUser(this.aap, this.projet, this.structure, this.utilisateur);
          if (!this.canEditConsortium) {
            this.contactForm.disable();
          }
        }
      })
    );
  }

  onSelectDoc($event: any): void {
    const documentProjet: DocumentProjet = new DocumentProjet();

    this.fileTooBig = false;
    this.fileNotSupported = false;
    this.fileToUpload = $event.item(0);

    if (this.fileToUpload.size >= 20000000) {
      this.fileTooBig = true;
    } else if (!['application/pdf'].includes(this.fileToUpload.type)) {
      this.fileNotSupported = true;
    } else {
      documentProjet.nom = this.fileToUpload.name;
      documentProjet.dateModification = this.projet.dateModification;
      documentProjet.dateCreation = new Date();
      documentProjet.projetId = this.projet.id;
      documentProjet.file = this.fileToUpload;
      this.documentCv = documentProjet;
      this.contactForm.get('cv').setValue(documentProjet);
      this.docNonFourni = false;
    }
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isValidDocument(): boolean {
    return this.documentCv?.scan === EnumScanDocument.SAFE;
  }

  isScanedDocument(): boolean {
    return this.documentCv?.scan === EnumScanDocument.SAFE || this.documentCv?.scan === EnumScanDocument.UNSAFE;
  }

  getNomCreateurDocument(): string {
    return DocumentHelper.getNomCreateurDocument(this.documentCv);
  }

  getNomMembreEquipe(): string {
    return (
      (this.contactForm.controls['prenom'].value ? this.contactForm.controls['prenom'].value : '') +
      ' ' +
      (this.contactForm.controls['nom'].value ? this.contactForm.controls['nom'].value : '')
    );
  }

  downloadDocument() {
    this.subscriptions.push(
      this.uploadDocumentService.getValueForDocMembreEquipeDownload(this.projet, this.documentCv).subscribe({
        next: (response: any) => {
          const url = response.body.url;
          window.open(url);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      })
    );
  }

  onDeleteDocument() {
    this.contactForm.controls['cv'].markAsDirty();
    this.contactForm.controls['cv'].setValue(null);
    this.documentCv = null;
    this.docNonFourni = true;
  }

  valueOrder(): number {
    return 0;
  }

  uploadDoc(): void {
    const typeUpload = 'B401';

    if (!this.documentCv.id || this.documentCv.id !== this.oldDocumentCv?.id) {
      this.documentCv.structureId = this.structureId;
      this.documentCv.typeDoc = EnumTypeDocument.CV;
      this.documentCv.scope = EnumScope.STRUCTURE;

      this.isUploading = true;
      const newFileToUpload = this.documentCv.file;
      this.subscriptions.push(
        this.projetService.upsertDocumentProjet(this.documentCv).subscribe({
          next: (rep: any) => {
            if (rep) {
              this.documentCv = rep.body;

              const path = this.projet.id + '/' + this.structureId + '/cvs/' + this.documentCv.id;

              this.uploadDocumentService
                .getValueForDocStructureUpload(
                  newFileToUpload.name,
                  path,
                  typeUpload,
                  this.documentCv.id,
                  this.projet.id,
                  this.documentCv.typeDoc
                )
                .subscribe({
                  next: (response: any) => {
                    const url = response.body.url;
                    this.subscriptions.push(
                      this.uploadDocumentService
                        .upload(url, newFileToUpload, this.projet.id, this.documentCv.id, typeUpload, this.documentCv.typeDoc)
                        .subscribe({
                          next: () => {
                            if (this.oldDocumentCv?.id) {
                              this.deleteOldDocument();
                            } else {
                              this.updateMembreEquipe();
                            }

                            this.isUploading = false;
                          },
                          error: (err: HttpErrorResponse) => {
                            this.showToastrService.checkCodeError(err?.error);
                            this.isUploading = false;
                            this.projetService.deleteDocument(this.projet.id, this.documentCv.id).subscribe();
                          },
                        })
                    );
                  },
                  error: (err: HttpErrorResponse) => {
                    this.showToastrService.checkCodeError(err?.error);
                    this.isUploading = false;
                    this.projetService.deleteDocument(this.projet.id, this.documentCv.id).subscribe();
                  },
                });
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
            this.isUploading = false;
          },
        })
      );
    } else {
      this.updateMembreEquipe();
    }
  }

  deleteOldDocument(): void {
    this.projetService.deleteDocument(this.projet.id, this.oldDocumentCv.id).subscribe({
      next: response => {
        if (response) {
          this.updateMembreEquipe();
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  updateMembreEquipe(): void {
    const newMembreEquipe = new MembreEquipe();
    if (this.membreId) {
      newMembreEquipe.index = this.membreId;
    }
    newMembreEquipe.identite.nom = this.contactForm.controls['nom'].value;
    newMembreEquipe.identite.prenom = this.contactForm.controls['prenom'].value;
    newMembreEquipe.identite.dateNaissance = DateTimeUtils.formatToDate(this.contactForm.controls['dateNaissance'].value);
    newMembreEquipe.identite.genre = this.contactForm.controls['genre'].value;
    newMembreEquipe.identite.responsableProjet = this.contactForm.controls['responsableProjet'].value;
    newMembreEquipe.infosProjet.roleEquipe = this.contactForm.controls['roleEquipe'].value;
    newMembreEquipe.formation.niveauFormation = this.contactForm.controls['formation'].value;
    newMembreEquipe.idDocumentCV = this.documentCv.id;
    newMembreEquipe.projetId = this.projetId;

    if (this.contactForm.controls['dateCreationEntrepriseCree'] && this.contactForm.controls['dateCreationEntrepriseCree'].value) {
      this.contactForm.get('dateCreationEntrepriseCree').setValue(new Date(this.contactForm.controls['dateCreationEntrepriseCree'].value));
    }
    if (
      this.contactForm.controls['dateCreationEntrepriseCreeAutreProjet'] &&
      this.contactForm.controls['dateCreationEntrepriseCreeAutreProjet'].value
    ) {
      this.contactForm
        .get('dateCreationEntrepriseCreeAutreProjet')
        .setValue(new Date(this.contactForm.controls['dateCreationEntrepriseCreeAutreProjet'].value));
    }

    if (this.isReponsableProjet && this.enqueteRP) {
      this.helperService.updateEnqueteResponsableProjetForm(newMembreEquipe, this.contactForm);
    }
    this.addMembreEquipeToStructure(this.structureId, newMembreEquipe);
  }

  addMembreEquipeToStructure(structureId: string, newMembreEquipe: MembreEquipe): void {
    this.subscriptions.push(
      this.structureService.updateMembreEquipeStructure(structureId, newMembreEquipe).subscribe({
        next: () => {
          this.isFormValid = true;
          if (this.membreId) {
            this.showToastrService.success('Le membre a bien été modifié');
          } else {
            this.showToastrService.success('Le membre a bien été ajouté');
          }
          this.onGoToConsortiumInfo();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      })
    );
  }

  /*
   * Ouvre la pop in du responsable projet
   * */
  openPopInRP(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Changement de responsable projet',
        description: `<p>Dans la structure, il ne peut y avoir qu'un seul responsable projet.<br>
                         Un responsable projet a déjà été désigné.<br>
                         Si vous souhaitez le remplacer, cliquer sur le bouton "Oui".</p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.uploadDoc();
      }
    });
  }

  submitFormContact(): void {
    let nbreRp = 0;
    if (this.structure.equipe.length !== 0) {
      this.structure.equipe.forEach(membre => {
        if (
          membre.identite.responsableProjet &&
          !this.membre.identite.responsableProjet &&
          this.contactForm.controls['responsableProjet'].value
        ) {
          nbreRp++;
        }
      });
    }
    if (nbreRp > 0 && this.contactForm.valid) {
      this.openPopInRP();
    } else {
      this.contactForm.markAllAsTouched();
      if (this.documentCv && this.contactForm.valid) this.uploadDoc();
    }
  }

  selectGenre(genre: GenreItem): void {
    const itemIndex = this.genres.findIndex(item => item.valeur === genre.valeur);
    if (!genre.checked) {
      this.genres.forEach(genreItem => {
        genreItem.checked = false;
      });
      this.genres[itemIndex].checked = true;
      this.contactForm.controls['genre'].setValue(genre.valeur);
    } else {
      this.genres[itemIndex].checked = false;
      this.contactForm.controls['genre'].setValue(null);
    }
  }

  setGenreFormFieldValue(): void {
    if (this.membre.identite.genre) {
      const itemIndex = this.genres.findIndex(item => item.valeur === this.membre.identite.genre);
      this.genres.forEach(genre => {
        genre.checked = false;
      });
      this.genres[itemIndex].checked = true;
    }
  }

  /*
   * Permet de retourner à la page d'information consortium
   * */
  onGoToConsortiumInfo(): void {
    this.router.navigate(['projet-creation', this.projetId, 'projet-consortium-info', this.structureId]);
  }

  downloadRGPD(): void {
    window.open(URL_PUBLIC_DOC + this.aap.programme.fichierRgpd);
  }

  toggle(event: Event) {
    this.isReponsableProjet = (event.target as HTMLInputElement).checked;
    if (this.enqueteRP) {
      if (this.isReponsableProjet) {
        this.helperService.addEnqueteResponsableProjetForms(this.contactForm);
        this.helperService.enableAllFormFields(this.contactForm);
        this.helperService.populateEnqueteResponsableProjetForm(this.structure.equipe[this.membreId]?.parcoursRP, this.contactForm);
        this.formValueChange();
      } else {
        this.helperService.deleteEnqueteResponsableProjetForms(this.contactForm);
      }
    }
  }

  formValueChange() {
    this.contactForm.controls['situationProfessionnelle'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      delete this.contactForm.controls['emploiSecteurPublic'];
      delete this.contactForm.controls['organismeRecherche'];
      delete this.contactForm.controls['financementsPublic'];
      delete this.contactForm.controls['typeFinancementsPublic'];
      delete this.contactForm.controls['autreSituationProfessionnelle'];

      if (x === this.EMPLOYE_DU_SECTEUR_PUBLIC) {
        this.contactForm.addControl('emploiSecteurPublic', new FormControl(null, Validators.required));
        this.helperService.toggleForm(
          this.contactForm.controls['situationProfessionnelle'],
          this.contactForm.controls['emploiSecteurPublic']
        );
      }
      if (x === this.ETUDIANT_DOCTORANT) {
        this.contactForm.addControl('financementsPublic', new FormControl(null, Validators.required));
        this.helperService.toggleForm(
          this.contactForm.controls['situationProfessionnelle'],
          this.contactForm.controls['financementsPublic']
        );
        this.contactForm.controls['financementsPublic'] &&
          this.contactForm.controls['financementsPublic'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
            delete this.contactForm.controls['typeFinancementsPublic'];
            if (x !== false) {
              this.contactForm.addControl('typeFinancementsPublic', new FormControl(null, Validators.required));
              this.helperService.toggleForm(
                this.contactForm.controls['financementsPublic'],
                this.contactForm.controls['typeFinancementsPublic']
              );
            }
          });
      }
      if (x === this.AUTRE) {
        this.contactForm.addControl('autreSituationProfessionnelle', new FormControl(null, Validators.required));
        this.helperService.toggleForm(
          this.contactForm.controls['situationProfessionnelle'],
          this.contactForm.controls['autreSituationProfessionnelle']
        );
      }

      this.contactForm.controls['emploiSecteurPublic'] &&
        this.contactForm.controls['emploiSecteurPublic'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
          if (x === this.CHERCHEUR || x === this.ENSEIGNANT_CHERCHEUR) {
            this.contactForm.addControl('organismeRecherche', new FormControl(null, Validators.required));
            this.helperService.toggleForm(
              this.contactForm.controls['emploiSecteurPublic'],
              this.contactForm.controls['organismeRecherche']
            );
          }
        });
    });

    this.contactForm.controls['niveauformation'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      delete this.contactForm.controls['detailDiplome'];
      if (x === this.NON_DIPLOME) {
        this.contactForm.get('anneeObtentionDiplome').clearValidators();
        this.contactForm.get('anneeObtentionDiplome').updateValueAndValidity();
      } else {
        this.contactForm.get('anneeObtentionDiplome').setValidators([Validators.required]);
        this.contactForm.get('anneeObtentionDiplome').updateValueAndValidity();
      }
      if (x === this.AUTRE_DIPLOME) {
        this.contactForm.addControl('detailDiplome', new FormControl(null, Validators.required));
        this.helperService.toggleForm(this.contactForm.controls['niveauformation'], this.contactForm.controls['detailDiplome']);
      }
    });

    this.contactForm.controls['laureatEmergenceOuBeneficieBourse'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      delete this.contactForm.controls['anneeLaureatEmergenceOuBeneficieBourse'];
      delete this.contactForm.controls['entrepriseCree'];
      delete this.contactForm.controls['raisonSocialeEntrepriseCree'];
      delete this.contactForm.controls['dateCreationEntrepriseCree'];
      delete this.contactForm.controls['sirenEntrepriseCree'];
      if (x !== false) {
        this.contactForm.addControl('anneeLaureatEmergenceOuBeneficieBourse', new FormControl(null, Validators.required));
        this.contactForm.addControl('entrepriseCree', new FormControl(null, Validators.required));
        this.helperService.toggleForm(
          this.contactForm.controls['laureatEmergenceOuBeneficieBourse'],
          this.contactForm.controls['anneeLaureatEmergenceOuBeneficieBourse'],
          this.contactForm.controls['entrepriseCree']
        );
      }

      this.contactForm.controls['entrepriseCree'] &&
        this.contactForm.controls['entrepriseCree'].valueChanges.subscribe(x => {
          delete this.contactForm.controls['raisonSocialeEntrepriseCree'];
          delete this.contactForm.controls['dateCreationEntrepriseCree'];
          delete this.contactForm.controls['sirenEntrepriseCree'];
          if (x !== false) {
            this.contactForm.addControl('raisonSocialeEntrepriseCree', new FormControl(null, Validators.required));
            this.contactForm.addControl('dateCreationEntrepriseCree', new FormControl(null, Validators.required));
            this.contactForm.addControl('sirenEntrepriseCree', new FormControl(null, Validators.required));
            this.helperService.toggleForm(
              this.contactForm.controls['laureatEmergenceOuBeneficieBourse'],
              this.contactForm.controls['raisonSocialeEntrepriseCree'],
              this.contactForm.controls['dateCreationEntrepriseCree'],
              this.contactForm.controls['sirenEntrepriseCree']
            );
          }
        });
    });

    this.contactForm.controls['laureatCreationDev'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      delete this.contactForm.controls['anneeLaureatCreationDev'];
      delete this.contactForm.controls['entrepriseCreeAutreProjet'];
      delete this.contactForm.controls['raisonSocialeEntrepriseCreeAutreProjet'];
      delete this.contactForm.controls['dateCreationEntrepriseCreeAutreProjet'];
      delete this.contactForm.controls['sirenEntrepriseCreeAutreProjet'];
      if (x === true) {
        this.contactForm.addControl('anneeLaureatCreationDev', new FormControl(null, Validators.required));
        this.contactForm.addControl('entrepriseCreeAutreProjet', new FormControl(null, Validators.required));
        this.helperService.toggleForm(
          this.contactForm.controls['laureatCreationDev'],
          this.contactForm.controls['anneeLaureatCreationDev'],
          this.contactForm.controls['entrepriseCreeAutreProjet']
        );
      }

      this.contactForm.controls['entrepriseCreeAutreProjet'] &&
        this.contactForm.controls['entrepriseCreeAutreProjet'].valueChanges.subscribe(x => {
          delete this.contactForm.controls['raisonSocialeEntrepriseCreeAutreProjet'];
          delete this.contactForm.controls['dateCreationEntrepriseCreeAutreProjet'];
          delete this.contactForm.controls['sirenEntrepriseCreeAutreProjet'];
          if (x === true) {
            this.contactForm.addControl('raisonSocialeEntrepriseCreeAutreProjet', new FormControl(null, Validators.required));
            this.contactForm.addControl('dateCreationEntrepriseCreeAutreProjet', new FormControl(null, Validators.required));
            this.contactForm.addControl('sirenEntrepriseCreeAutreProjet', new FormControl(null, Validators.required));
            this.helperService.toggleForm(
              this.contactForm.controls['laureatCreationDev'],
              this.contactForm.controls['raisonSocialeEntrepriseCreeAutreProjet'],
              this.contactForm.controls['dateCreationEntrepriseCreeAutreProjet'],
              this.contactForm.controls['sirenEntrepriseCreeAutreProjet']
            );
          }
        });
    });

    this.contactForm.controls['dejaCandidat']['controls'].radioToggle.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      if (x)
        this.helperService.toggleForm(
          this.contactForm.controls['dejaCandidat']['controls'].radioToggle,
          this.contactForm.controls['dejaCandidat']['controls'].value
        );
    });

    this.contactForm.controls['laureatPEPITETEE']['controls'].radioToggle.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
      if (x)
        this.helperService.toggleForm(
          this.contactForm.controls['laureatPEPITETEE']['controls'].radioToggle,
          this.contactForm.controls['laureatPEPITETEE']['controls'].value
        );
    });

    this.contactForm.controls['laureatEmergenceAutreProjet']['controls'].radioToggle.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        if (x)
          this.helperService.toggleForm(
            this.contactForm.controls['laureatEmergenceAutreProjet']['controls'].radioToggle,
            this.contactForm.controls['laureatEmergenceAutreProjet']['controls'].value
          );
      });
  }

  downloadNETVA(): void {
    window.open(environment.docPublicBaseUrl + URL_NETVA);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onKeyDown(event: any) {
    event.target.value = DateTimeUtils.formatDateForFront(event.target.value);
  }

  protected readonly EnumGenre = EnumGenre;
}
