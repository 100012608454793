import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pxl-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
})
export class PageWrapperComponent {
  @Input() title: string;
  @Input() backLinkPath: string;
  @Input() backLinkText: string;
  constructor(private router: Router, private route: ActivatedRoute) {}

  goTo() {
    this.router.navigate([`../${this.backLinkPath || ''}`], { relativeTo: this.route });
  }
}
