import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
  Aap,
  Contact,
  DocumentAapModel,
  DocumentProjet,
  EnumMotifNonRattachement,
  EnumProjetEtape,
  EnumRoleContact,
  EnumRoleStructure,
  EnumScanDocument,
  EnumScope,
  NumberUtils,
  Projet,
  Structure,
} from '@shared-ui';
import { isNil } from 'lodash';
import { SharedFunction } from './sharedFunction';

export class StructureValidationFunction {
  readonly REPRESENTANT_LEGAL = (EnumRoleContact as any)[EnumRoleContact.REPRESENTANT_LEGAL.toString()];
  readonly AUTRE = (EnumRoleContact as any)[EnumRoleContact.AUTRE.toString()];
  readonly RESPONSABLE_PROJET = (EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_PROJET.toString()];
  readonly RESPONSABLE_ADMINISTRATIF = (EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_ADMINISTRATIF.toString()];

  public sharedFunction: SharedFunction = new SharedFunction();

  /**
   * Vérifier si un responsable projet/responsable administratif et financier existe pour un chef de file ou classique
   */
  checkExistanceResponsable(structure: Structure, role: EnumRoleContact): boolean {
    if (structure.role === EnumRoleStructure.MANDATAIRE) {
      return true;
    } else {
      return structure.contacts?.some(contact => {
        return !contact.inactif && contact.roles?.length > 0 && contact.roles.indexOf((EnumRoleContact as any)[role.toString()]) !== -1;
      });
    }
  }

  /**
   * Vérifier si responsable Administratif et Financier existe pour un chef de file ou classique
   */
  checkExistanceResponsableAdministratif(structure: Structure): boolean {
    if (structure.role === EnumRoleStructure.MANDATAIRE) {
      return true;
    } else {
      let ra = false;
      return structure.contacts?.some(contact => {
        if (!contact.inactif && contact.roles?.length > 0) {
          if (contact.roles.indexOf((EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_ADMINISTRATIF.toString()]) !== -1) {
            ra = true;
          }
        }
        return ra;
      });
    }
  }

  /**
   * Vérifier si responsable Projet existe pour une équipe
   */
  checkExistanceResponsableProjetEquipe(structure: Structure): boolean {
    if (structure.role === EnumRoleStructure.MANDATAIRE) {
      return true;
    } else {
      let rpe = false;
      return structure.equipe?.some(equipe => {
        if (equipe.identite?.responsableProjet) {
          rpe = true;
        }
        return rpe;
      });
    }
  }

  checkInfosSiegeInfosStructure(structure: Structure): boolean {
    if (structure?.raisonSiret === EnumMotifNonRattachement.EN_COURS_DE_CREATION) {
      return true;
    }
    return !!structure?.adresse && !!structure?.typeStructure && structure?.adresse?.cp != null;
  }

  checkLieuDeRealisation(structure: Structure): boolean {
    if (structure?.lieuRD?.raisonSiret === EnumMotifNonRattachement.EN_COURS_DE_CREATION) {
      return !!structure?.lieuRD?.raisonSocial;
    }
    return !!structure?.lieuRD?.raisonSocial || !!structure?.lieuRD?.typeStructure || structure?.lieuRD?.typeStructure !== undefined;
  }

  checkRepresentantLegal(structure: Structure): boolean {
    return structure?.contacts?.filter(contact => contact.roles.includes(this.REPRESENTANT_LEGAL)).length !== 0;
  }

  hasAutresContacts(structure: Structure): boolean {
    const contactsActifs = structure.contacts.filter(contact => !contact.inactif);
    if (structure.role !== EnumRoleStructure.MANDATAIRE) {
      return (
        contactsActifs.filter(
          contact =>
            contact.roles.includes(this.RESPONSABLE_PROJET) ||
            contact.roles.includes(this.RESPONSABLE_ADMINISTRATIF) ||
            contact.roles.includes(this.AUTRE)
        ).length !== 0
      );
    }
    return contactsActifs.length !== 0;
  }

  canAddAutresContacts(structure: Structure): boolean {
    const contactsActifs = structure.contacts.filter(contact => !contact.inactif);
    if (structure.role !== EnumRoleStructure.MANDATAIRE) {
      return (
        contactsActifs.filter(contact => contact.roles.includes(this.RESPONSABLE_PROJET) || contact.roles.includes(this.AUTRE)).length < 6
      );
    }
    return contactsActifs.length !== 0;
  }

  isBudgetComplete(aap: Aap, projet: Projet, structure: Structure): boolean {
    const etapeProjet = this.sharedFunction.getProjectEtapeName(projet);
    const budgetPropertyStr = etapeProjet === EnumProjetEtape.PRE_DEPOT ? 'budgetPreDepot' : 'budgetDepot';
    // No check for structure in PRE_DEPOT if budgetEstime is true.
    if (aap?.budgetEstime === true && etapeProjet === EnumProjetEtape.PRE_DEPOT) {
      return true;
    }

    return (
      structure[budgetPropertyStr]?.besoin === false ||
      (structure[budgetPropertyStr]?.besoin === true &&
        NumberUtils.isNumeric(structure[budgetPropertyStr]?.montant) &&
        NumberUtils.isNumeric(structure[budgetPropertyStr]?.montantAideDemande))
    );
  }

  isBudgetSet(aap: Aap, projet: Projet, structure: Structure): boolean {
    const etapeProjet = this.sharedFunction.getProjectEtapeName(projet);
    const budgetPropertyStr = etapeProjet === EnumProjetEtape.PRE_DEPOT ? 'budgetPreDepot' : 'budgetDepot';
    // No check for structure in PRE_DEPOT if budgetEstime is true.
    if (aap?.budgetEstime === true && etapeProjet === EnumProjetEtape.PRE_DEPOT) {
      return true;
    }

    return (
      !isNil(structure) &&
      !isNil(structure[budgetPropertyStr]?.besoin) &&
      (!isNil(structure[budgetPropertyStr]?.montant) || structure[budgetPropertyStr]?.besoin === false)
    );
  }

  isBudgetRequested(projet: Projet, structure: Structure): boolean {
    const etapeProjet = this.sharedFunction.getProjectEtapeName(projet);
    const budgetPropertyStr = etapeProjet === EnumProjetEtape.PRE_DEPOT ? 'budgetPreDepot' : 'budgetDepot';
    return structure[budgetPropertyStr]?.besoin === true;
  }

  structureNeedsToFillRibIban(projet: Projet, aap: Aap, structure: Structure): boolean {
    if (aap?.budgetEstime === true && this.sharedFunction.getProjectEtapeName(projet) === EnumProjetEtape.PRE_DEPOT) {
      return false;
    }

    return (
      structure.role !== EnumRoleStructure.MANDATAIRE &&
      !structure.raisonSiret &&
      this.isBudgetSet(aap, projet, structure) &&
      this.isBudgetRequested(projet, structure)
    );
  }

  structureComplete(projet: Projet, aap: Aap, structure: Structure): boolean {
    const etapeProjet = this.sharedFunction.getProjectEtapeName(projet);

    return this.structureBudgetComplete(aap, structure, etapeProjet) && this.structureTypeComplete(structure);
  }

  structureTypeComplete(structure: Structure): boolean {
    return structure.typeStructure != null;
  }

  structureBudgetComplete(aap: Aap, structure: Structure, etapeProjet: EnumProjetEtape | null): boolean {
    return (
      structure.role === EnumRoleStructure.MANDATAIRE ||
      (structure?.budgetDepot?.montant !== null &&
        (etapeProjet !== EnumProjetEtape.PRE_DEPOT || (etapeProjet === EnumProjetEtape.PRE_DEPOT && !aap.budgetEstime)))
    );
  }

  needsDocuments(projet: Projet, aap: Aap, structure: Structure): boolean {
    return (
      (structure.role === EnumRoleStructure.MANDATAIRE || !!structure.budgetDepot?.besoin) &&
      this.sharedFunction.getDocumentsAapForStructure(aap, structure, projet, EnumScope.STRUCTURE).length !== 0
    );
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isValidDocument(document: DocumentProjet): boolean {
    return document?.scan === EnumScanDocument.SAFE;
  }

  isScanedDocument(document: DocumentProjet): boolean {
    return document?.scan === EnumScanDocument.SAFE || document?.scan === EnumScanDocument.UNSAFE;
  }

  checkStructureDocs(
    aap: Aap,
    projet: Projet,
    scope: EnumScope,
    listDocumentStructure: DocumentProjet[],
    listDoc: DocumentAapModel[]
  ): boolean {
    const listDocForThisStep = listDoc?.filter(
      document => document.etapes[0] === this.sharedFunction.getProjectEtapeName(projet)?.toString() && document.scope === scope
    );
    return (
      !listDocForThisStep.length ||
      !!(listDocumentStructure.length && listDocumentStructure.some(doc => listDocForThisStep.some(sDoc => doc.type === sDoc.nomDocument)))
    );
  }

  isStructureNeitherClosedNorMandataire(structure: Structure): boolean {
    return !structure.closed && structure.role !== EnumRoleStructure.MANDATAIRE;
  }

  /**
   * -> Pour toutes les structures
   * Contact -> validation identité/ contact
   *
   * -> si "Structure en cours de création"
   * et Contact RP -> validation identité/ contact/ Adresse perso
   * @param structure
   */
  public isStructureContactCorrect(contact: Contact, raisonSiret: EnumMotifNonRattachement): boolean {
    if (!this.isIdentityAndContactValid(contact)) {
      return false;
    }

    if (raisonSiret === EnumMotifNonRattachement.EN_COURS_DE_CREATION && contact?.roles?.includes(this.RESPONSABLE_PROJET)) {
      return this.isPersonalAddressContactValid(contact);
    }
    return true;
  }

  private isIdentityAndContactValid(projectManagerContact: Contact): boolean {
    return (
      projectManagerContact.genre !== null &&
      projectManagerContact.nom !== null &&
      projectManagerContact.prenom !== null &&
      projectManagerContact.telephone !== null &&
      projectManagerContact.email !== null &&
      projectManagerContact.fonction !== null
    );
  }

  private isPersonalAddressContactValid(contact: Contact): boolean {
    const adresse = contact.adresse;

    if (!adresse) {
      return false;
    }

    return (
      adresse.pays !== null &&
      adresse.codePays !== null &&
      adresse.cp != null &&
      adresse.ville !== null &&
      adresse.numero !== null &&
      adresse.voie !== null &&
      adresse.typeVoie !== null
    );
  }

  /**
   * Vérifier si le matricule de l'utilisateur existe dans la valeur du multiselect de la fiche de demande d'aide
   * @param utilisateurMatricule
   * @returns
   */
  utilisateurMatriculeExistedValidator(utilisateurMatricule: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValid = false;

      if (control.value?.length > 0 && !control.value?.includes(utilisateurMatricule)) {
        isValid = true;
      }

      return isValid ? { uncheckedUser: true } : null;
    };
  }

  /**
   * Fonctionne extrayant les contacts actifs des structures d'un projet
   * @param structures : array de structures d'un projet
   * @returns array de contacts
   */
  getContactsStructuresAap(structures: Structure[]): Contact[] {
    return structures
      .filter(structure => !structure.closed)
      .flatMap(structure => structure.contacts)
      .filter(contact => !contact.inactif);
  }

  /**
   * Vérifier si effectifSiege et effectifGroupe, fonction dans representantLegal
   * budgetTotal et montantAideDemande existent
   * @param structure
   * @param projetEtape
   */
  checkStructureBeforeGenerateFicheAide(structure: Structure, projet: Projet, aap: Aap, etapeProjet: EnumProjetEtape): boolean {
    let result = true;

    structure.contacts
      .filter(contact => contact.roles.includes(this.REPRESENTANT_LEGAL))
      .forEach(contact => {
        if (contact.fonction == null) {
          result = false;
        }
      });

    if (structure.effectifGroupe == null || structure.effectifSiege == null) {
      result = false;
    }

    if (
      etapeProjet === EnumProjetEtape.PRE_DEPOT &&
      structure.budgetPreDepot.montant == null &&
      structure.budgetPreDepot.montantAideDemande == null
    ) {
      result = false;
    }

    if (
      etapeProjet === EnumProjetEtape.DEPOT &&
      structure.budgetDepot.montant == null &&
      structure.budgetDepot.montantAideDemande == null
    ) {
      result = false;
    }

    if (!this.isBudgetComplete(aap, projet, structure)) {
      result = false;
    }

    return result;
  }
}
