import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DonneesFinancieres, Environment, Structure } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class DonneesFinancieresHttpService {
  private readonly headers = new HttpHeaders();
  private structureUrl = '';

  constructor(@Inject('environment') private environment: Environment, private http: HttpClient) {
    this.structureUrl = this.environment.apiUrl + 'structures';
    this.headers = this.headers.set('Content-Type', 'application/json; charset=utf-8');
  }

  public putStructureDonneesFinancieres(structureId: string, donneesFinancieres: DonneesFinancieres) {
    const url = `${this.structureUrl}/${structureId}/donnees-financieres`;
    return this.http.put<Structure>(url, donneesFinancieres, { headers: this.headers });
  }
}
