<div>
  <div [ngClass]="isFullScreen ? 'displayNone' : 'displayBlock'">
    <div class="d-flex flex-wrap flex-column">
      <div class="d-flex justify-content-between">
        <button class="ds-btn ds-btn--secondary align-items-end" type="button" (click)="toggleForm()">
          <div class="d-flex">
            <div class="me-2">Personnaliser l'affichage</div>
            <div><lib-pxl-icon class="icon" icon="icon-filter"></lib-pxl-icon></div>
          </div>
        </button>
      </div>
      <div class="checkbox-container">
        <div *ngFor="let item of checkboxList" class="checkboxRow">
          <mat-checkbox [checked]="item.hide" (change)="hideColumn($event, item.def)">{{ item.label }}</mat-checkbox>
        </div>
      </div>
      <div>
        <ng-container *ngIf="afficherOnglets">
          <div *ngFor="let lot of filter" class="me-2 d-inline-flex containerFilter">
            <button
              id="actions-btn"
              (click)="applyFilter(lot)"
              class="d-flex justify-content-between justify-content-center align-items-center buttonFilter"
              [ngClass]="{ active: isActive(lot) }"
              matTooltip="{{ lotName(lot) }}"
            >
              <lib-pxl-icon
                class="card__check"
                [ngClass]="{ valid: isActive(lot) }"
                icon="icon-action-check-circle"
                aria-hidden="true"
              ></lib-pxl-icon>
              {{ 'Lot ' + lot }}
              <button
                class="menuButton"
                (change)="saveLot(lot)"
                (click)="$event.stopPropagation()"
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="icon-button with a menu"
              >
                <lib-pxl-icon class="icon-info" icon="icon-3-dot-menu" />
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <span (click)="onDeleteElement(lot)">Supprimer ce lot</span>
                </button>
              </mat-menu>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
    <div (click)="toggleFullScreen()" class="display-6">
      <lib-pxl-icon class="icon" icon="icon-full-screen"></lib-pxl-icon>
    </div>
  </div>

  <lib-table-synthese
    [tachesByLots]="dataTable"
    [showOngletTab]="afficherOnglets"
    [filterChange]="selected[0]"
    [disColumns]="disColumns"
    [isFullScreen]="isFullScreen"
    [readOnly]="readOnly"
    [updateData]="newCommentaire"
    (lotsTachesChanges)="lotChange($event)"
    (cellSelectedEvent)="openSideNav($event)"
  ></lib-table-synthese>
</div>
