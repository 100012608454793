import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentProjet } from '../../shared/models/_public_models';
import { ShowToastrService } from '../../shared/services/show-toastr.service';
import { SubscriptionDestroyerComponent } from '../../shared/subscription-destroyer/subscription-destroyer.abstract';
import { StructureValidationFunction } from '../../utils/structure-validation';
import { DocumentHelper } from '../../utils/document-helper';
import { DocumentFileManagerService } from '../../shared/services/document-file-manager.service';

@Component({
  selector: 'lib-document-light',
  templateUrl: './document-light.component.html',
  styleUrls: ['./document-light.component.scss'],
})
export class DocumentLightComponent extends SubscriptionDestroyerComponent {
  @Input() projetId: string;
  @Input() document: DocumentProjet;
  @Input() subDirectory?: string;
  @Input() canUserWrite: boolean;
  @Input() hideDateCreation = false;
  @Input() hideType = false;
  @Input() hasCheckbox = false;
  @Input() isChecked = false;
  @Input() isSiteAdmin?: boolean;

  @Output() deleteDocument = new EventEmitter<DocumentProjet>();
  @Output() isCheckedChange = new EventEmitter<boolean>();

  constructor(
    public structureValidationFunction: StructureValidationFunction,
    public showToastrService: ShowToastrService,
    public documentFileManagerService: DocumentFileManagerService
  ) {
    super();
  }

  isScannedDocument(document: DocumentProjet): boolean {
    return this.structureValidationFunction.isScanedDocument(document);
  }

  isValidDocument(document: DocumentProjet): boolean {
    return this.structureValidationFunction.isValidDocument(document);
  }

  getNomCreateurDocument(document: DocumentProjet): string {
    return DocumentHelper.getNomCreateurDocument(document);
  }

  onDeleteDocument(): void {
    this.deleteDocument.emit(this.document);
  }

  checkChange(): void {
    this.isCheckedChange.emit(this.isChecked);
  }

  downloadDocument(document: DocumentProjet): void {
    this.documentFileManagerService.downloadDocument(document, this.isSiteAdmin).subscribe();
  }
}
