<table
  *ngIf="dataSource"
  mat-table
  [dataSource]="dataSource"
  [class.responsive]="tableReponsive"
  multiTemplateDataRows
  class="table-responsive mat-elevation-z0"
>
  <ng-container *ngFor="let col of cols; let colIndex = index" [matColumnDef]="col.def" [sticky]="col.sticky">
    <th mat-header-cell *matHeaderCellDef class="column-key" [ngStyle]="{ 'max-width': col.width, 'min-width': col.width }" class="fw-bold">
      <div>{{ col.label }}</div>
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = dataIndex"
      [ngStyle]="{ 'max-width': col.width, 'min-width': col.width }"
      [attr.colspan]="element.isHeader ? displayedColumnslength : 1"
      [class.hidden]="colIndex !== 0 && element.isHeader"
      [class.noBorder]="element.isHeader"
    >
      <div *ngIf="arrayTitle && colIndex === 0 && i === 0" class="mt-2 mb-2 fw-bolder">
        {{ arrayTitle }}
      </div>
      <div>
        <ng-container *ngIf="(previsionEconomiqueStatut === 'VALIDE' ? true : false) || !col.editable">
          <ng-container *ngIf="element.data[col.def]; else other">
            {{ element.data[col.def]?.value | formatNumber : col }}
          </ng-container>
          <ng-template #other>
            <span [style]="element.style">
              <ng-container *ngIf="element.isHeader && element.label !== ''; else notProduit">
                <div>{{ 'Produit - ' + element[col.def] }}</div>
                <div>
                  <a
                    *ngIf="previsionEconomiqueStatut !== 'VALIDE' && actionButton && structureUpdatableByUser"
                    role="button"
                    (click)="deleteEvent(element[col.def])"
                    class="text-decoration-underline d-inline-flex align-items-center"
                  >
                    <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
                    Supprimer un produit
                  </a>
                  <a
                    *ngIf="previsionEconomiqueStatut !== 'VALIDE' && actionButton && structureUpdatableByUser"
                    role="button"
                    (click)="updateEvent(element[col.def])"
                    class="ms-2 text-decoration-underline d-inline-flex align-items-center"
                  >
                    <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
                    Modifier un produit
                  </a>
                </div>
              </ng-container>
              <ng-template #notProduit>
                <span>{{ element[col.def] }}</span>
              </ng-template>
            </span>
          </ng-template>
        </ng-container>

        <div *ngIf="col.editable && (previsionEconomiqueStatut === 'VALIDE' ? false : true) && structureUpdatableByUser">
          <input
            *ngIf="col.editable"
            [id]="col.def"
            [name]="col.def"
            [disabled]="previsionEconomiqueStatut === 'VALIDE' ? true : false"
            [placeholder]="col.placeholder ?? ''"
            [value]="element.data[col.def]?.value || null"
            (change)="onDataChange($event, element, col.def)"
            (keydown)="onKeyPress($event, element)"
            type="text"
            class="input-cell ds-input-group__input"
          />
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = dataIndex"></tr>
</table>
