import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { ContractualisationService } from '@services-candidat/contractualisation.service';
import { StructureService } from '@services-candidat/structure.service';
import { SyntheseContractualisationStructure } from '@shared-ui';
import { combineLatest, map, switchMap } from 'rxjs';

@Component({
  selector: 'app-signature-contrat',
  templateUrl: './signature-contrat.component.html',
  styleUrls: ['./signature-contrat.component.scss'],
})
export class SignatureContratComponent implements OnInit {
  syntheses: SyntheseContractualisationStructure[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    private structureService: StructureService,
    private route: ActivatedRoute,
    private contractualisationService: ContractualisationService
  ) {}

  ngOnInit(): void {
    this.structureService
      .getSyntheseContractualisationStructures(this.route.snapshot.parent?.params.id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(syntheses => {
          const syntheseObservables = syntheses.body.map(synthese =>
            this.contractualisationService
              .hasSignataireInterneSignedContractOfStructure(synthese.id)
              .pipe(map(hasInterneSigned => (this.syntheseHasHistory(synthese) || hasInterneSigned ? synthese : null)))
          );
          return combineLatest(syntheseObservables);
        })
      )
      .subscribe(results => {
        this.syntheses = results.filter(synthese => synthese !== null);
      });
  }

  syntheseHasHistory(synthese: SyntheseContractualisationStructure): boolean {
    return synthese.proceduresSignatures.length > 1;
  }

  onGoToStructure(id: string): void {
    console.log('Go to structure with id: ', id); // To implement
  }
}
