import { Component, Input, OnInit } from '@angular/core';
import { EnumStatutSignature, SignataireProcedure, SyntheseContractualisationStructure, Utilisateur } from '@shared-ui';

declare const Yousign: any;

@Component({
  selector: 'lib-signature-contrat-frame',
  templateUrl: './signature-contrat-frame.component.html',
  styleUrls: ['./signature-contrat-frame.component.scss'],
})
export class SignatureContratFrameComponent implements OnInit {
  @Input() synthesesStructures!: SyntheseContractualisationStructure[];
  @Input() utilisateur!: Utilisateur;
  @Input() structureId!: string;
  @Input() currentSigner!: SignataireProcedure;

  confirmation = false;
  private yousign: any;
  needToSign: boolean;

  constructor() {}

  ngOnInit(): void {
    this.needToSign = this.currentSigner.signature.statut === EnumStatutSignature.A_SIGNER;
    this.confirmation = !this.needToSign;
  }

  onConfirmation() {
    if (this.confirmation && this.needToSign) {
      const link = this.currentSigner?.signature?.url + '&disable_domain_validation=true';
      this.yousign = new Yousign({
        signatureLink: link,
        iframeContainerId: 'iframe-container',
        isSandbox: false,
        iFrameAttributes: {},
      });
      this.resizeIFrame();
      this.onSignatureError();
      this.onSignatureSuccess();
      this.onSignatureDeclined();
    }
  }

  private resizeIFrame() {
    const iFrame = document.getElementById('yousign-iframe') as any;
    iFrame.width = '100%';
    iFrame.height = window.innerHeight + 'px';
    iFrame.addEventListener('message', (event: any) => {
      console.log(event);
    });
  }

  private onSignatureSuccess() {
    this.yousign.onSuccess((data: any) => {
      console.log(data);
    });
  }

  private onSignatureDeclined() {
    this.yousign.onDeclined((data: any) => {
      console.log(data);
    });
  }

  private onSignatureError() {
    this.yousign.onError((data: any) => {
      console.log(data);
    });
  }
}
