<!-- Entête de la page -->
<header class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <div *ngIf="backLinkText" class="page-header__back" (click)="goTo()">
    <lib-pxl-icon class="icon-left" icon="icon-arrow-chevron-left" aria-hidden="true"></lib-pxl-icon>
    <a>{{ backLinkText }}</a>
  </div>
  <h2 class="page-header__title">{{ title }}</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <ng-content></ng-content>
</div>
<!-- FIN de Contenu de la page -->
