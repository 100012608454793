import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccueilComponent } from './accueil.component';
import { SharedModule } from '@shared-candidat/shared.module';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [AccueilComponent],
  imports: [CommonModule, SharedModule, SharedUiModule],
})
export class AccueilModule {}
