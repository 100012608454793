import { FormGroup } from '@angular/forms';
import { Aap, DocumentAapModel, DocumentProjet, EnumProjetEtape, EnumScope, Projet, Structure } from '@shared-ui';

export class SharedFunction {
  getProjectEtapeName(projet: Projet): EnumProjetEtape | null {
    if (projet?.etapes == null) {
      return null;
    }
    return projet?.etapes[projet?.etapes.length - 1].nom;
  }

  getDocumentsAapForStructure(aap: Aap, structure: Structure, projet: Projet, scope: EnumScope): DocumentAapModel[] {
    const projetEtapeName = this.getProjectEtapeName(projet);
    return aap.documentAaps?.filter((docAap: any) => {
      return (
        docAap.scope === scope &&
        docAap.etapes.includes(projetEtapeName) &&
        docAap.typePartenaires?.includes(projet.partenaireType) &&
        docAap.roleStructures?.includes(structure.role) &&
        docAap.typeStructures.includes(structure.typeStructure)
      );
    });
  }

  isDisabledFormValid(form: FormGroup): boolean {
    const wasDisabled = form?.disabled;
    form?.enable();
    const formValid = form?.valid;
    if (wasDisabled) {
      form?.disable();
    }
    return formValid;
  }

  getDocumentName(document: DocumentProjet): string {
    if (!document.nom) {
      return 'Masqué';
    } else {
      return document.nom;
    }
  }
}
