import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { PeriodeFinanciere, SaisieFinanciere, SectionFinanciere, Structure } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class SignalService {
  private structuresSignal: WritableSignal<Structure[]> = signal([]);
  private structureSignal: WritableSignal<Structure> = signal({} as Structure);
  private comptesResultatsSignal: WritableSignal<PeriodeFinanciere[]> = signal([]);
  private plansFinancementSignal: WritableSignal<PeriodeFinanciere[]> = signal([]);
  private plansTresorerieSignal: WritableSignal<PeriodeFinanciere[]> = signal([]);

  // Structure

  public setStructures(structures: Structure[]): void {
    this.structuresSignal.set(structures);
  }

  public setStructure(structure: Structure): void {
    this.structureSignal.set(structure);
  }

  public getStructure(): Structure {
    return this.structureSignal();
  }

  public getStructureById(id: string): Structure | undefined {
    const structureSignal: Signal<Structure | undefined> = computed(() =>
      this.structuresSignal().find((structure: Structure) => structure.id === id)
    );
    return structureSignal();
  }

  // DonneesFinancieres

  private updatePeriodesFinancieres(
    periodesFinancieres: PeriodeFinanciere[],
    newValue: number,
    periode: string,
    sectionLabel: string,
    saisieLabel: string
  ): PeriodeFinanciere[] {
    const pfIndex: number = periodesFinancieres.findIndex((pf: PeriodeFinanciere) => pf.periode === periode);
    const pf: PeriodeFinanciere = periodesFinancieres[pfIndex];
    if (pf) {
      const sections: SectionFinanciere[] = pf.sections;
      const secIndex: number = sections.findIndex((sf: SectionFinanciere) => sf.label === sectionLabel);
      const sf: SectionFinanciere = sections[secIndex];
      if (sf) {
        const saisies: SaisieFinanciere[] = sf.saisies;
        const saiFiIndex: number = saisies.findIndex((sf: SaisieFinanciere) => sf.label === saisieLabel);
        if (saiFiIndex !== -1) {
          periodesFinancieres[pfIndex].sections[secIndex].saisies[saiFiIndex].saisie = newValue;
        }
      }
    }
    return periodesFinancieres;
  }

  //  ComptesResultats

  public setComptesResultats(periodesFinancieres: PeriodeFinanciere[]) {
    this.comptesResultatsSignal.set(periodesFinancieres);
  }

  public updateComptesResultats(newValue: number, periode: string, sectionLabel: string, saisieLabel: string): void {
    this.comptesResultatsSignal.update((periodesFinancieres: PeriodeFinanciere[]) =>
      this.updatePeriodesFinancieres(periodesFinancieres, newValue, periode, sectionLabel, saisieLabel)
    );
  }

  public getComptesResultats(): PeriodeFinanciere[] {
    return this.comptesResultatsSignal();
  }

  // Plan de financement

  public setPlanFinancement(periodesFinancieres: PeriodeFinanciere[]) {
    this.plansFinancementSignal.set(periodesFinancieres);
  }

  public updatePlanFinancement(periode: string, sectionLabel: string, saisieLabel: string, newValue: number): void {
    this.plansFinancementSignal.update((periodesFinancieres: PeriodeFinanciere[]) =>
      this.updatePeriodesFinancieres(periodesFinancieres, newValue, periode, sectionLabel, saisieLabel)
    );
  }

  public getPlanFinancement(): PeriodeFinanciere[] {
    return this.plansFinancementSignal();
  }

  // Plan de trésorerie

  public setPlansTresorerie(periodesFinancieres: PeriodeFinanciere[]) {
    this.plansTresorerieSignal.set(periodesFinancieres);
  }

  public updatePlansTresorerie(periode: string, sectionLabel: string, saisieLabel: string, newValue: number): void {
    this.plansTresorerieSignal.update((periodesFinancieres: PeriodeFinanciere[]) =>
      this.updatePeriodesFinancieres(periodesFinancieres, newValue, periode, sectionLabel, saisieLabel)
    );
  }

  public getPlansTresorerie(): PeriodeFinanciere[] {
    return this.plansTresorerieSignal();
  }
}
