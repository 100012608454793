import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';

import { ProjetListViewDto } from '@shared-ui';
import { ProjetListViewService } from '@services-candidat/projet-list-view.service';

export const projetListViewResolver: ResolveFn<ProjetListViewDto[]> = (): Observable<ProjetListViewDto[]> => {
  const service: ProjetListViewService = inject(ProjetListViewService);

  return service.getCombinedProjets();
};
