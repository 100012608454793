import { Component, Input, OnInit } from '@angular/core';
import { noop } from 'rxjs';
import { DynamicTableComponent, DynamicTableDataSource } from '../../dynamic-table/dynamic-table.component';
import { Cell, CellNumberValue } from '../../dynamic-table/dynamic-table.model';
import { TableDataRecord, WarningAmountsByPeriod } from '../../../shared/models/donnees-financieres.model';
import { DonneesFinancieresHelperService } from '../../../shared/services/donnees-financieres/donnees-financieres.helper.service';
import { SignalService } from '../../../shared/services/signal.service';
import {
  SaisieLabel_PlanFinancement as SLPF,
  SectionFinanciere_PlanFinancement as SFPF,
  SectionFinanciere_NameSpace_PlanFinancement,
  TOOLTIP_BY_ROW_PLAN_FINANCEMENT,
} from './plan-financement.model';

@Component({
  selector: 'lib-projet-consortium-df-plan-financement',
  templateUrl: '../../dynamic-table/dynamic-table.component.html',
  styleUrls: ['../../dynamic-table/dynamic-table.component.css'],
})
export class ProjetConsortiumDfPlanFinancementComponent extends DynamicTableComponent implements OnInit {
  @Input() public upTitleRow = false;

  private valueBySaisieBySectionByPeriod: TableDataRecord = {};

  private sumSaisies = (colDef: string, sfpf: SFPF, saisiesToSum: SLPF[]): number => {
    return saisiesToSum.map(saisie => this.valueBySaisieBySectionByPeriod[colDef][sfpf][saisie]).reduce((sum, current) => sum + current, 0);
  };

  private resultsCalculatorBySectionFinanciere: Record<SFPF, (colDef: string) => void> = {
    [SFPF.TOTAL_BESOINS]: (colDef: string) => {
      // Total des besoins
      const idp: number = this.valueBySaisieBySectionByPeriod[colDef][SFPF.TOTAL_BESOINS][SLPF.IDP];
      const bfrdim: number = this.valueBySaisieBySectionByPeriod[colDef][SFPF.TOTAL_BESOINS][SLPF.BFRDIM];
      const totalBesoins: number =
        this.sumSaisies(colDef, SFPF.TOTAL_BESOINS, [SLPF.ILICRP, SLPF.IC, SLPF.BFRAUG, SLPF.RC, SLPF.DIVERS]) + idp - bfrdim;
      this.valueBySaisieBySectionByPeriod[colDef][SFPF.TOTAL_BESOINS][SFPF.TOTAL_BESOINS] = totalBesoins;
      this.updateDataSource(colDef, SFPF.TOTAL_BESOINS, totalBesoins);
      this.emitIdpValue(colDef, idp);
      this.resultsCalculatorBySectionFinanciere[SFPF.TOTAL_RESSOURCES](colDef);
    },

    [SFPF.TOTAL_RESSOURCES]: (colDef: string) => {
      // Total des ressources
      const totalRessources = this.sumSaisies(colDef, SFPF.TOTAL_RESSOURCES, [SLPF.AC, SLPF.ACC, SLPF.CA, SLPF.EDN, SLPF.ERN, SLPF.AE]);
      this.valueBySaisieBySectionByPeriod[colDef][SFPF.TOTAL_RESSOURCES][SFPF.TOTAL_RESSOURCES] = totalRessources;
      this.updateDataSource(colDef, SFPF.TOTAL_RESSOURCES, totalRessources);

      // Solde de trésorerie
      const totalBesoins = this.valueBySaisieBySectionByPeriod[colDef][SFPF.TOTAL_BESOINS][SFPF.TOTAL_BESOINS] || 0;
      const soldeTresorerie: number = totalRessources - totalBesoins;
      this.valueBySaisieBySectionByPeriod[colDef][SFPF.SOLDE_TRESORERIE] = {
        [SFPF.SOLDE_TRESORERIE]: soldeTresorerie,
      };
      this.updateDataSource(colDef, SFPF.SOLDE_TRESORERIE, soldeTresorerie);

      // Cumul de trésorerie :
      let cumulTresorerie = 0;
      const colIndex: number = this.displayedColumns.findIndex((col: string) => col === colDef);
      if (colIndex === 1) {
        // Si année courante
        cumulTresorerie = soldeTresorerie;
      } else {
        // Si année postérieure
        const previousIndex: number = colIndex - 1;
        const previousCol: string = this.displayedColumns[previousIndex];
        const previousCumulTresorerieByPeriode: Record<string, number> | undefined =
          this.valueBySaisieBySectionByPeriod[previousCol][SFPF.CUMUL_TRESORERIE];
        const previousCumulTresorerie: number =
          (previousCumulTresorerieByPeriode && previousCumulTresorerieByPeriode[SFPF.CUMUL_TRESORERIE]) || 0;
        cumulTresorerie = previousCumulTresorerie + soldeTresorerie;
      }
      this.valueBySaisieBySectionByPeriod[colDef][SFPF.CUMUL_TRESORERIE] = {
        [SFPF.CUMUL_TRESORERIE]: cumulTresorerie,
      };
      this.updateDataSource(colDef, SFPF.CUMUL_TRESORERIE, cumulTresorerie);
    },

    [SFPF.SOLDE_TRESORERIE]: () => noop(),
    [SFPF.CUMUL_TRESORERIE]: () => noop(),
  };

  constructor(protected override service: DonneesFinancieresHelperService, private signalService: SignalService) {
    super(service);
  }

  ngOnInit(): void {
    // init planFinancement
    this.signalService.setPlanFinancement(this.periodesFinancieres);
    this.buildColumns();
    this.rows = this.buildRows(this.upTitleRow, undefined, TOOLTIP_BY_ROW_PLAN_FINANCEMENT);
    this.dataSource = new DynamicTableDataSource(this.rows);
    this.valueBySaisieBySectionByPeriod = this.initDataRecord();
    this.initTableCalculation(this.periodes);
  }

  private initTableCalculation(periodes: string[]) {
    periodes.forEach((periode: string) => {
      this.resultsCalculatorBySectionFinanciere[SFPF.TOTAL_BESOINS](periode);
    });
  }

  protected override onDataChange = (event: Event, cell: Cell): void => {
    const newValue: string | number | undefined = (event?.target as HTMLInputElement)?.value ?? '';
    const { colDef, sectionLabel, rowDef } = cell;
    const cellNumberValue = new CellNumberValue(newValue).value;
    this.valueBySaisieBySectionByPeriod[colDef][sectionLabel][rowDef] = cellNumberValue;
    this.signalService.updatePlanFinancement(colDef, sectionLabel, rowDef, cellNumberValue);

    const sectionFinanciereLabel: SFPF | undefined = SectionFinanciere_NameSpace_PlanFinancement.toEnum(sectionLabel);
    if (sectionFinanciereLabel) {
      this.resultsCalculatorBySectionFinanciere[sectionFinanciereLabel](colDef);
      // Recalculer tous les cumuls de trésoreries pour les années postérieures
      const colIndex: number = this.displayedColumns.findIndex((col: string) => col === colDef);
      for (let i = colIndex + 1; i < this.nbOfColumns; i++) {
        const postCol: string = this.displayedColumns[i];
        this.resultsCalculatorBySectionFinanciere[SFPF.TOTAL_BESOINS](postCol);
      }
    }
  };

  private emitIdpValue(colDef: string, idp: number): void {
    const warningAmountsByPeriod: WarningAmountsByPeriod = this.service.getWarningAmountsByPeriod();
    warningAmountsByPeriod[colDef].idpSaisie$.next(idp);
    this.service.setWarningAmountsByPeriod(warningAmountsByPeriod);
  }
}
