<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToProjetInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Projet
  </a>
  <h2 class="page-header__title">Budget du projet</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <form id="projet-form" class="form" [formGroup]="budgetForm" (ngSubmit)="modifyProjetInfo()">
    <section class="form-step">
      <div id="anchor1" class="form-step__anchor"></div>
      <header class="form-step__header"></header>

      <div class="form-step__content">
        <!-- Budget du projet -->
        <div
          id="montant"
          class="form-step__budget ds-input-group"
          [class.ds-input-group--error]="!budgetForm.controls['montant'].valid && budgetForm.controls['montant'].touched"
        >
          <label for="montant" class="ds-input-group__label d-flex"
            >Budget présenté estimé (€)<lib-asterisk></lib-asterisk>
            <div [matTooltip]="infoAide" class="d-flex align-items-center">
              <lib-pxl-icon class="icon-info ms-2" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon>
            </div>
          </label>
          <span class="ds-input-group__input">
            <input
              id="creation_projet_budget"
              name="budget"
              (keypress)="onkeypress($event)"
              (blur)="onBlur()"
              (focus)="onFocus()"
              placeholder="Budget présenté estimé"
              [attr.maxlength]="15"
              class="ds-input-group__input"
              formControlName="montant"
              #nameInput
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-budget" *ngIf="!budgetForm.controls['montant'].valid && budgetForm.controls['montant'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un budget du projet</div>
          </ng-container>
        </div>

        <div
          id="input-aide-demande"
          class="form-step__montant ds-input-group"
          [class.ds-input-group--error]="
            !budgetForm.controls['montantAideDemande'].valid && budgetForm.controls['montantAideDemande'].touched
          "
        >
          <label for="montantAideDemande" class="ds-input-group__label d-flex"
            >Montant de l'aide demandée estimé (€)<lib-asterisk></lib-asterisk>
            <div [matTooltip]="infoAideDemande" class="d-flex align-items-center">
              <lib-pxl-icon class="icon-info ms-2" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon>
            </div>
          </label>
          <span class="ds-input-group__input">
            <input
              id="montantAideDemande"
              name="montantAideDemande"
              (keypress)="onkeypress($event)"
              (blur)="onBlur()"
              (focus)="onFocus()"
              placeholder="Montant de l'aide demandée"
              [attr.maxlength]="15"
              class="ds-input-group__input"
              formControlName="montantAideDemande"
              #nameInput
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-budget"
            *ngIf="!budgetForm.controls['montantAideDemande'].valid && budgetForm.controls['montantAideDemande'].touched"
          >
            <div class="ds-input-group__feedback">Veuillez indiquer le montant de l'aide demandée</div>
          </ng-container>
        </div>

        <div
          id="input-budget-total"
          class="form-step__total ds-input-group"
          [class.ds-input-group--error]="!budgetForm.controls['budgetTotal'].valid && budgetForm.controls['budgetTotal'].touched"
        >
          <label for="budgetTotal" class="ds-input-group__label d-flex"
            >Budget total du projet estimé (€)
            <div [matTooltip]="infoAideTotal" class="d-flex align-items-center">
              <lib-pxl-icon class="icon-info ms-2" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon>
            </div>
          </label>
          <span class="ds-input-group__input">
            <input
              id="budgetTotal"
              name="budgetTotal"
              (keypress)="onkeypress($event)"
              (blur)="onBlur()"
              (focus)="onFocus()"
              placeholder="Budget total de votre investissement"
              [attr.maxlength]="15"
              class="ds-input-group__input"
              formControlName="budgetTotal"
              #nameInput
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-budget" *ngIf="!budgetForm.controls['budgetTotal'].valid && budgetForm.controls['budgetTotal'].touched">
            <div class="ds-input-group__feedback">Veuillez indiquer le montant de l'aide demandée</div>
          </ng-container>
        </div>
        <!-- FIN de Budget du projet -->
      </div>
    </section>

    <div class="form-actions">
      <button id="creation_projet_btn_annuler" type="button" class="ds-btn ds-btn--secondary" (click)="onGoToProjetInfo()">Annuler</button>
      <button
        id="creation_projet_btn_ajouter"
        type="submit"
        class="ds-btn ds-btn--primary"
        *ngxPermissionsOnly="['PROJET_WRITE']"
        [disabled]="!sharedFunction.isProjectUpdatable(aap, projet)"
      >
        <span *ngIf="!isProjectHasBudget">Ajouter</span>
        <span *ngIf="isProjectHasBudget">Modifier</span>
      </button>
    </div>
  </form>
</div>
