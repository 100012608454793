import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';

import { Utilisateur } from '@shared-ui';
import { UserService } from '@services-candidat/user.service';

export const utilisateurResolver: ResolveFn<Utilisateur> = (): Observable<Utilisateur> => {
  const userService: UserService = inject(UserService);

  return userService.getUserObservable();
};
