import { Aap, EnumPermissionScope, EnumPermissionUtilisateur, Permission, Projet, ProjetLight, SuiviProjet, Utilisateur } from '@shared-ui';

export class PermissionUtils {
  public static getGlobalPermissions(utilisateur: Utilisateur): EnumPermissionUtilisateur[] {
    return (utilisateur?.perms ?? []).filter(perm => perm.scope === EnumPermissionScope.GLOBAL).map(perm => perm.name);
  }

  public static getProjetPermissions(utilisateur: Utilisateur, projetId: string): EnumPermissionUtilisateur[] {
    return (utilisateur?.perms ?? [])
      .filter(perm => perm.scope === EnumPermissionScope.PROJET && perm.scopeId === projetId)
      .map(perm => perm.name);
  }

  public static hasAnyPermissionOnProjet(
    utilisateur: Utilisateur,
    permissions: EnumPermissionUtilisateur[],
    projet?: Projet | ProjetLight
  ): boolean {
    return permissions.some(perm => this.hasPermissionOnProjet(utilisateur, perm, projet));
  }

  public static hasPermissionOnProjet(
    utilisateur: Utilisateur,
    permission: EnumPermissionUtilisateur,
    projet?: Projet | ProjetLight
  ): boolean {
    return (
      this.hasGlobalOrProjetPermission(utilisateur, permission, projet) ||
      (!!projet &&
        this.searchAapPermission(permission, utilisateur?.perms ?? [], (projet as ProjetLight).aapId ?? (projet as Projet).aap?.id) !==
        undefined)
    );
  }

  public static hasGlobalOrProjetPermission(
    utilisateur: Utilisateur | null | undefined,
    permission: EnumPermissionUtilisateur,
    projet?: Projet | ProjetLight | SuiviProjet
  ): boolean {
    if (!utilisateur) {
      return false;
    }

    const { perms = [] } = utilisateur;

    // Check for global permission
    if (this.searchGlobalPermission(permission, perms)) {
      return true;
    }

    // Check for project-specific permission if a project is provided
    return !!projet && !!this.searchProjetPermission(permission, perms, projet.id);
  }

  public static hasAnyPermissionOnAap(utilisateur: Utilisateur, permissions: EnumPermissionUtilisateur[], aap?: Aap): boolean {
    return permissions.some(perm => this.hasPermissionOnAap(utilisateur, perm, aap));
  }

  public static hasPermissionOnAap(utilisateur: Utilisateur, permission: EnumPermissionUtilisateur, aap?: Aap): boolean {
    return (
      this.searchGlobalPermission(permission, utilisateur?.perms ?? []) !== undefined ||
      (!!aap && this.searchAapPermission(permission, utilisateur?.perms ?? [], aap.id) !== undefined)
    );
  }

  public static hasPermission(utilisateur: Utilisateur, permission: EnumPermissionUtilisateur): boolean {
    return this.searchGlobalPermission(permission, utilisateur?.perms ?? []) !== undefined;
  }

  private static searchProjetPermission(
    permission: EnumPermissionUtilisateur,
    permissions: Permission[],
    projetId: string
  ): Permission | undefined {
    return permissions
      .filter(perm => perm.scope === EnumPermissionScope.PROJET && perm.scopeId === projetId)
      .filter(perm => perm.name === permission || this.normalizedPermission(perm.name) === permission)
      .find(perm => !this.isPermissionExpired(perm));
  }

  private static searchAapPermission(
    permission: EnumPermissionUtilisateur,
    permissions: Permission[],
    aapId: string
  ): Permission | undefined {
    return permissions
      .filter(perm => perm.scope === EnumPermissionScope.AAP && perm.scopeId === aapId)
      .filter(perm => perm.name === permission || this.normalizedPermission(perm.name) === permission)
      .find(perm => !this.isPermissionExpired(perm));
  }

  private static searchGlobalPermission(permission: EnumPermissionUtilisateur, permissions: Permission[]): Permission | undefined {
    return permissions
      .filter(perm => perm.scope === EnumPermissionScope.GLOBAL)
      .filter(perm => perm.name === permission || this.normalizedPermission(perm.name) === permission)
      .find(perm => !this.isPermissionExpired(perm));
  }

  private static normalizedPermission(permission: EnumPermissionUtilisateur): EnumPermissionUtilisateur {
    if (permission.endsWith('_ALL')) {
      const normalizedPermission = permission.replace('_ALL', '');
      if (Object.values(EnumPermissionUtilisateur).includes(normalizedPermission as EnumPermissionUtilisateur)) {
        return normalizedPermission as EnumPermissionUtilisateur;
      }
    }
    return permission;
  }

  private static isPermissionExpired(permission: Permission): boolean {
    return (!!permission.startDate && permission.startDate > new Date()) || (!!permission.endDate && permission.endDate < new Date());
  }
}
