export enum SectionFinanciere_ComptesResultats {
  TOTAL_PEX = "A. TOTAL DES PRODUITS D'EXPLOITATION",
  TOTAL_CPT = 'B. TOTAL CONSO. EN PROVENANCE DE TIERS',
  VA = 'C. VALEUR AJOUTEE (A-B)',
  EBE = "D. EXCEDENT BRUT D'EXPLOITATION",
  RESULTAT_EXPLOITATION = "E. RESULTAT D'EXPLOITATION",
  RESULTAT_CAI = 'F. RESULTAT COURANT AVANT IMPOTS',
  RESULTAT_EXERCICE = "G. RESULTAT DE L'EXERCICE",
  CAPACITE_AUTOFINANCEMENT = "CAPACITE D'AUTOFINANCEMENT",
}

export namespace SectionFinanciere_NameSpace_ComptesResultats {
  export function toEnum(sfString: string): SectionFinanciere_ComptesResultats | undefined {
    return Object.values(SectionFinanciere_ComptesResultats).find((value: SectionFinanciere_ComptesResultats) => value === sfString);
  }
}

export enum SaisieLabel_ComptesResultats {
  CA = "Chiffre d'affaires (HT) total de l'entreprise",
  PI = '+ production immobilisée',
  PST = '+ production stockée',
  AMPM = 'Achat de matières premières et marchandises',
  VSMM = '+/- variation de stock de matières et marchandises',
  AACE = '+ autres achats et charges externes',
  SE = "+ Subvention d'exploitation",
  IT = '- Impôts et taxes',
  CP = '- Charges de personnel',
  DA = '- Dotation aux amortissements',
  ACE = "- Autres charges d'exploitation",
  APE = "+ Autres produits d'exploitation",
  PF = '+ Produits financiers',
  CF = '- Charges financières',
  PE = '+ Produits exceptionnels',
  CE = '- Charges exceptionnelles',
  PSA = '- Participation des salariés',
  IB = '- Impôts sur les bénéfices',
  EF = 'Effectifs',
}

export enum IsolatedEditableRow_ComptesResultats {
  MP = 'Montant du projet',
  MPCE = "Montant du projet passé en charges d'exploitation",
}

export const TOOLTIP_BY_ROW_COMPTES_RESULTATS: Record<string, string> = {
  [SaisieLabel_ComptesResultats.CA]: `• dont chiffre d'affaires généré par les résultats du projet\r\n• dont vente à l'exportation`,
  [SaisieLabel_ComptesResultats.AACE]: `• dont sous-traitance\r\n• dont crédit bail - redevances`,
  [SectionFinanciere_ComptesResultats.CAPACITE_AUTOFINANCEMENT]: `G = amortissements +/- résultats exceptionnels`,
  [IsolatedEditableRow_ComptesResultats.MP]: `Les dépenses du projet peuvent être immobilisées et amorties (ligne "Immobilisation des dépenses du projet proposé" de l'onglet Plan de financement), ou bien passées pour tout ou partie en charges d’exploitation.`,
};

export const IS_ISOLATED_EDITABLE_ROW_COMPTES_RESULTATS: (sectionLabel: string) => boolean = (sectionLabel: string) =>
  Object.values<string>(IsolatedEditableRow_ComptesResultats).includes(sectionLabel);
