<!-- Entête de la page -->
<header class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <h2 class="page-header__title">Mes tâches à réaliser</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <div *ngIf="demandesCorrection.length > 0">
    <lib-card-title title="Modifications demandées"></lib-card-title>
    <div *ngFor="let demande of demandesCorrection; index as i">
      <lib-demande-modification-candidat
        [demande]="demande"
        (correctionSent)="correctionSent()"
        [projet]="projet"
        [canWrite]="canWriteModification"
      ></lib-demande-modification-candidat>
    </div>
  </div>

  <div *ngIf="demandesDocComplement.length > 0">
    <lib-card-title title="Demande de document(s) complémentaire(s)"></lib-card-title>
    <div *ngFor="let demande of demandesDocComplement; index as i">
      <lib-demande-document-complementaire-candidat
        [demande]="demande"
        (demandeComplementaireSent)="demandeComplementaireSent()"
        [projet]="projet"
        [canWrite]="canWriteDemandeComplement"
      ></lib-demande-document-complementaire-candidat>
    </div>
  </div>

  <!-- Message d'information AAP férmé -->
  <div
    class="card--info card--blue card--aap-ferme"
    *ngIf="this.aap?.id !== null && !canUpdateClosedAapProject() && demandesCorrection.length === 0"
  >
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    L'appel à projets {{ aap.nom }} est fermé. Vous n'avez aucune tâche à réaliser.
  </div>
  <ng-container *ngIf="canUpdateClosedAapProject() || demandesCorrection.length > 0">
    <ng-container *ngxPermissionsOnly="['PROJET_WRITE']">
      <section *ngIf="canUserSeeContract">
        <lib-card-title title="Contrat"></lib-card-title>
        <!-- Carte 'contrat' -->
        <div id="card-contrat" class="card card--task" role="button" (click)="onGoToContrat()">
          <h3 class="card__title">Signer le(s) contrat(s) partenaire(s)</h3>
          <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
        </div>
        <!-- FIN de Carte 'contrat' -->
      </section>
      <section
        class="page-section"
        *ngIf="
          projet?.statut === EnumProjetStatut.EN_COURS &&
          etapeActuelle?.nom !== EnumProjetEtape.INSTRUCTION &&
          etapeActuelle?.nom !== EnumProjetEtape.CONTRACTUALISATION
        "
      >
        <p class="page-section__title">Projet</p>
        <!-- Carte 'informations' -->
        <div
          id="card-info"
          class="card card--task"
          [ngClass]="{ 'is-valid': infoValid }"
          role="button"
          (click)="!infoValid ? onGoToProjetInfoGene() : null"
        >
          <h3 class="card__title">Renseigner les informations du projet</h3>
          <p class="card__text">Titre, acronyme et description du projet</p>

          <lib-pxl-icon *ngIf="!infoValid" class="card__arrow" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          <lib-pxl-icon *ngIf="infoValid" class="card__check" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
        </div>
        <!-- FIN de Carte 'informations' -->

        <!-- Carte enquete projet  -->
        <div
          id="card-enquete"
          class="card card--task"
          [ngClass]="{ 'is-valid': enqueteValid }"
          *ngIf="aap.enqueteProjetObligatoire && aap.enqueteProjetPresent"
          role="button"
          (click)="!enqueteValid ? onGoToProjetEnquete() : null"
        >
          <h3 class="card__title">Renseigner l'enquête du projet</h3>

          <lib-pxl-icon *ngIf="!enqueteValid" class="card__arrow" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          <lib-pxl-icon *ngIf="enqueteValid" class="card__check" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
        </div>
        <!-- FIN de carte enquete projet  -->

        <!-- Carte thematique  -->
        <div
          id="card-thematique"
          class="card card--task"
          [ngClass]="{ 'is-valid': thematiqueValid }"
          *ngIf="aap.thematiquesObligatoire && aap.thematiquesPresent"
          role="button"
          (click)="!thematiqueValid ? onGoToProjetThematique() : null"
        >
          <h3 class="card__title">Renseigner la thématique du projet</h3>

          <lib-pxl-icon *ngIf="!thematiqueValid" class="card__arrow" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          <lib-pxl-icon *ngIf="thematiqueValid" class="card__check" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
        </div>
        <!-- FIN de Carte thematique  -->

        <!-- Carte domaines technologiques  -->
        <div
          id="card-domaines"
          class="card card--task"
          [ngClass]="{ 'is-valid': domainesValid }"
          *ngIf="aap.domaineObligatoire && aap.domainePresent"
          role="button"
          (click)="!domainesValid ? onGoToProjetDomaines() : null"
        >
          <h3 class="card__title">Renseigner le ou les domaines technologiques du projet</h3>

          <lib-pxl-icon *ngIf="!domainesValid" class="card__arrow" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          <lib-pxl-icon *ngIf="domainesValid" class="card__check" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
        </div>
        <!-- FIN de Carte domaines technologiques  -->

        <!-- Carte 'secteurs d'application -->
        <div
          id="card-secteurs"
          class="card card--task"
          [ngClass]="{ 'is-valid': secteursValid }"
          *ngIf="aap.secteursObligatoire && aap.secteursPresent"
          role="button"
          (click)="!secteursValid ? onGoToProjetSecteurs() : null"
        >
          <h3 class="card__title">Renseigner le ou les secteurs d'application du projet</h3>

          <lib-pxl-icon *ngIf="!secteursValid" class="card__arrow" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          <lib-pxl-icon *ngIf="secteursValid" class="card__check" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
        </div>
        <!-- FIN de Carte 'secteurs d'application -->

        <!-- Carte 'budget' -->
        <div
          id="card-budget"
          class="card card--task"
          [ngClass]="{ 'is-valid': budgetValid }"
          role="button"
          *ngIf="aap.budgetEstime"
          (click)="!budgetValid ? onGoToProjetInfoBudget() : null"
        >
          <h3 class="card__title">Renseigner le budget estimé du projet</h3>

          <lib-pxl-icon *ngIf="!budgetValid" class="card__arrow" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          <lib-pxl-icon *ngIf="budgetValid" class="card__check" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
        </div>
        <!-- FIN de Carte 'budget' -->

        <!-- Carte 'KPI' -->
        <div
          id="card-kpi"
          class="card card--task"
          [ngClass]="{ 'is-valid': isGrilleImpactsProjetValid }"
          role="button"
          *ngIf="isKpiProjetNeeded"
          (click)="!isGrilleImpactsProjetValid ? onGoToProjetInfoKpi() : null"
        >
          <h3 class="card__title">Renseigner la grille d'impacts du projet</h3>

          <lib-pxl-icon
            *ngIf="!isGrilleImpactsProjetValid"
            class="card__arrow"
            icon="icon-arrow-arrow-forward"
            aria-hidden="true"
          ></lib-pxl-icon>
          <lib-pxl-icon
            *ngIf="isGrilleImpactsProjetValid"
            class="card__check"
            icon="icon-action-check-circle"
            aria-hidden="true"
          ></lib-pxl-icon>
        </div>
        <!-- FIN de Carte 'KPI' -->

        <!-- Carte 'Dépenses' -->
        <div
          id="card-depenses"
          class="card card--task"
          [ngClass]="{ 'is-valid': isExpensesProjetValid }"
          role="button"
          *ngIf="etapeActuelle?.nom === 'DEPOT' && aap.depensesProjetPresent"
          (click)="!isExpensesProjetValid ? onGoToProjetInfoExpenses() : null"
        >
          <h3 class="card__title">Renseigner les dépenses du projet</h3>
          <p class="card__text">En phase de {{ getEtapeName() }}, veuillez renseigner les dépenses de votre projet</p>
          <lib-pxl-icon
            *ngIf="!isExpensesProjetValid"
            class="card__arrow"
            icon="icon-arrow-arrow-forward"
            aria-hidden="true"
          ></lib-pxl-icon>
          <lib-pxl-icon *ngIf="isExpensesProjetValid" class="card__check" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
        </div>
        <!-- FIN de Carte 'Dépenses' -->

        <!-- Carte 'Fiche de Com' -->
        <div
          id="card-fiche-com"
          class="card card--task"
          [ngClass]="{ 'is-valid': isFicheComValid }"
          role="button"
          *ngIf="aap.ficheComPresent"
          (click)="!isFicheComValid ? onGoToProjetInfoFicheCom() : null"
        >
          <h3 class="card__title">Renseigner la fiche pour communication publique</h3>
          <lib-pxl-icon *ngIf="!isFicheComValid" class="card__arrow" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          <lib-pxl-icon *ngIf="isFicheComValid" class="card__check" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
        </div>
        <!-- FIN de Carte 'Fiche de Com' -->

        <!-- Carte 'document de présentation' -->
        <div
          id="card-presentation"
          class="card card--task"
          *ngIf="!(docValid && listDocumentProjet.length === 0)"
          [ngClass]="{ 'is-valid': docValid }"
          role="button"
          (click)="!docValid ? onGoToProjetInfo() : null"
        >
          <h3 class="card__title">Renseigner le ou les document(s) projet</h3>
          <p class="card__text">
            En phase de {{ getEtapeName() }}, merci de vous assurer que l'ensemble des documents demandés dans le dossier de candidature ont
            bien été fournis.
          </p>

          <lib-pxl-icon *ngIf="!docValid" class="card__arrow" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          <lib-pxl-icon *ngIf="docValid" class="card__check" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
        </div>
        <!-- FIN de Carte 'document de présentation' -->

        <!-- Carte 'Pôles' -->
        <div
          id="card-poles"
          class="card card--task"
          [ngClass]="{ 'is-valid': poleValid }"
          *ngIf="aap.poleObligatoire"
          role="button"
          (click)="!poleValid ? onGoToPolesInfo() : null"
        >
          <h3 class="card__title">Renseigner le ou les pôles du projet</h3>

          <lib-pxl-icon *ngIf="!poleValid" class="card__arrow" icon="icon-arrow-arrow-forward" aria-hidden="true"></lib-pxl-icon>
          <lib-pxl-icon *ngIf="poleValid" class="card__check" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
        </div>
        <!-- FIN de Carte 'Pôles' -->
      </section>
    </ng-container>
    <ng-container *ngxPermissionsOnly="['PROJET_READ']">
      <section
        class="page-section"
        *ngIf="
          projet?.statut === EnumProjetStatut.EN_COURS &&
          etapeActuelle?.nom !== EnumProjetEtape.INSTRUCTION &&
          etapeActuelle?.nom !== EnumProjetEtape.CONTRACTUALISATION
        "
      >
        <p *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI" class="page-section__title">Consortium</p>
        <p *ngIf="projet.partenaireType === EnumTypePartenaire.INDIV" class="page-section__title">Structure</p>
        <!-- Carte 'membres du consortium' -->
        <div
          id="card-membres"
          class="card card--task"
          [ngClass]="{
            'is-valid':
              structureValid &&
              structureDocValid &&
              isGrillesImpactsStructuresValid &&
              isFicheDmdAideValid &&
              (isStructureInformationBancaireValid || aap.immersion) &&
              isSignatairesValid &&
              previsionEconomiqueValid
          }"
          role="button"
          (click)="
            !structureValid ||
            !structureDocValid ||
            !isGrillesImpactsStructuresValid ||
            !isFicheDmdAideValid ||
            (!isStructureInformationBancaireValid && !aap.immersion) ||
            !isSignatairesValid ||
            !previsionEconomiqueValid
              ? onGoToConsortium()
              : null
          "
        >
          <ng-container *ngxPermissionsOnly="['STRUCTURE_WRITE_ALL']">
            <h3 *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI" class="card__title">
              Renseigner les informations des membres du consortium (minimum 2 structures)
            </h3>
          </ng-container>
          <ng-container *ngxPermissionsOnly="['STRUCTURE_WRITE_ALL']">
            <p *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI" class="card__text">
              En phase de {{ getEtapeNom(etapeActuelle) }}, vous devez déclarer un ensemble d’informations obligatoires pour chaque
              structure.
            </p>
          </ng-container>

          <ng-container *ngxPermissionsOnly="['STRUCTURE_WRITE']">
            <h3 *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI" class="card__title">
              Renseigner les informations de la structure
            </h3>
          </ng-container>
          <ng-container *ngxPermissionsOnly="['STRUCTURE_WRITE']">
            <p *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI" class="card__text">
              En phase de {{ getEtapeNom(etapeActuelle) }}, vous devez déclarer un ensemble d’informations obligatoires pour la structure.
            </p>
          </ng-container>

          <h3 *ngIf="projet.partenaireType === EnumTypePartenaire.INDIV" class="card__title">
            Renseigner les informations de la structure
          </h3>
          <p *ngIf="projet.partenaireType === EnumTypePartenaire.INDIV" class="card__text">
            En phase de {{ getEtapeNom(etapeActuelle) }}, vous devez déclarer un ensemble d’informations obligatoires pour la structure.
          </p>

          <lib-pxl-icon
            *ngIf="
              !structureValid ||
              !structureDocValid ||
              !isGrillesImpactsStructuresValid ||
              !isFicheDmdAideValid ||
              (!isStructureInformationBancaireValid && !aap.immersion) ||
              !isSignatairesValid ||
              !previsionEconomiqueValid
            "
            class="card__arrow"
            icon="icon-arrow-arrow-forward"
            aria-hidden="true"
          ></lib-pxl-icon>
          <lib-pxl-icon
            *ngIf="
              structureValid &&
                structureDocValid &&
                isGrillesImpactsStructuresValid &&
                isFicheDmdAideValid &&
                (isStructureInformationBancaireValid || aap.immersion) &&
                isSignatairesValid;
              previsionEconomiqueValid
            "
            class="card__check"
            icon="icon-action-check-circle"
            aria-hidden="true"
          ></lib-pxl-icon>
        </div>
        <!-- FIN de Carte 'membres du consortium' -->

        <!-- Carte 'compléter documents projet' -->
        <div
          id="card-mandataire"
          class="card card--task"
          [ngClass]="{ 'is-valid': mandataireValid && structureDocMandataireValid }"
          *ngIf="isMandataireExist"
          role="button"
          (click)="!(mandataireValid && structureDocMandataireValid) ? onGoToStructureMandataire() : null"
        >
          <h3 class="card__title">Renseigner les informations du Mandataire</h3>

          <lib-pxl-icon
            *ngIf="!(mandataireValid && structureDocMandataireValid)"
            class="card__arrow"
            icon="icon-arrow-arrow-forward"
            aria-hidden="true"
          ></lib-pxl-icon>
          <lib-pxl-icon
            *ngIf="mandataireValid && structureDocMandataireValid"
            class="card__check"
            icon="icon-action-check-circle"
            aria-hidden="true"
          ></lib-pxl-icon>
        </div>
        <!-- FIN de Carte 'compléter documents projet' -->
      </section>
    </ng-container>
    <ng-container *ngxPermissionsOnly="['PROJET_WRITE']">
      <section
        class="page-section"
        *ngIf="projet?.statut === EnumProjetStatut.EN_COURS && etapeActuelle?.nom === EnumProjetEtape.PRE_DEPOT"
      >
        <p class="page-section__title">Finaliser mon pré-dépôt</p>
        <!-- Carte 'pré-déposer' -->
        <div id="card-document" class="card card--task card--inactive">
          <h3 id="card-description" class="card__title">Finaliser mon pré-dépôt</h3>
          <p class="card__text">Avant de pouvoir pré-déposer le projet, vous devez valider toutes les étapes ci-dessus.</p>
          <button
            type="button"
            id="predeposer-btn"
            class="card__button ds-btn ds-btn--primary"
            [disabled]="!checkIfProjetCanBeSend() || !sharedFunction.isProjectUpdatable(aap, projet)"
            (click)="sendProject()"
          >
            <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
            Pré-déposer le projet
          </button>
        </div>
        <!-- FIN de Carte 'pré-déposer' -->
      </section>
    </ng-container>

    <!-- Carte statut 'PREDEPOT ENVOYE' -->
    <section class="page-section" *ngIf="projet?.statut === EnumProjetStatut.ENVOYE && etapeActuelle?.nom === EnumProjetEtape.PRE_DEPOT">
      <div class="card--info">
        <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
        Votre projet a été transmis, vous pouvez consulter les informations.
      </div>
      <div class="card card--task card--inactive">
        <h3 class="card__title">Votre pré-dépôt n’a pas encore été vérifié</h3>
        <button
          type="button"
          *ngxPermissionsOnly="['PROJET_WRITE']"
          id="modifier-btn"
          class="card__button ds-btn ds-btn--primary"
          [disabled]="!sharedFunction.isProjetCanBeModifiedAfterDeposited(aap, projet)"
          (click)="updateDepositedProject()"
        >
          Modifier mon pré-dépôt
        </button>
      </div>
      <!-- FIN de Carte statut 'ENVOYE' -->
    </section>

    <!-- Carte statut 'EN_ANALYSE' -->
    <section
      class="page-section"
      *ngIf="
        projet?.statut === EnumProjetStatut.EN_ANALYSE &&
        etapeActuelle?.nom === EnumProjetEtape.PRE_DEPOT &&
        demandesCorrection.length === 0
      "
    >
      <div class="card--info">
        <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
        Votre projet a été transmis, vous pouvez consulter les informations.
      </div>
      <!-- FIN de Carte statut 'EN_ANALYSE' -->
    </section>

    <!-- Carte statut -->
    <section *ngIf="showAucuneActionMessage()">
      <div class="card--info" [ngClass]="{ 'card--blue': isInfoTuile() }">
        <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
        {{ aucuneActionMessage() }}
      </div>
    </section>
    <!-- FIN de Carte statut -->

    <!-- Depot en cours  -->
    <ng-container *ngxPermissionsOnly="['PROJET_WRITE']">
      <section class="page-section" *ngIf="projet?.statut === EnumProjetStatut.EN_COURS && etapeActuelle?.nom === EnumProjetEtape.DEPOT">
        <p class="page-section__title">Finaliser mon dépôt</p>
        <!-- Carte 'pré-déposer' -->
        <div id="card-document-depot" class="card card--task card--inactive">
          <h3 id="card-description-depot" class="card__title">Finaliser mon dépôt</h3>
          <p class="card__text">Avant de pouvoir déposer le projet, vous devez valider toutes les étapes ci-dessus.</p>
          <button
            type="button"
            id="deposer-btn"
            class="card__button ds-btn ds-btn--primary"
            [disabled]="!checkIfProjetCanBeSend() || !sharedFunction.isProjectUpdatable(aap, projet)"
            (click)="sendProjectDepot()"
          >
            <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
            Déposer le projet
          </button>
        </div>
        <!-- FIN de Carte 'pré-déposer' -->
      </section>
    </ng-container>
    <!-- Carte statut 'ENVOYE' -->
    <section class="page-section" *ngIf="projet?.statut === EnumProjetStatut.ENVOYE && etapeActuelle?.nom === EnumProjetEtape.DEPOT">
      <div class="card--info">
        <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
        Votre projet a été transmis, vous pouvez consulter les informations.
      </div>
      <div class="card card--task card--inactive">
        <h3 class="card__title">Votre dépôt n’a pas encore été vérifié</h3>
        <button
          type="button"
          *ngxPermissionsOnly="['PROJET_WRITE']"
          id="modifier-depot-btn"
          class="card__button ds-btn ds-btn--primary"
          [disabled]="!sharedFunction.isProjetCanBeModifiedAfterDeposited(aap, projet)"
          (click)="deposeEnCoursProjet()"
        >
          Modifier mon dépôt
        </button>
      </div>
      <!-- FIN de Carte statut 'ENVOYE' -->
    </section>
  </ng-container>
</div>

<!-- FIN de Contenu de la page -->
