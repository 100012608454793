import { EnumStatutDemandeAide } from '../enums/enum.statut-demande-aide';
import { FicheDemandeAideInterface } from '../interfaces/fiche-demande-aide.interface';
import { ActionnaireDemandeAide } from './actionnaire-demande-aide.model';
import { AideDemandeAide } from './aide-demande-aide.model';
import { SignataireDemandeAide } from './signataire-demande-aide.model';

export class FicheDemandeAide implements FicheDemandeAideInterface {
  id: string;
  structureId: string;
  projetId: string;
  contactsAutorisesMatricules: Set<string>;
  dateT0: string;
  dateTfinal: string;
  signeeParRepresentantLegal: boolean;
  correspondantSignatureManuelle: SignataireDemandeAide;
  signatureElectronique: boolean;
  signataireElectroniqueIdentique: boolean;
  correspondantSignatureElectronique: SignataireDemandeAide;
  actionnaires: ActionnaireDemandeAide[];
  obtenuAidesTroisDernieresAnnees: boolean;
  autresDemandesAidesDeposees: boolean;
  aides: AideDemandeAide[];
  statut: EnumStatutDemandeAide;
  documentKslId: string;
}
