<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToProjetInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Information du projet
  </a>
  <h2 class="page-header__title">Thématique</h2>
</header>
<!-- FIN de Entête de la page -->

<div class="div_page-thematique-container">
  <!-- Contenu de la page -->
  <div class="page-thematique-content">
    <div class="div_checkboxs_container">
      <label *ngFor="let thematique of aapThematiques"
        ><input
          class="btnlike"
          type="radio"
          name="thematique"
          [(ngModel)]="checkRadio"
          (click)="onSelectRadio(thematique)"
          [value]="thematique"
        />
        <span class="unselectable">{{ thematique.libelleThematique }}</span>
      </label>
    </div>
    <div class="message-selectionner-thematique" *ngIf="this.thematiqueNonSelectione">
      Merci de bien vouloir sélectionner une thématique.
    </div>
    <div class="div_button">
      <button id="creation_projet_btn_annuler" type="button" class="ds-btn ds-btn--secondary" (click)="onClickBack()">Annuler</button>
      <button
        id="creation_projet_btn_ajouter"
        type="submit"
        class="ds-btn ds-btn--primary"
        (click)="onValidateChoice()"
        *ngxPermissionsOnly="['PROJET_WRITE']"
        [disabled]="!sharedFunction.isProjectUpdatable(aap, projet)"
      >
        <span>Valider ma sélection</span>
      </button>
    </div>
  </div>
</div>
