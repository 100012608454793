import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface ModalDataTextSimpleInput {
  title: string;
  description: string;
  produit: string[];
  fieldInput: string;
  textGoButton: string;
  textReturnButton: string;
  placeholder?: string;
}

@Component({
  selector: 'lib-text-simple-input-modal',
  templateUrl: './text-simple-input-modal.component.html',
  styleUrls: ['./text-simple-input-modal.component.scss'],
})
export class TextSimpleInputModalComponent implements OnInit {
  inputForm!: UntypedFormGroup;
  existeProduit = false;

  get formInputField(): UntypedFormControl {
    return this.inputForm.get('inputField') as UntypedFormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<TextSimpleInputModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalDataTextSimpleInput,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.inputForm = this.formBuilder.group({
      inputField: [this.modalData.fieldInput ? this.modalData.fieldInput : null, Validators.required],
    });
  }

  saveModal(): void {
    if (this.inputForm.valid) {
      const finalValue = this.formInputField.value.trim();
      if (this.modalData.produit.length > 0 && this.modalData.produit.includes(finalValue)) {
        this.existeProduit = true;
      } else {
        this.existeProduit = false;
        this.dialogRef.close(finalValue);
      }
    } else {
      this.formInputField.markAsTouched();
    }
  }

  cancelModal(): void {
    if (this.inputForm.dirty) {
      const cn = confirm('Les modifications apportées ne seront peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page ?');
      if (cn) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }
}
