<div *ngIf="cellParam?.def !== 'action'">
  <mat-menu #menu="matMenu" class="user-menu">
    <div class="filter-wrapper">
      <mat-checkbox (click)="$event.stopPropagation()" *ngFor="let data of dataList" (change)="onChange($event)" [value]="data">
        {{ data }}
      </mat-checkbox>
    </div>
  </mat-menu>

  <div class="table-header-cell">
    <div class="d-flex align-items-center">
      <div [matTooltip]="infoText" matTooltipClass="tooltip-multiline" class="d-inline">
        <span [ngClass]="{ nowrap: !cellParam?.wrap }"> {{ cellParam?.label }} <lib-asterisk *ngIf="cellParam?.mandatory" /> </span>
      </div>
    </div>
    <button *ngIf="cellParam?.filterEnable" mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="cellParam?.def === 'action'" class="d-flex justify-content-center">
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="$event.stopPropagation();onSelectAll()">
      <div class="d-flex justify-content-between">
        <mat-checkbox style="margin-right: 1rem" [checked]="allChecked"></mat-checkbox>
        <span>{{ ALL }}</span>
      </div>
    </button>
    <button mat-menu-item (click)="onActionClicked(DUPLIQUER)">
      <div class="d-flex justify-content-between">
        <mat-icon>content_copy</mat-icon>
        <span>{{ DUPLIQUER }}</span>
      </div>
    </button>
    <button mat-menu-item (click)="onActionClicked(SUPPRIMER)">
      <div class="d-flex justify-content-between">
        <mat-icon>delete</mat-icon>
        <span>{{ SUPPRIMER }}</span>
      </div>
    </button>
    <button mat-menu-item (click)="onActionClicked(MODIFIER)">
      <div class="d-flex justify-content-between">
        <mat-icon>edit</mat-icon>
        <span>{{ MODIFIER }}</span>
      </div>
    </button>
  </mat-menu>
</div>
