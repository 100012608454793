import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[libOverflowTooltip]',
})
export class OverflowTooltipDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, private matTooltip: MatTooltip) {}

  ngAfterViewInit(): void {
    this.updateTooltipState();
  }

  private updateTooltipState(): void {
    const element = this.elementRef.nativeElement;

    const isOverflowing = element.scrollWidth > element.clientWidth;

    if (isOverflowing) {
      this.matTooltip.disabled = false;
      this.matTooltip.message = element.textContent.trim();
    } else {
      this.matTooltip.disabled = true;
    }
  }
}
