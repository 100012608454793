<div>
  <span class="switch" [style.width.px]="labels.length * 141">
    <span class="slider round" [class]="'selected-toggle-' + selectedIndex" [ngClass]="{ 'toggle-disabled': lockToggle }">
      <!-- Replace the hardcoded option labels with dynamic ones -->
      <ng-container *ngFor="let label of labels; let i = index">
        <span class="toggle-option" [class.active]="selectedIndex === i" [style.left.px]="i * 140 + 4" (click)="toggleOptionClick(i)">
          {{ label }}
        </span>
      </ng-container>
    </span>
  </span>
</div>
