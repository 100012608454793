import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { EspaceSelectionComponent } from './espace-selection.component';
import { AppConfig } from 'apps/pxl_front_candidat_nx/src/app.config';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [EspaceSelectionComponent],
  imports: [CommonModule, MaterialModule, SharedModule, SharedUiModule],
  providers: [{ provide: 'appConfig', useValue: AppConfig }],
})
export class EspaceModule {}
