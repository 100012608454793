import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { UserService } from '@services-candidat/user.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  EnumEnqueteConnaissance,
  EnumEnqueteConnaissanceSite,
  EnumEnqueteConnaissanceTech,
  EnumEnqueteEnergiesRenouvelables,
  EnumEnqueteFinancement,
  EnumEnqueteGestionPollution,
  EnumEnqueteIncubateurPublic,
  EnumEnqueteRecherchePublique,
  EnumEnqueteRessourcesNaturelles,
  EnumEnqueteSATT,
  Projet,
  ShowToastrService,
  Utilisateur,
} from '@shared-ui';
import { Observable, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { developpementDurableValidator } from './projet-info-enquete-dev-durable.validator';
import { ProjetInfoEnqueteHelperService } from './projet-info-enquete.helper.service';

@Component({
  selector: 'pxl-projet-info-enquete',
  templateUrl: './projet-info-enquete.component.html',
  styleUrls: ['./projet-info-enquete.component.scss'],
})
export class ProjetInfoEnqueteComponent implements OnInit, OnDestroy {
  readonly TEXTAREA_MAX_LENGTH = 250;
  readonly INPUT_TEXT_MAX_LENGTH = 65;

  enqueteForm: UntypedFormGroup;

  enumConnaissance = EnumEnqueteConnaissance;
  enumConnaissanceSite = EnumEnqueteConnaissanceSite;
  enumRecherchePublique = EnumEnqueteRecherchePublique;
  enumIncubateurPublic = EnumEnqueteIncubateurPublic;
  enumSATT = EnumEnqueteSATT;
  enumFinancement = EnumEnqueteFinancement;
  enumEnergiesRenouvelables = EnumEnqueteEnergiesRenouvelables;
  enumGestionPollution = EnumEnqueteGestionPollution;
  enumRessourcesNaturelles = EnumEnqueteRessourcesNaturelles;
  enumEnqueteConnaissanceTech = EnumEnqueteConnaissanceTech;

  isProjectUpdatableByUser = false;
  projetId: string;
  subscriptions: Subscription[] = [];
  projet: Projet;
  aap: Aap;
  utilisateur: Utilisateur;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    public helperService: ProjetInfoEnqueteHelperService,
    private projetService: ProjetService,
    private userService: UserService,
    private aapService: AapService,
    private showToastrService: ShowToastrService,
    public sharedFunction: SharedFunction,
    private cd: ChangeDetectorRef
  ) {}

  // Order by ascending property value
  valueOrder(): number {
    return 0;
  }

  get energiesRenouvelablesControl(): AbstractControl {
    return this.enqueteForm.controls['developpementDurable'].get('energiesRenouvelables');
  }

  get gestionPollutionControl(): AbstractControl {
    return this.enqueteForm.controls['developpementDurable'].get('gestionPollution');
  }

  get optimisationConsommationsControl(): AbstractControl {
    return this.enqueteForm.controls['developpementDurable'].get('optimisationConsommations');
  }

  ngOnInit(): void {
    this.getProjet();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.enqueteForm.dirty;
  }

  getProjet(): void {
    this.projetId = this.route.snapshot.parent.params.id;
    this.subscriptions.push(
      this.projetService
        .getProjetObservable()
        .pipe(
          concatMap(responseProjet => {
            if (responseProjet) {
              this.projet = responseProjet;
              this.createForms();
              this.cd.detectChanges();

              this.subscriptions.push(
                this.userService.getUserObservable().subscribe(response => {
                  if (response) {
                    this.utilisateur = response;
                  }
                })
              );

              return this.aapService.loadAapSubject();
            }
          })
        )
        .subscribe({
          next: responseAap => {
            if (responseAap) {
              this.aap = responseAap.body;
              this.checkUserFormPermission();
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        })
    );
  }

  createForms(): void {
    const enquete = this.projet.enquete;
    this.enqueteForm = this.formBuilder.group({
      connaissance: this.formBuilder.group({
        value: [enquete?.sourceInfo, Validators.required],
        site: [
          {
            value: enquete?.sourceInfositeWeb,
            disabled: enquete?.sourceInfo !== EnumEnqueteConnaissanceTech[EnumEnqueteConnaissanceTech.SITE_INTERNET],
          },
          Validators.required,
        ],
      }),
      recherchePublique: this.formBuilder.group({
        radioToggle: [enquete?.hasExploitRecherchePublic, Validators.required],
        value: [{ value: enquete?.exploitRecherchePublicOrganisme, disabled: !enquete?.hasExploitRecherchePublic }, Validators.required],
        description: [
          { value: enquete?.exploitRecherchePublicOrganismeCoordonnees, disabled: !enquete?.hasExploitRecherchePublic },
          Validators.required,
        ],
      }),
      etablissementPublic: this.formBuilder.group({
        value: [enquete?.hasBrevetDepotPublic, Validators.required],
      }),
      autreRecherchePublique: this.formBuilder.group({
        radioToggle: [enquete?.lieRecherchePublic, Validators.required],
        value: [{ value: enquete?.lieRecherchePublicDetail, disabled: !enquete?.lieRecherchePublic }, Validators.required],
      }),
      incubateurPublic: this.formBuilder.group({
        radioToggle: [enquete?.incubeIncubateurPublic, Validators.required],
        value: [{ value: enquete?.incubateurPublicDetail, disabled: !enquete?.incubeIncubateurPublic }, Validators.required],
      }),
      incubateurPrive: this.formBuilder.group({
        radioToggle: [enquete?.incubeIncubateurPrive, Validators.required],
        value: [{ value: enquete?.incubateurPriveDetail, disabled: !enquete?.incubeIncubateurPrive }, Validators.required],
      }),
      satt: this.formBuilder.group({
        radioToggle: [enquete?.matureSATT, Validators.required],
        value: [{ value: enquete?.sattdetail, disabled: !enquete?.matureSATT }, Validators.required],
      }),
      financement: this.formBuilder.group({
        radioToggle: [enquete?.objetFinancement, Validators.required],
        value: [{ value: enquete?.objetFinancementType, disabled: !enquete?.objetFinancement }, Validators.required],
      }),
      developpementDurable: this.formBuilder.group(
        {
          radioToggle: [enquete?.developpementDurable, Validators.required],
          energiesRenouvelables: [
            { value: enquete?.developpementDurableEnergiesRenouv ?? [], disabled: !enquete?.developpementDurable },
            null,
          ],
          gestionPollution: [
            { value: enquete?.developpementDurableGestionPollution ?? [], disabled: !enquete?.developpementDurable },
            null,
          ],
          optimisationConsommations: [
            { value: enquete?.developpementDurableOptimConsommation ?? [], disabled: !enquete?.developpementDurable },
            null,
          ],
        },
        { validator: developpementDurableValidator() }
      ),
    });

    this.enqueteForm.controls['connaissance'].get('value').valueChanges.subscribe(() => {
      this.helperService.toggleSite(this.enqueteForm.controls['connaissance']);
    });
  }

  checkUserFormPermission(): void {
    if (this.sharedFunction.isProjectUpdatableByUser(this.aap, this.projet, this.utilisateur)) {
      this.isProjectUpdatableByUser = true;
    } else {
      this.enqueteForm.disable();
    }
  }

  /**
   * Evenement de checkbox - ajoute ou retire le champ de la liste (value) du control
   * @param event checkbox event
   * @param control checkbox form control
   */
  onCheckboxChange(event: Event, control: AbstractControl): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      control.value?.push(checkbox.value);
    } else {
      const index = control.value?.findIndex((x: string) => x === checkbox.value);
      control.value?.splice(index, 1);
    }
    this.enqueteForm.controls['developpementDurable'].updateValueAndValidity();
  }

  /**
   * Enregistre l'enquête projet
   */
  saveEnqueteProjet(): void {
    try {
      this.subscriptions.push(
        this.helperService.saveEnqueteProjet(this.projetId, this.enqueteForm).subscribe({
          next: response => {
            if (response) {
              this.enqueteForm.markAsPristine();
              this.onGoToProjetInfo();
              this.showToastrService.success('Projet modifié');
              this.projetService.setProjetObservable(response.body);
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        })
      );
    } catch (err) {
      this.showToastrService.error(err.message);
    }
  }

  /* Envoi sur la page des informations du projet
   * */
  onGoToProjetInfo(): void {
    this.router.navigate(['../projet-info'], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }
}
