import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EnumMotifNonRattachement, InformationSiege } from '@shared-ui';

@Component({
  selector: 'step-identification-structure',
  templateUrl: './step-identification-structure.component.html',
  styleUrls: ['./step-identification-structure.component.scss'],
})
export class StepIdentificationStructureComponent {
  @Input() structureForm: UntypedFormGroup;
  @Input() isAapPersonnePhysique: boolean;
  @Output() changePage = new EventEmitter<any>();
  @Output() structureEnCreationChange = new EventEmitter<boolean>();
  @Output() structureIdentifiedByRET = new EventEmitter<boolean>();

  nextButtonDisabled = true;
  nextButtonAsSaisieManuelle = false;

  structureEnCreation = false;
  structureSelected = false;
  motifNonRattachmentSelected = false;
  structuresNotFound = false;

  onMotifNonRattachementSelected(motif: EnumMotifNonRattachement) {
    if (motif) {
      this.structureForm.get('raisonSiret')?.enable();
      this.structureForm.get('raisonSiret')?.setValue(motif);
    } else {
      this.structureForm.get('raisonSiret')?.reset();
      this.structureForm.get('raisonSiret')?.disable();
    }
    this.structureEnCreationChange.emit(motif === EnumMotifNonRattachement.EN_COURS_DE_CREATION);
    this.structureEnCreation = motif === EnumMotifNonRattachement.EN_COURS_DE_CREATION;
    this.motifNonRattachmentSelected = !!motif;
    this.updateState();
  }

  onStructureSelected(siege: InformationSiege) {
    if (siege) {
      // TODO: fix incoherence (address vs adresss, raisonSociale vs raisonSocial)
      this.structureForm.patchValue({
        ...siege,
        adresse: siege.address,
        raisonSocial: siege.raisonSociale,
        organisationCreationDate: siege.creationDate,
      });
    } else {
      this.resetForm();
    }
    this.structureSelected = !!siege;
    this.updateState();
  }

  onStructuresFound(sieges: InformationSiege[]) {
    this.structuresNotFound = sieges && sieges.length === 0;
    this.updateState();
  }

  updateState() {
    this.nextButtonDisabled = !(this.structureSelected || this.motifNonRattachmentSelected || this.structuresNotFound);
    this.nextButtonAsSaisieManuelle = this.structuresNotFound;
    if (this.structureEnCreation) {
      this.structureForm.get('raisonSocial')?.enable();
      this.structureForm.get('raisonSocial')?.clearValidators();
      this.structureForm.get('raisonSocial')?.updateValueAndValidity();
    }
  }

  onMaStructureNotListed() {
    this.resetForm();
    this.structureIdentifiedByRET.emit(false);
    this.changePage.emit(true);
  }

  onNextButtonClick() {
    this.structureIdentifiedByRET.emit(this.structureSelected);
    this.changePage.emit(true);
  }

  onPreviousButtonClick() {
    this.changePage.emit(false);
  }

  resetForm() {
    this.structureForm.reset({
      raisonSiret: this.structureForm.get('raisonSiret')?.value,
      adresse: {
        demandeRectification: this.structureForm.get('adresse.demandeRectification')?.value,
      },
    });
  }
}
