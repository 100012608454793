import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmModalComponent,
  DisplayColumn,
  PeriodeFinanciere,
  PrevisionsEconomiques,
  PrevisionsEconomiquesSharedService,
  SaisieFinanciere,
  SectionFinanciere,
  Structure,
  SubscriptionDestroyerComponent,
  TableCelldFpE,
  TextSimpleInputModalComponent,
} from '@shared-ui';
import { PrevisionEconomiqueHelperService } from '../prevision-economique.helper.service';
import { cloneDeep } from 'lodash';
interface Line {
  label: string;
  data: { [key: string]: TableCelldFpE };
  produit: string;
  style?: string;
  isHeader?: boolean;
}

const emptyAnnees = [
  { def: '1ère anne', label: '1ère année', width: '7rem', editable: true },
  { def: '2e année', label: '2e année', width: '7rem', editable: true },
  { def: '3e année', label: '3e année', width: '7rem', editable: true },
  { def: '4e année', label: '4e année', width: '7rem', editable: true },
  { def: '5e année', label: '5e année', width: '7rem', editable: true },
];

const lastFixArray = 3;

@Component({
  selector: 'pxl-projet-consortium-pe-produit',
  templateUrl: './projet-consortium-pe-produit.component.html',
})
export class projetConsortiumPeProduitComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() formName: string;
  @Input() structure: Structure;
  @Input() isStructureUpdatableByUser: boolean;
  @Input() btnProduit = true;

  columnName: string;
  columns: DisplayColumn[];
  annees: DisplayColumn[] = [];

  displayedColumns: DisplayColumn[] = [{ def: 'label', label: '', editable: false, width: '25rem', sticky: true }];

  dataSource: Line[] = [];
  loaded = false;

  previsionEconomique: PrevisionsEconomiques;
  periodeFinanciereForm: FormGroup;

  title = "Chiffre d'affaire généré par les résultats du projet";

  constructor(public matDialog: MatDialog, private previsionEconomiqueService: PrevisionsEconomiquesSharedService) {
    super();
  }

  ngOnInit(): void {
    this.previsionEconomiqueService.getPrevisionsEconomiques().pipe(this.takeUntilDestroyed()).subscribe(this.updateModele.bind(this));
    this.loaded =
      (String(this.structure?.previsionsEconomiques?.statut) === 'NON_VALIDE' && this.dataSource.length > 0) ||
      this.dataSource.length === 0 ||
      !this.structure?.previsionsEconomiques?.statut;
  }

  updateModele(previsionEconomique: PrevisionsEconomiques): void {
    const previsionEconomiqueFG = PrevisionEconomiqueHelperService.getStructureForm(this.structure);
    this.form?.setControl(this.formName, previsionEconomiqueFG);
    this.previsionEconomique = previsionEconomique;
    this.periodeFinanciereForm = this.form?.get(this.formName) as FormGroup;
    this.initColumns();
    this.updateDataSource();
  }

  private initColumns(): void {
    this.columns = [...this.displayedColumns];
    this.annees = this.periodeFinanciereForm?.value?.periodes.map((periodeFinanciere: PeriodeFinanciere) => {
      return {
        def: periodeFinanciere.periode,
        label: periodeFinanciere.periode,
        width: '7rem',
        editable: true,
      };
    });

    this.annees = this.annees?.filter((col, index, self) => index === self.findIndex(t => t.def === col.def));

    if (this.annees) {
      this.columns.push(...this.annees);
    }
    this.displayedColumns = this.columns;
  }

  private updateDataSource() {
    const data: Line[] = [];
    const periodes = (this.periodeFinanciereForm?.get('periodes') as FormArray)?.value ?? [];
    const periodesResultats = (this.periodeFinanciereForm?.get('resultats') as FormArray)?.value ?? [];
    periodes.forEach((periode: PeriodeFinanciere) => {
      const annee: string = periode.periode;
      const produits = periode?.sections ?? [];
      produits.forEach((produit: SectionFinanciere) => {
        const nomProduit = produit.label;
        const saisies = produit.saisies ?? [];
        this.initProduitSection(nomProduit, data);
        this.updateSaisiesCells(saisies, annee, nomProduit, data);
      });
    });

    periodesResultats.forEach((periodeResultat: SectionFinanciere) => {
      const annee = periodeResultat.label;
      const saisies = periodeResultat.saisies ?? [];
      this.initProduitSection('', data);
      this.updateSaisiesCells(saisies, annee, '', data, true);
    });

    this.dataSource = data;
  }

  initProduitSection(nomProduit: string, data: Line[]) {
    const existingProduit = data.find(line => line.label === nomProduit);
    if (!existingProduit) {
      data.push({
        label: nomProduit,
        produit: nomProduit,
        data: {},
        style: 'font-weight: 700;',
        isHeader: true,
      });
    }
  }

  updateSaisiesCells(saisies: SaisieFinanciere[], annee: string, nomProduit: string, data: Line[], style?: boolean): Line[] {
    saisies.forEach((saisie: SaisieFinanciere) => {
      const existingLine = data.find(line => line.produit === nomProduit && line.label === saisie.label);
      if (existingLine) {
        existingLine.data[annee] = new TableCelldFpE(saisie.saisie);
      } else {
        data.push({
          label: saisie.label,
          produit: nomProduit,
          data: {
            [annee]: new TableCelldFpE(saisie.saisie),
          },
          style: style ? 'font-weight: 700;' : '',
        });
      }
    });
    return data;
  }

  onKeyPress($event: KeyboardEvent): boolean {
    if ($event.key) {
      if (/[^\d]/.test($event.key)) {
        return false;
      }
    }
    return true;
  }

  addProduit(): void {
    let produit: string[] = [];

    if (this.dataSource.length > 0) {
      this.dataSource.forEach(data => {
        produit.push(data.produit);
      });
      produit = produit?.filter((col, index, self) => index === self.findIndex(t => t));
    }
    const dialogRef = this.matDialog.open(TextSimpleInputModalComponent, {
      data: {
        fieldInput: '',
        title: 'Ajouter un produit',
        produit: produit,
        description: 'Nom du produit',
        placeholder: 'Vous pouvez nommer votre produit',
        textGoButton: 'Enregistrer',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.initDatasource(result);
      }
    });
  }

  initDatasource(result: string): void {
    let data = {};
    this.annees.map(annee => {
      data = { ...data, [annee.def]: new TableCelldFpE('') };
    });

    if (this.annees.length === 0) {
      this.annees = emptyAnnees;
      this.columns = [...this.displayedColumns, ...this.annees];
      this.displayedColumns = this.columns;
    }

    const newProduit = [
      {
        label: result,
        produit: result,
        data: {},
        style: 'font-weight: 700;',
        isHeader: true,
      },
      {
        label: "Chiffre d'affaires",
        produit: result,
        data: data,
      },
      {
        label: "Nombres d'unités vendues",
        produit: result,
        data: data,
      },
      {
        label: "Chiffre d'affaire service associés",
        produit: result,
        data: data,
      },
    ];

    const endLine = [
      {
        label: '',
        produit: '',
        data: {},
      },
      {
        label: 'Marge nette prévisionnelle générée par les résultats du projet',
        produit: '',
        data: data,
        style: 'font-weight: 700;',
      },
      {
        label: "Chiffre d'affaires total de l'entreprise",
        produit: '',
        data: data,
        style: 'font-weight: 700;',
      },
    ];

    if (this.dataSource.length === 0) {
      this.dataSource = [...newProduit, ...endLine];
      this.initForm(result, true);
    } else {
      this.initForm(result, false);
      this.dataSource.splice(this.dataSource.length - lastFixArray, 0, ...newProduit);
    }
    this.dataSource = [...this.dataSource];
  }

  initForm(label: string, endLine: boolean): void {
    const annee = ['1ère année', '2e année', '3e année', '4e année', '5e année'];
    const periodes: PeriodeFinanciere[] = [];
    const resultats: SectionFinanciere[] = [];

    const saisies = [
      {
        label: "Chiffre d'affaires",
      },
      {
        label: "Nombres d'unités vendues",
      },
      {
        label: "Chiffre d'affaire service associés",
      },
    ];

    const firstSection = {
      label: label,
      saisies: saisies,
    };

    annee.forEach(annee => {
      periodes.push({
        periode: annee,
        sections: [cloneDeep(firstSection)],
      });
      resultats.push({
        label: annee,
        saisies: [
          {
            label: 'Marge nette prévisionnelle générée par les résultats du projet',
          },
          {
            label: "Chiffre d'affaires total de l'entreprise",
          },
        ],
      });
    });

    this.periodeFinanciereForm.get('periodes').value.push(periodes);
    if (endLine) {
      this.periodeFinanciereForm.get('resultats').value.push(resultats);
    }
  }

  updateData($event: { element: { produit: string; label: string }; col: string; value: number }): void {
    this.dataSource.forEach(m => {
      if (m.produit === $event.element.produit && m.label === $event.element.label) {
        m.data = { ...m.data, [$event.col]: new TableCelldFpE($event.value) };
      }
    });

    const updateSaisie = (saisies: SaisieFinanciere[]) => {
      saisies.forEach((saisie: SaisieFinanciere) => {
        if (saisie.label === $event.element.label) {
          saisie.saisie = $event.value;
        }
      });
    };

    this.periodeFinanciereForm
      .get('periodes')
      .value.flat()
      .forEach((data: PeriodeFinanciere) => {
        if (data.periode === $event.col) {
          data.sections.forEach((section: SectionFinanciere) => {
            if (section.label === $event.element.produit) {
              updateSaisie(section.saisies);
            }
          });
        }
      });

    this.periodeFinanciereForm
      .get('resultats')
      .value.flat()
      .forEach((data: SectionFinanciere) => {
        if (data.label === $event.col) {
          updateSaisie(data.saisies);
        }
      });
  }

  deleteProduit(produitName: string): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Supprimer un produit',
        description: `<p>En confirmant l'action, le produit ${produitName} sera définitivement supprimé.
Confirmez-vous l’action ?</p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource = this.dataSource.filter(data => data.produit !== produitName);

        this.periodeFinanciereForm
          .get('periodes')
          .value.flat()
          .forEach((data: PeriodeFinanciere) => {
            data.sections = data.sections.filter(section => section.label !== produitName);
          });
      }
    });
  }

  updateProduit(produitName: string): void {
    const produit = this.dataSource.map(data => data.produit).filter((col, index, self) => index === self.findIndex(t => t));

    const dialogRef = this.matDialog.open(TextSimpleInputModalComponent, {
      data: {
        fieldInput: produitName,
        title: 'Modifier un produit',
        produit: produit,
        description: '',
        placeholder: 'Vous pouvez nommer votre produit',
        textGoButton: 'Enregistrer',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(newProduitName => {
      if (newProduitName != null) {
        this.updateDataSourceProduit(produitName, newProduitName);
        this.updateFormProduit(produitName, newProduitName);
      }
    });
  }

  private updateDataSourceProduit(oldProduit: string, newProduit: string): void {
    this.dataSource.forEach(data => {
      if (data.produit === oldProduit) {
        data.produit = newProduit;
        if (Object.keys(data.data).length === 0) {
          data.label = newProduit;
        }
      }
    });
  }

  private updateFormProduit(oldProduit: string, newProduit: string): void {
    this.periodeFinanciereForm
      .get('periodes')
      .value.flat()
      .forEach((data: PeriodeFinanciere) => {
        data.sections.forEach((section: SectionFinanciere) => {
          if (section.label === oldProduit) {
            section.label = newProduit;
          }
        });
      });
  }
}
