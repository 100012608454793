import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EnumValidation, PeriodeFinanciere, PrevisionsEconomiques, SaisieFinanciere, SectionFinanciere, Structure } from '@shared-ui';

export class PrevisionEconomiqueHelperService {
  static getStructureForm(structure: Structure): FormGroup {
    const annee = ['1ère année', '2e année', '3e année', '4e année', '5e année'];
    const effectifsArray: PeriodeFinanciere[] = [];

    const saisies = [
      {
        label: 'Recherche & développement',
      },
      {
        label: 'Industriels',
      },
      {
        label: 'Autres...',
      },
    ];

    const firstSection = {
      label: "Chiffre d'affaire généré par les résultats du projet",
      saisies: saisies,
    };

    const secondSection = {
      label: "Effectif global de l'entreprise",
      saisies: saisies,
    };

    annee.forEach(annee => {
      effectifsArray.push(
        {
          periode: annee,
          sections: [firstSection],
        },
        {
          periode: annee,
          sections: [secondSection],
        }
      );
    });

    const periodes = structure?.previsionsEconomiques?.produits.periodes ?? [];
    const resultats = structure?.previsionsEconomiques?.produits.resultats ?? [];
    const effectifs = structure?.previsionsEconomiques?.effectifs ?? effectifsArray;

    const periodesForm = new FormArray(periodes.map(pe => this.getPrevisionsEconomiqueForm(pe)));

    const resultatsForm = new FormArray(resultats.map(resultat => this.getSectionsForm(resultat)));

    const effectifsForm = new FormArray(effectifs.map(effectif => this.getPrevisionsEconomiqueForm(effectif)));

    return new FormGroup({
      statut: new FormControl(structure?.previsionsEconomiques?.statut),
      periodes: periodesForm,
      resultats: resultatsForm,
      effectifs: effectifsForm,
    });
  }

  static getPrevisionsEconomiqueForm(periodeFinanciere: PeriodeFinanciere): FormGroup {
    const sectionFinanciere = periodeFinanciere?.sections ?? [];
    const sectionFinanciereForm = new FormArray(sectionFinanciere.map(section => this.getSectionsForm(section)));
    return new FormGroup({
      periode: new FormControl(periodeFinanciere?.periode),
      sections: sectionFinanciereForm,
    });
  }

  static getSectionsForm(sections: SectionFinanciere): FormGroup {
    const sectionFinanciere = sections?.saisies ?? [];
    const saisieFinanciereForm = new FormArray(sectionFinanciere.map(saisie => this.getSaisieForm(saisie)));
    return new FormGroup({
      label: new FormControl(sections?.label),
      saisies: saisieFinanciereForm,
    });
  }

  static getSaisieForm(saisies: SaisieFinanciere): FormGroup {
    return new FormGroup({
      label: new FormControl(saisies?.label),
      saisie: new FormControl(saisies?.saisie),
    });
  }

  static getPrevisionEconomiqueDTO(
    data: { periodes: PeriodeFinanciere[]; resultats: SectionFinanciere[]; effectifs: PeriodeFinanciere[] },
    statut: EnumValidation
  ): PrevisionsEconomiques {
    return {
      statut: statut,
      produits: {
        periodes: data.periodes.flat(),
        resultats: data.resultats.flat(),
      },
      effectifs: data.effectifs,
    };
  }
}
