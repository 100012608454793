import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DocumentProjet,
  EnumScope,
  EnumTypeDocument,
  FILE_SIZE,
  InformationBancaire,
  InformationsBancairesHttpService,
  Projet,
  ShowToastrService,
  Structure,
  UploadDocumentHttpService,
  Utilisateur,
} from '@shared-ui';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProjetService } from '@services-candidat/projet.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UploadDocumentService } from '@services-candidat/upload-document.service';
import { EMPTY, forkJoin, Observable, of, tap } from 'rxjs';

export interface ModalData {
  projet: Projet;
  structure: Structure;
  utilisateur: Utilisateur;
  informationBancaire?: InformationBancaire;
}

interface InformationBancaireForm {
  libelle: FormControl<string | null | undefined>;
  iban: FormControl<string | null | undefined>;
  bic: FormControl<string | null | undefined>;
  nomBanque: FormControl<string | null | undefined>;
  nomAgence: FormControl<string | null | undefined>;
  adresseAgence: FormControl<string | null | undefined>;
  commentaire: FormControl<string | null | undefined>;
}

@Component({
  selector: 'pxl-information-bancaire-modal',
  templateUrl: './information-bancaire-modal.component.html',
  styleUrls: ['./information-bancaire-modal.component.scss'],
})
export class InformationBancaireModalComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  readonly FILE_TYPES = [
    'application/msword', // doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
    'application/vnd.ms-powerpoint', // ppt
    'application/pdf', // pdf
    'application/vnd.ms-excel', // xls
    'image/jpeg', // jpg
    'text/csv', // csv
  ];
  readonly FILE_SIZE_LIMIT = FILE_SIZE;
  readonly SUBDIRECTORY = 'rib';
  form: FormGroup<InformationBancaireForm>;

  utilisateur!: Utilisateur;
  projet!: Projet;
  structure!: Structure;
  documents: DocumentProjet[] = [];
  deletedDocuments: DocumentProjet[] = [];

  edited?: InformationBancaire;

  showInvalidIban = false;
  showDocumentRequired = false;

  constructor(
    private dialogRef: MatDialogRef<InformationBancaireModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    private projetService: ProjetService,
    private uploadDocumentService: UploadDocumentService,
    private uploadDocumentS3Service: UploadDocumentHttpService,
    private informationBancaireService: InformationsBancairesHttpService,
    private formBuilder: UntypedFormBuilder,
    private showToastrService: ShowToastrService
  ) {
    this.form = this.formBuilder.group({
      libelle: ['', Validators.required],
      iban: ['', Validators.required],
      bic: ['', Validators.required],
      nomBanque: ['', Validators.required],
      nomAgence: ['', Validators.required],
      adresseAgence: ['', Validators.required],
      commentaire: [''],
    });
  }

  ngOnInit(): void {
    this.utilisateur = this.modalData.utilisateur;
    this.projet = this.modalData.projet;
    this.structure = this.modalData.structure;
    this.edited = this.modalData.informationBancaire;

    this.initForm();
    this.loadDocuments();
  }

  initForm(): void {
    if (this.edited) {
      this.form.patchValue(this.edited);
    }
  }

  loadDocuments(): void {
    if (this.edited) {
      this.projetService
        .getDocumentsStructure(this.projet.id, this.structure.id, [EnumTypeDocument.RIB])
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          map(documents => documents.body || [])
        )
        .subscribe(documents => {
          this.documents = documents.filter(doc => doc.informationBancaireId === this.edited!.id);
        });
    } else {
      this.documents = [];
    }
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.documents.length === 0) {
      this.showDocumentRequired = true;
      return;
    }
    const informationBancaire = {
      ...this.form.value,
      projetId: this.projet.id,
      structureId: this.structure.id,
    } as InformationBancaire;
    if (this.edited) {
      informationBancaire.id = this.edited.id;
    }
    this.informationBancaireService
      .createOrUpdateInformationBancaire(informationBancaire)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(err => this.handleError(err)),
        map(response => response.body!)
      )
      .subscribe(informationBancaire => {
        this.saveDocuments(informationBancaire).subscribe(() => {
          this.showToastrService.success('Informations bancaires enregistrées avec succès');
          this.form.reset();
          this.dialogRef.close(informationBancaire);
        });
      });
  }

  cancel(): void {
    if (this.form.touched) {
      const confirmation = confirm('La saisie ne sera peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page ?');
      if (confirmation) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }

  isFileSizeValid(file: File): boolean {
    return !(file.size && file.size > FILE_SIZE * 1048576);
  }

  isFileTypeValid(file: File): boolean {
    return this.FILE_TYPES.includes(file.type);
  }

  addDocument(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files) {
      throw new Error('No file selected');
    }
    const fileToUpload: File = input.files.item(0)!;
    const isFileSizeValid = this.isFileSizeValid(fileToUpload);
    const isFileTypeValid = this.isFileTypeValid(fileToUpload);
    if (!isFileSizeValid) {
      this.showToastrService.error(
        `Le fichier importé est trop volumineux, la taille maximale autorisée est de ${this.FILE_SIZE_LIMIT} Mo`
      );
    } else if (!isFileTypeValid) {
      this.showToastrService.error("Le type du document sélectionné n'est pas supporté, merci de sélectionner un autre fichier.");
    } else {
      this.showDocumentRequired = false;
      const document: DocumentProjet = new DocumentProjet();
      document.typeDoc = EnumTypeDocument.RIB;
      document.scope = EnumScope.STRUCTURE;
      document.nom = fileToUpload.name;
      document.projetId = this.projet.id;
      document.structureId = this.structure.id;
      document.createur = this.utilisateur.matricule;
      document.file = fileToUpload;
      this.documents.push(document);
    }
  }

  deleteDocument(document: DocumentProjet): void {
    this.documents = this.documents.filter(doc => doc.id !== document.id);
    if (document.id) {
      this.deletedDocuments.push(document);
    }
  }

  uploadDocument(document: DocumentProjet): Observable<any> {
    const path = `${this.projet.id}/${this.structure.id}/${this.SUBDIRECTORY}/${document.id}`;
    const fileType = 'B401';
    return this.uploadDocumentService.getValueForDocStructureUpload(document.nom, path, fileType, document.id, this.projet.id).pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError(err => this.handleError(err)),
      map(response => response.body!.url),
      switchMap(url =>
        this.uploadDocumentService.upload(url, document.file, this.projet.id, document.id, fileType).pipe(
          takeUntilDestroyed(this.destroyRef),
          catchError(err => this.handleError(err))
        )
      )
    );
  }

  clearDocumentFile(document: DocumentProjet): void {
    const path = `${this.projet.id}/${this.structure.id}/${this.SUBDIRECTORY}/${document.id}/${document.nom}`;
    this.uploadDocumentS3Service
      .deleteDocFromS3(path, 'B401', document.id, this.projet.id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(err => this.handleError(err))
      )
      .subscribe();
  }

  saveDocuments(informationBancaire: InformationBancaire): Observable<any> {
    return forkJoin([
      ...this.documents
        .filter(document => !document.id)
        .map(document => {
          document.informationBancaireId = informationBancaire.id;
          return this.projetService.upsertDocumentProjet(document).pipe(
            takeUntilDestroyed(this.destroyRef),
            catchError(err => this.handleError(err)),
            map(response => response.body!),
            switchMap(doc => {
              document.id = doc.id;
              document.dateCreation = doc.dateCreation;
              return this.uploadDocument(document);
            })
          );
        }),

      ...this.deletedDocuments.map(document => {
        return this.projetService.deleteDocument(this.projet.id, document.id).pipe(
          takeUntilDestroyed(this.destroyRef),
          catchError(err => this.handleError(err)),
          tap(() => {
            this.clearDocumentFile(document);
          })
        );
      }),
      of(null), // in case there is no dirty document
    ]).pipe(
      tap(() => {
        this.deletedDocuments = [];
      })
    );
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    const invalidIbanCodes = ['2548', '2549'];
    if (invalidIbanCodes.includes(err?.error?.code)) {
      this.showInvalidIban = true;
    } else {
      this.showToastrService.checkCodeError(err?.error);
    }
    return EMPTY;
  }
}
