import { Injectable } from '@angular/core';
import {
  DonneesFinancieres,
  IsolatedEditableRow_ComptesResultats,
  PeriodeFinanciere,
  SaisieLabel_ComptesResultats,
  SaisieLabel_PlanFinancement,
  SectionFinanciere_ComptesResultats,
  SectionFinanciere_PlanFinancement,
  WarningAmountsByPeriod,
} from '@shared-ui';
import { BehaviorSubject } from 'rxjs';
import {
  ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE,
  SaisieLabel_PlanTresorerie,
  SectionFinanciere_PlanTresorerie,
} from '../../../components/projet-consortium-donnees-financieres/projet-consortium-df-plan-tresorerie/plan-tresorerie.model';

@Injectable({
  providedIn: 'root',
})
export class DonneesFinancieresHelperService {
  private readonly NB_OF_PERIODS_COMPTES_RESULTATS = 5;
  private readonly NB_OF_PERIODS_PLAN_FINANCEMENT = 5;
  private readonly NB_OF_PERIODS_PLAN_TRESORERIE = 4;
  private readonly CURRENT_YEAR_LABEL = 'Exercice en cours';
  private readonly PLAN_TRESORERIE_PERIODS: string[] = ["Aujourd'hui à 6 mois", '6 à 12 mois', '12 à 18 mois', '18 à 24 mois'];
  private readonly COMPTES_RESULTATS_PERIODE_FINANCIERE: PeriodeFinanciere = {
    periode: '',
    sections: [
      {
        label: SectionFinanciere_ComptesResultats.TOTAL_PEX,
        saisies: [
          {
            label: SaisieLabel_ComptesResultats.CA,
          },
          {
            label: SaisieLabel_ComptesResultats.PI,
          },
          {
            label: SaisieLabel_ComptesResultats.PST,
          },
        ],
      },
      {
        label: SectionFinanciere_ComptesResultats.TOTAL_CPT,
        saisies: [
          {
            label: SaisieLabel_ComptesResultats.AMPM,
          },
          {
            label: SaisieLabel_ComptesResultats.VSMM,
          },
          {
            label: SaisieLabel_ComptesResultats.AACE,
          },
        ],
      },
      {
        label: SectionFinanciere_ComptesResultats.VA,
        saisies: [],
      },
      {
        label: SectionFinanciere_ComptesResultats.EBE,
        saisies: [
          {
            label: SaisieLabel_ComptesResultats.SE,
          },
          {
            label: SaisieLabel_ComptesResultats.IT,
          },
          {
            label: SaisieLabel_ComptesResultats.CP,
          },
        ],
      },
      {
        label: SectionFinanciere_ComptesResultats.RESULTAT_EXPLOITATION,
        saisies: [
          {
            label: SaisieLabel_ComptesResultats.DA,
          },
          {
            label: SaisieLabel_ComptesResultats.ACE,
          },
          {
            label: SaisieLabel_ComptesResultats.APE,
          },
        ],
      },
      {
        label: SectionFinanciere_ComptesResultats.RESULTAT_CAI,
        saisies: [
          {
            label: SaisieLabel_ComptesResultats.PF,
          },
          {
            label: SaisieLabel_ComptesResultats.CF,
          },
        ],
      },
      {
        label: SectionFinanciere_ComptesResultats.RESULTAT_EXERCICE,
        saisies: [
          {
            label: SaisieLabel_ComptesResultats.PE,
          },
          {
            label: SaisieLabel_ComptesResultats.CE,
          },
          {
            label: SaisieLabel_ComptesResultats.PSA,
          },
          {
            label: SaisieLabel_ComptesResultats.IB,
          },
        ],
      },
      {
        label: SectionFinanciere_ComptesResultats.CAPACITE_AUTOFINANCEMENT,
        saisies: [
          {
            label: SaisieLabel_ComptesResultats.EF,
          },
        ],
      },
      {
        label: IsolatedEditableRow_ComptesResultats.MPCE,
        saisies: [
          {
            label: IsolatedEditableRow_ComptesResultats.MPCE,
          },
        ],
      },
      {
        label: IsolatedEditableRow_ComptesResultats.MP,
        saisies: [
          {
            label: IsolatedEditableRow_ComptesResultats.MP,
          },
        ],
      },
    ],
  };
  private readonly PLAN_FINANCEMENT_PERIODE_FINANCIERE: PeriodeFinanciere = {
    periode: '',
    sections: [
      {
        label: SectionFinanciere_PlanFinancement.TOTAL_BESOINS,
        saisies: [
          {
            label: SaisieLabel_PlanFinancement.IDP,
          },
          {
            label: SaisieLabel_PlanFinancement.ILICRP,
          },
          {
            label: SaisieLabel_PlanFinancement.IC,
          },
          {
            label: SaisieLabel_PlanFinancement.BFRAUG,
          },
          {
            label: SaisieLabel_PlanFinancement.BFRDIM,
          },
          {
            label: SaisieLabel_PlanFinancement.RC,
          },
          {
            label: SaisieLabel_PlanFinancement.DIVERS,
          },
        ],
      },
      {
        label: SectionFinanciere_PlanFinancement.TOTAL_RESSOURCES,
        saisies: [
          {
            label: SaisieLabel_PlanFinancement.AC,
          },
          {
            label: SaisieLabel_PlanFinancement.ACC,
          },
          {
            label: SaisieLabel_PlanFinancement.CA,
          },
          {
            label: SaisieLabel_PlanFinancement.EDN,
          },
          {
            label: SaisieLabel_PlanFinancement.ERN,
          },
          {
            label: SaisieLabel_PlanFinancement.AE,
          },
        ],
      },
      {
        label: SectionFinanciere_PlanFinancement.SOLDE_TRESORERIE,
        saisies: [],
      },
      {
        label: SectionFinanciere_PlanFinancement.CUMUL_TRESORERIE,
        saisies: [],
      },
    ],
  };
  private readonly PLAN_TRESORERIE_PERIODE_FINANCIERE: PeriodeFinanciere = {
    periode: '',
    sections: [
      {
        label: ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE,
        saisies: [
          {
            label: ISOLATED_EDITABLE_ROW_PLAN_TRESORERIE,
          },
        ],
      },
      {
        label: SectionFinanciere_PlanTresorerie.EDEX,
        saisies: [
          {
            label: SaisieLabel_PlanTresorerie.EV,
          },
          {
            label: SaisieLabel_PlanTresorerie.APE,
          },
        ],
      },
      {
        label: SectionFinanciere_PlanTresorerie.EHEX,
        saisies: [
          {
            label: SaisieLabel_PlanTresorerie.LF,
          },
          {
            label: SaisieLabel_PlanTresorerie.ACC,
          },
          {
            label: SaisieLabel_PlanTresorerie.PB,
          },
          {
            label: SaisieLabel_PlanTresorerie.FBPIPCB,
          },
          {
            label: SaisieLabel_PlanTresorerie.FBPIAR,
          },
          {
            label: SaisieLabel_PlanTresorerie.FBPISUB,
          },
        ],
      },
      {
        label: SectionFinanciere_PlanTresorerie.TE,
        saisies: [],
      },
      {
        label: SectionFinanciere_PlanTresorerie.DDEX,
        saisies: [
          {
            label: SaisieLabel_PlanTresorerie.DRD,
          },
          {
            label: SaisieLabel_PlanTresorerie.IFBPIAN,
          },
          {
            label: SaisieLabel_PlanTresorerie.IFHBPIAN,
          },
          {
            label: SaisieLabel_PlanTresorerie.LCBAN,
          },
          {
            label: SaisieLabel_PlanTresorerie.ADE,
          },
        ],
      },
      {
        label: SectionFinanciere_PlanTresorerie.DHEX,
        saisies: [
          {
            label: SaisieLabel_PlanTresorerie.ICI,
          },
          {
            label: SaisieLabel_PlanTresorerie.RFBPIAN,
          },
          {
            label: SaisieLabel_PlanTresorerie.RFHBPIAN,
          },
          {
            label: SaisieLabel_PlanTresorerie.RCC,
          },
          {
            label: SaisieLabel_PlanTresorerie.ADHE,
          },
        ],
      },
      {
        label: SectionFinanciere_PlanTresorerie.TD,
        saisies: [],
      },
      {
        label: SectionFinanciere_PlanTresorerie.STFP,
        saisies: [],
      },
      {
        label: SectionFinanciere_PlanTresorerie.SCTFP,
        saisies: [],
      },
    ],
  };
  private readonly DONNEES_FINANCIERES_DEFAULT: DonneesFinancieres = {
    statut: 'VALIDE',
    isPME: false,
    isOlderThan8Years: false,
    comptesResultats: this.buildDefaultPeriodesFinancieres(this.COMPTES_RESULTATS_PERIODE_FINANCIERE, this.NB_OF_PERIODS_COMPTES_RESULTATS),
    planFinancements: this.buildDefaultPeriodesFinancieres(this.PLAN_FINANCEMENT_PERIODE_FINANCIERE, this.NB_OF_PERIODS_PLAN_FINANCEMENT),
    planTresoreries: this.buildDefaultPeriodesFinancieres(this.PLAN_TRESORERIE_PERIODE_FINANCIERE, this.NB_OF_PERIODS_PLAN_TRESORERIE),
  };
  private warningAmountsByPeriod: WarningAmountsByPeriod = {};

  public buildDonneesFinancieres(df: DonneesFinancieres | undefined): DonneesFinancieres {
    const donneesFinancieres: DonneesFinancieres = df || this.DONNEES_FINANCIERES_DEFAULT;
    donneesFinancieres.comptesResultats = this.buildComptesResultats(donneesFinancieres);
    donneesFinancieres.planFinancements = this.buildPlanFinancement(donneesFinancieres);
    donneesFinancieres.planTresoreries = this.buildPlanTresorerie(donneesFinancieres);
    return donneesFinancieres;
  }

  private buildDefaultPeriodesFinancieres(periodeFinanciere: PeriodeFinanciere, nbOfPeriods: number): PeriodeFinanciere[] {
    const periodesFinancieres: PeriodeFinanciere[] = [];
    for (let i = 0; i < nbOfPeriods; i++) {
      periodesFinancieres.push(periodeFinanciere);
    }
    return periodesFinancieres;
  }

  private buildComptesResultats(donneesFinancieres: DonneesFinancieres): PeriodeFinanciere[] {
    const comptesResultats: PeriodeFinanciere[] = donneesFinancieres.comptesResultats?.length
      ? donneesFinancieres.comptesResultats
      : this.DONNEES_FINANCIERES_DEFAULT.comptesResultats || [];
    return this.fillPeriodsAsYears(comptesResultats);
  }

  private buildPlanFinancement(donneesFinancieres: DonneesFinancieres): PeriodeFinanciere[] {
    const plansFinancements: PeriodeFinanciere[] = donneesFinancieres.planFinancements?.length
      ? donneesFinancieres.planFinancements
      : this.DONNEES_FINANCIERES_DEFAULT.planFinancements || [];
    return this.fillPeriodsAsYears(plansFinancements);
  }

  private buildPlanTresorerie(donneesFinancieres: DonneesFinancieres): PeriodeFinanciere[] {
    const plansTresorerie: PeriodeFinanciere[] = donneesFinancieres.planTresoreries?.length
      ? donneesFinancieres.planTresoreries
      : this.DONNEES_FINANCIERES_DEFAULT.planTresoreries || [];
    return this.fillPlanTresoreriePeriods(plansTresorerie);
  }

  private fillPeriodsAsYears(periodesFinancieres: PeriodeFinanciere[]): PeriodeFinanciere[] {
    const currentYear: number = new Date().getFullYear();
    return periodesFinancieres.map((pf: PeriodeFinanciere, index: number) => {
      return {
        ...pf,
        periode: index === 0 ? this.CURRENT_YEAR_LABEL : '' + (currentYear + index),
      };
    });
  }

  private fillPlanTresoreriePeriods(periodesFinancieres: PeriodeFinanciere[]): PeriodeFinanciere[] {
    return periodesFinancieres.map((pf: PeriodeFinanciere, index: number) => {
      return {
        ...pf,
        periode: this.PLAN_TRESORERIE_PERIODS[index],
      };
    });
  }

  public initWarningAmountsByPeriod(periodes: string[]): void {
    const init: WarningAmountsByPeriod = periodes.reduce((acc: WarningAmountsByPeriod, current: string) => {
      const added: WarningAmountsByPeriod = {
        [current]: {
          idpSaisie$: new BehaviorSubject<number>(0),
          mpSaisie$: new BehaviorSubject<number>(0),
          mpceSaisie$: new BehaviorSubject<number>(0),
        },
      };
      return { ...acc, ...added };
    }, {});
    this.setWarningAmountsByPeriod(init);
  }

  public setWarningAmountsByPeriod(warningAmountsByPeriod: WarningAmountsByPeriod): void {
    this.warningAmountsByPeriod = warningAmountsByPeriod;
  }

  public getWarningAmountsByPeriod(): WarningAmountsByPeriod {
    return this.warningAmountsByPeriod;
  }
}
