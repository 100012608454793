import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';

import { SuiviProjetService } from '@features-candidat/suivi-projet/service/suivi-projet.service';
import { SuiviProjet } from '@shared-ui';

export const suiviProjetResolver: ResolveFn<SuiviProjet> = (route: ActivatedRouteSnapshot): Observable<SuiviProjet> => {
  const id = route.paramMap.get('id');
  const suiviProjetService: SuiviProjetService = inject(SuiviProjetService);

  return suiviProjetService.getById(id);
};
