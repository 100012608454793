<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToProjetInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Projet
  </a>
  <h2 class="page-header__title">Pôles</h2>
</header>
<!-- FIN de Entête de la page -->

<div class="div_page-domaine-container">
  <!-- Contenu de la page -->
  <div class="page-poles-content">
    <h2 class="page-section_title">Sélectionnez votre pôle</h2>
    <div class="div_checkboxs_container">
      <label *ngFor="let pole of this.aapPoles"
        ><input class="btnlike" type="checkbox" [checked]="pole.checked" (change)="onSelectCheckbox(pole)" /><span class="unselectable">{{
          pole.nomCommercial | uppercase
        }}</span></label
      >
    </div>
    <div class="message-selectionner-pole" *ngIf="this.polesNonSelectione">Merci de bien vouloir sélectionner un ou plusieurs pôles.</div>
    <div class="div_button">
      <button id="creation_projet_btn_annuler" type="button" class="ds-btn ds-btn--secondary" (click)="onClickBack()">Annuler</button>
      <button
        id="creation_projet_btn_ajouter"
        type="submit"
        class="ds-btn ds-btn--primary"
        (click)="onValidateChoice()"
        *ngxPermissionsOnly="['PROJET_WRITE']"
        [disabled]="!sharedFunction.isProjectUpdatable(aap, projet)"
      >
        <span>Valider ma sélection</span>
      </button>
    </div>
  </div>
</div>
