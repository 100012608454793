<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToConsortium()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Ma Structure
  </a>
  <h2 id="title-structure" class="page-header__title">{{ currentStructure.raisonSocial }}</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <!-- Message d'information demande de correction -->
  <div class="card--info card--blue card--readonly" *ngIf="currentStructure?.adresse?.demandeRectification?.demandeTraitee === false">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    Vous avez fait une demande de correction de vos informations siège à Bpifrance. Dès que ces informations seront corrigées, vous serez
    notifié.
  </div>
  <div class="card--info card--blue card--readonly" *ngIf="currentStructure?.adresse?.demandeRectification?.demandeTraitee">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    Les informations siège ont été mises à jour le
    {{ currentStructure?.adresse?.demandeRectification?.dateTraitement | date : 'dd/MM/YYYY' }}
  </div>
  <form id="structure-form" class="form" [formGroup]="structureForm" (ngSubmit)="submitForm()">
    <!-- Informations de la structure -->
    <section *ngIf="currentStructure" class="form-step structure">
      <h3 class="form-step__title">Informations du siège / de la structure</h3>
      <div class="form-step__content">
        <!-- Role de la structure -->
        <div
          id="input-role"
          class="form-step__role"
          *ngIf="isRoleSelectable"
          [class.ds-input-group--error]="structureForm.controls['role'].invalid && structureForm.controls['role'].touched"
        >
          <div class="ds-input-group__label">Rôle <span class="ds-input-group__label-asterisk"> *</span></div>
          <div class="select-container">
            <div id="id_input-role-select" class="input-role-select role-select">
              <input formControlName="role" type="radio" name="role" id="candidat" value="CANDIDAT" hidden />
              <label for="candidat">Candidat</label>
              <input formControlName="role" type="radio" name="role" id="chef" value="CHEF_DE_FILE" hidden />
              <label for="chef">Chef de file</label>
              <ng-container>
                <input
                  formControlName="role"
                  [attr.disabled]="this.currentStructure.role === EnumRoleStructure.MANDATAIRE ? null : true"
                  type="radio"
                  name="role"
                  id="mandataire"
                  value="MANDATAIRE"
                  hidden
                />
                <label
                  [matTooltip]="currentStructure.role !== EnumRoleStructure.MANDATAIRE ? mandataireRoleSelectionInfo : null"
                  matTooltipPosition="above"
                  [class.radio-disabled]="currentStructure.role !== EnumRoleStructure.MANDATAIRE"
                  for="mandataire"
                  >Mandataire</label
                >
              </ng-container>
            </div>
          </div>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-role" *ngIf="!structureForm.controls['role'].valid && structureForm.controls['role'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir sélectionner votre rôle</div>
          </ng-container>
          <!--Informations siege -->
          <div class="card--blue-paragraph role-message">
            <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
            <p *ngIf="structureForm.controls['role'].value === EnumRoleStructure.CANDIDAT" class="card--info--text">
              La structure avec le rôle Candidat est partie prenante du projet. Elle doit renseigner les éléments de sa structure et pourra
              accéder aux autres données du projet en lecture seule. Le nombre de structure avec le rôle Candidat n'est pas limité.
            </p>
            <p *ngIf="structureForm.controls['role'].value === EnumRoleStructure.CHEF_DE_FILE" class="card--info--text">
              La structure avec le rôle Chef de file est partie prenante du projet. Elle est en charge de renseigner les éléments du projet,
              assure la coordination du dépôt et aura la charge de transmettre le dossier complet à Bpifrance. La structure Chef de file
              possède un droit d'écriture sur l'ensemble des sections projet et structure hors grille d'impacts. Il n'y a qu'une seule
              structure avec le rôle Chef de file sur le projet.
            </p>
            <p *ngIf="structureForm.controls['role'].value === EnumRoleStructure.MANDATAIRE" class="card--info--text">
              La structure avec le rôle Mandataire <b>ne prend pas part au projet</b>. Elle possède un mandat pour venir déposer tout ou
              partie du dossier au nom de son ou ses clients, et aura la charge de transmettre le dossier complet à Bpifrance. La structure
              Mandataire possède un droit d'écriture sur l'ensemble des sections projet et structure. Il n'y a qu'une seule structure avec
              le rôle Mandataire sur le projet.
            </p>
          </div>
        </div>
        <!-- Role de la structure -->

        <div class="ds-input-group">
          <lib-structure-searcher
            [displayCreationEnCoursOption]="aap.personnePhysique"
            [motifNonRattachement]="currentStructure.raisonSiret"
            (motifNonRattachementSelected)="onMotifNonRattachementSelected($event)"
            (structureSelected)="onStructureSelected($event)"
            [maStructureNotListedButtonVisibleOnEmptyList]="true"
            (maStructureNotListed)="onMaStructureNotListed()"
          ></lib-structure-searcher>
        </div>

        <!-- Raison sociale -->
        <div
          *ngIf="structureEnCreation"
          id="input-raison"
          class="ds-input-group"
          [class.ds-input-group--error]="
            structureForm.get('raisonSocial')?.invalid &&
            structureForm.get('raisonSocial')?.enabled &&
            structureForm.get('raisonSocial')?.touched
          "
        >
          <label for="raison" class="ds-input-group__label">Raison Sociale</label>
          <span class="ds-input-group__input">
            <input
              id="raison"
              name="raisonSocial"
              type="text"
              maxlength="100"
              placeholder="Votre Raison Sociale"
              class="input_raison ds-input-group__input"
              formControlName="raisonSocial"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-raison"
            *ngIf="
              structureForm.get('raisonSocial')?.invalid &&
              structureForm.get('raisonSocial')?.enabled &&
              structureForm.get('raisonSocial')?.touched
            "
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une raison sociale valide</div>
          </ng-container>
        </div>
      </div>

      <lib-demande-rectification-card
        *ngIf="currentStructure?.adresse?.demandeRectification && !currentStructure?.adresse?.demandeRectification?.demandeTraitee"
        [demandeRectification]="currentStructure.adresse.demandeRectification"
      >
      </lib-demande-rectification-card>
    </section>
    <!-- FIN de Informations de la structure -->

    <ng-container *ngIf="!structureEnCreation">
      <section class="form-step">
        <h3 class="form-step__title">Adresse du siège / de la structure</h3>
        <div class="form-step__content">
          <lib-information-siege
            [structureForm]="structureForm"
            [readOnly]="structureIdentifiedByRET"
            [readOnlyExceptions]="['typeStructure', 'effectifSiege', 'effectifGroupe']"
            [canRequestRectification]="true"
            (rectificationRequested)="submitDemandeRectification($event)"
            (rectificationCanceled)="rectificationCanceled = $event"
          >
          </lib-information-siege>
        </div>
      </section>
    </ng-container>

    <div class="form-actions">
      <button id="cancel-save" type="button" class="ds-btn ds-btn--secondary" (click)="onGoToConsortium()">Annuler</button>
      <button id="save-structure" type="submit" class="ds-btn ds-btn--primary" *ngIf="isStructureUpdatableByUser">
        <span>Modifier</span>
      </button>
    </div>
  </form>
</div>
<!-- FIN de Contenu de la page -->
