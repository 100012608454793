<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>
<div id="invit-structure" class="modal__content">
  <form id="structure-form" class="form" [formGroup]="structureForm">
    <!-- Raison sociale -->
    <div
      id="input-raison"
      class="ds-input-group"
      [class.ds-input-group--error]="!structureForm.controls['raisonSocial'].valid && structureForm.controls['raisonSocial'].touched"
    >
      <label for="raison" class="ds-input-group__label">Raison sociale de la structure</label>
      <span class="ds-input-group__input">
        <input
          id="raison"
          name="raisonSocial"
          type="text"
          maxlength="100"
          placeholder="Raison sociale"
          class="input_raison ds-input-group__input"
          formControlName="raisonSocial"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container
        id="error-raison"
        *ngIf="!structureForm.controls['raisonSocial'].valid && structureForm.controls['raisonSocial'].touched"
      >
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une raison sociale valide</div>
      </ng-container>
    </div>
    <!-- Adress mail du contact de la structure-->
    <!-- mail -->
    <div
      id="input-email"
      class="ds-input-group"
      [class.ds-input-group--error]="!structureForm.controls['email'].valid && structureForm.controls['email'].touched"
    >
      <label for="email" class="ds-input-group__label">Adresse mail du contact de la structure</label>
      <span class="ds-input-group__input">
        <input
          id="email"
          name="email"
          type="text"
          maxlength="80"
          pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          placeholder="Adresse mail du contact de la structure"
          class="input_email ds-input-group__input"
          formControlName="email"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container id="error-email" *ngIf="!structureForm.controls['email'].valid && structureForm.controls['email'].touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un mail valide</div>
      </ng-container>
    </div>

    <!--fin adress mail du contact de la structure -->
    <!-- Role de la structure -->
    <div
      id="input-role"
      class="form-step__role ds-input-group"
      *ngIf="roleSelectable"
      [class.ds-input-group--error]="!structureForm.controls['role'].valid && structureForm.controls['role'].touched"
    >
      <div class="ds-input-group__label">Rôle de la structure</div>
      <div class="select-container">
        <div id="id_input-role-select" class="input-role-select role-select">
          <input
            formControlName="role"
            type="radio"
            name="role"
            [attr.disabled]="modalData.isChefFileExist ? true : null"
            id="chef"
            value="CHEF_DE_FILE"
            hidden
          />
          <label for="chef">Chef de file</label>
          <input
            formControlName="role"
            type="radio"
            name="role"
            [attr.disabled]="modalData.isMandatoryExist ? true : null"
            id="mandataire"
            value="MANDATAIRE"
            hidden
          />
          <label for="mandataire">Mandataire</label>
          <input formControlName="role" type="radio" name="role" id="candidat" value="CANDIDAT" hidden />
          <label
            matTooltip="Pour toutes les structures n’ayant ni le rôle de chef de file ni de mandataire"
            matTooltipPosition="right"
            for="candidat"
          >
            Candidat</label
          >
          <div class="card__text">
            <div class="card__action-button-wrapper">
              <div class="card__action-button-tooltip">
                <p>Pour toutes les structures n’ayant ni le rôle de chef de file ni de mandataire</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Icône et message d'erreur -->
      <ng-container id="error-role" *ngIf="!structureForm.controls['role'].valid && structureForm.controls['role'].touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir sélectionner votre rôle</div>
      </ng-container>

      <ng-container *ngIf="(structureForm?.controls)['role']?.value">
        <div class="card--blue">
          <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
          <p *ngIf="structureForm.controls['role'].value === EnumRoleStructure.CHEF_DE_FILE" class="card--info--text">
            La structure avec le rôle Chef de file est partie prenante du projet. Elle est en charge de renseigner les éléments du projet,
            assure la coordination du dépôt et aura la charge de transmettre le dossier complet à Bpifrance. La structure Chef de file
            possède un droit d'écriture sur l'ensemble des sections projet et structure hors grille d'impacts. Il n'y a qu'une seule
            structure avec le rôle Chef de file sur le projet.
          </p>
          <p *ngIf="structureForm.controls['role'].value === EnumRoleStructure.MANDATAIRE" class="card--info--text">
            La structure avec le rôle Mandataire <b>ne prend pas part au projet</b>. Elle possède un mandat pour venir déposer tout ou
            partie du dossier au nom de son ou ses clients, et aura la charge de transmettre le dossier complet à Bpifrance. La structure
            Mandataire possède un droit d'écriture sur l'ensemble des sections projet et structure. Il n'y a qu'une seule structure avec le
            rôle Mandataire sur le projet.
          </p>
          <p *ngIf="structureForm.controls['role'].value === EnumRoleStructure.CANDIDAT" class="card--info--text">
            La structure avec le rôle Candidat est partie prenante du projet. Elle doit renseigner les éléments de sa structure et pourra
            accéder aux autres données du projet en lecture seule. Le nombre de structure avec le rôle Candidat n'est pas limité.
          </p>
        </div>
      </ng-container>
    </div>
  </form>

  <div id="cgu" class="cgu">
    Pour en savoir plus sur la gestion de vos données personnelles, <a (click)="downloadRGPD()">cliquez ici</a>
    <br />
    Tout UTILISATEUR effectuant une (des) Demande(s) dans l’Extranet s’engage à informer les personnes dont il pourrait mentionner les
    données à caractère personnel au titre de la (des) Demande(s) des traitements de données à caractère personnel conformément au présent
    article.
  </div>

  <div class="form-actions">
    <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" mat-dialog-close>
      {{ modalData.textReturnButton }}
    </button>
    <button class="ds-btn ds-btn--primary" (click)="submitForm(structureForm.value)">
      {{ modalData.textGoButton }}
    </button>
  </div>
</div>
