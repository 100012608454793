<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToProjetInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Information du projet
  </a>
  <h2 class="page-header__title">Domaine(s) technologique(s)</h2>
</header>
<!-- FIN de Entête de la page -->

<div class="div_page-domaine-container">
  <!-- Contenu de la page -->
  <div class="page-domaine-content">
    <ng-container *ngFor="let domaine of aapDomaines">
      <h2 class="page-section_title">{{ domaine.libelleDomaine }}</h2>
      <div class="div_checkboxs_container">
        <label *ngFor="let sousDomaine of domaine.sousDomaines"
          ><input
            class="btnlike"
            type="checkbox"
            [checked]="sousDomaine.checked"
            [disabled]="maxChecked === 3 && !sousDomaine.checked"
            (change)="onSelectCheckbox(domaine, sousDomaine)"
          />
          <span class="unselectable">{{ sousDomaine.libelleSousDomaine }}</span>
        </label>
      </div>
    </ng-container>
    <div class="message-selectionner-domaine" *ngIf="this.domainesNonSelectione">
      Merci de bien vouloir sélectionner un ou plusieurs sous-domaines technologiques.
    </div>
    <div class="div_button">
      <button id="creation_projet_btn_annuler" type="button" class="ds-btn ds-btn--secondary" (click)="onClickBack()">Annuler</button>
      <button
        id="creation_projet_btn_ajouter"
        type="submit"
        class="ds-btn ds-btn--primary"
        (click)="onValidateChoice()"
        *ngxPermissionsOnly="['PROJET_WRITE']"
        [disabled]="!sharedFunction.isProjectUpdatable(aap, projet)"
      >
        <span>Valider ma sélection</span>
      </button>
    </div>
  </div>
</div>
