import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EnumTypeStructure } from '../../shared/enums/enum.typeStructure';
import { SubscriptionDestroyerComponent } from '../../shared/subscription-destroyer/subscription-destroyer.abstract';

@Component({
  selector: 'lib-information-contact',
  templateUrl: './information-contact.component.html',
  styleUrls: ['./information-contact.component.scss'],
})
export class InformationContactComponent extends SubscriptionDestroyerComponent implements OnInit, OnChanges {
  @Input() structureForm: any;
  @Input() readOnly = false;
  @Input() readOnlyExceptions: string[] = [];

  isPaysFrance = false;

  ngOnInit() {
    this.updateState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['readOnly']) {
      this.updateState();
    }
  }

  updateState() {
    const relatedControlNames = ['adresse'];
    relatedControlNames.forEach(controlName => {
      if (this.readOnly && !this.readOnlyExceptions.includes(controlName)) {
        this.structureForm.get(controlName)?.disable();
      } else {
        this.structureForm.get(controlName)?.enable();
      }
    });
    this.structureForm.get('typeStructure')?.setValue('PERSONNE_PHYSIQUE');
    this.structureForm.get('typeStructure')?.disable();
    this.structureForm.get('siret')?.reset();
    this.structureForm.get('siret')?.disable();
    this.structureForm.get('activite')?.reset();
    this.structureForm.get('activite')?.disable();
    this.structureForm.get('effectifSiege')?.reset();
    this.structureForm.get('effectifSiege')?.disable();
    this.structureForm.get('effectifGroupe')?.reset();
    this.structureForm.get('effectifGroupe')?.disable();
    this.structureForm.get('raisonSocial')?.clearValidators();
    this.structureForm.get('raisonSocial')?.updateValueAndValidity();
  }

  protected readonly EnumTypeStructure = EnumTypeStructure;
}
