import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  DisplayColumn,
  PeriodeFinanciere,
  PrevisionsEconomiques,
  PrevisionsEconomiquesSharedService,
  SaisieFinanciere,
  SectionFinanciere,
  Structure,
  SubscriptionDestroyerComponent,
  TableCelldFpE,
} from '@shared-ui';
import { PrevisionEconomiqueHelperService } from '../prevision-economique.helper.service';
interface Line {
  label: string;
  data: { [key: string]: TableCelldFpE };
  effectif: string;
  style?: string;
  isHeader?: boolean;
}

@Component({
  selector: 'pxl-projet-consortium-pe-effectif',
  templateUrl: './projet-consortium-pe-effectif.component.html',
})
export class projetConsortiumPeEffectifComponent extends SubscriptionDestroyerComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() formName: string;
  @Input() structure: Structure;
  @Input() isStructureUpdatableByUser: boolean;

  columnName: string;
  columns: DisplayColumn[];
  annees: DisplayColumn[] = [];

  displayedColumns: DisplayColumn[] = [{ def: 'label', label: '', editable: false, width: '25rem', sticky: true }];

  dataSource: Line[] = [];

  previsionEconomique: PrevisionsEconomiques;
  effectifForm: FormGroup;

  constructor(public matDialog: MatDialog, private previsionEconomiqueService: PrevisionsEconomiquesSharedService) {
    super();
  }

  ngOnInit(): void {
    this.previsionEconomiqueService.getPrevisionsEconomiques().pipe(this.takeUntilDestroyed()).subscribe(this.updateModele.bind(this));
  }

  updateModele(previsionEconomique: PrevisionsEconomiques): void {
    const previsionEconomiqueFG = PrevisionEconomiqueHelperService.getStructureForm(this.structure);
    this.form?.setControl(this.formName, previsionEconomiqueFG);
    this.previsionEconomique = previsionEconomique;
    this.effectifForm = this.form?.get(this.formName) as FormGroup;
    this.initColumns();
    this.updateDataSource();
  }

  private initColumns(): void {
    this.columns = [...this.displayedColumns];
    this.annees = this.effectifForm.value.effectifs.map((periodeFinanciere: PeriodeFinanciere) => {
      return {
        def: periodeFinanciere.periode,
        label: periodeFinanciere.periode,
        editable: true,
        width: '7rem',
      };
    });

    this.annees = this.annees.filter((col, index, self) => index === self.findIndex(t => t.def === col.def));

    this.columns.push(...this.annees);
    this.displayedColumns = this.columns;
  }

  private updateDataSource() {
    const data: Line[] = [];
    const periodes = (this.effectifForm?.get('effectifs') as FormArray)?.value ?? [];
    periodes.forEach((periode: PeriodeFinanciere) => {
      const annee: string = periode.periode;
      const effectifs = periode?.sections ?? [];
      effectifs.forEach((effectif: SectionFinanciere) => {
        const nomEffectif = effectif.label;
        const saisies = effectif.saisies ?? [];
        this.initProduitSection(nomEffectif, data);
        this.updateSaisiesCells(saisies, annee, nomEffectif, data);
      });
    });

    this.dataSource = data;
  }

  initProduitSection(nomEffectif: string, data: Line[]) {
    const existingProduit = data.find(line => line.label === nomEffectif);
    if (!existingProduit) {
      data.push({
        label: nomEffectif,
        effectif: nomEffectif,
        data: {},
        style: 'font-weight: 700;',
        isHeader: true,
      });
    }
  }

  updateSaisiesCells(saisies: SaisieFinanciere[], annee: string, nomEffectif: string, data: Line[]): Line[] {
    saisies.forEach((saisie: SaisieFinanciere) => {
      const existingLine = data.find(line => line.effectif === nomEffectif && line.label === saisie.label);
      if (existingLine) {
        existingLine.data[annee] = new TableCelldFpE(saisie.saisie);
      } else {
        data.push({
          label: saisie.label,
          effectif: nomEffectif,
          data: {
            [annee]: new TableCelldFpE(saisie.saisie),
          },
        });
      }
    });
    return data;
  }

  onKeyPress($event: KeyboardEvent): boolean {
    if ($event.key) {
      if (/[^\d]/.test($event.key)) {
        return false;
      }
    }
    return true;
  }

  // update data and form
  updateData($event: { element: { effectif: string; label: string }; col: string; value: number }): void {
    this.dataSource.forEach(m => {
      if (m.effectif === $event.element.effectif && m.label === $event.element.label) {
        m.data = { ...m.data, [$event.col]: new TableCelldFpE($event.value) };
      }
    });

    const updateSaisie = (saisies: SaisieFinanciere[]) => {
      saisies.forEach((saisie: SaisieFinanciere) => {
        if (saisie.label === $event.element.label) {
          saisie.saisie = $event.value;
        }
      });
    };

    this.effectifForm
      .get('effectifs')
      .value.flat()
      .forEach((data: PeriodeFinanciere) => {
        if (data.periode === $event.col) {
          data.sections.forEach((section: SectionFinanciere) => {
            if (section.label === $event.element.effectif) {
              updateSaisie(section.saisies);
            }
          });
        }
      });
  }
}
