import { Component, Input } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { Cell, CellNumberValue, Row, ROW_HEADER_COLUMN_DEF, RowType } from './dynamic-table.model';
import { PeriodeFinanciere } from '../../shared/models/periode-financiere.model';
import { Status } from '../../shared/enums/enum.validation';
import { DisplayColumn } from '../../shared/models/display-column';
import { SaisieFinanciere } from '../../shared/models/saisie-financiere.model';
import {
  TableDataRecord,
  ValueBySaisie,
  ValueBySaisieBySection,
  WarningAmountsByPeriod,
} from '../../shared/models/donnees-financieres.model';
import { SectionFinanciere } from '../../shared/models/section-financiere.model';
import { map } from 'rxjs/operators';
import { IsolatedEditableRow_ComptesResultats } from '../projet-consortium-donnees-financieres/projet-consortium-df-comptes-resultats/comptes-resultats.model';
import { DonneesFinancieresHelperService } from '../../shared/services/donnees-financieres/donnees-financieres.helper.service';

@Component({
  selector: 'lib-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.css'],
})
export class DynamicTableComponent {
  @Input() periodesFinancieres: PeriodeFinanciere[] = [];
  @Input() status: Status;
  @Input() isDisabled = false;
  @Input() title: string;
  @Input() isComptesResultats: boolean;

  protected periodes: string[] = [];
  protected columns: DisplayColumn[] = [];
  protected displayedColumns: string[] = [];
  protected nbOfColumns: number;
  protected rows: Row[] = [];
  protected dataSource: DynamicTableDataSource;
  protected onDataChange: (event: Event, cell: Cell) => void;

  protected readonly RowType = RowType;
  protected readonly IsolatedEditableRow_ComptesResultats = IsolatedEditableRow_ComptesResultats;

  private readonly onlyDigitsRegex = new RegExp(/^[0-9.,]+$/g);
  private readonly specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Enter'];

  constructor(protected service: DonneesFinancieresHelperService) {}

  protected buildColumns(): void {
    this.columns = [{ def: ROW_HEADER_COLUMN_DEF, label: '', editable: false, sticky: true, width: '12rem' }];
    this.displayedColumns = [ROW_HEADER_COLUMN_DEF];
    this.periodesFinancieres.forEach((pf: PeriodeFinanciere) => {
      const periode: string = pf.periode;
      this.periodes.push(periode);
      this.displayedColumns.push(periode);
      this.columns.push({ def: periode, label: periode, sticky: false, editable: true, width: '7rem' });
    });
    this.nbOfColumns = this.columns.length;
  }

  protected buildRows(
    upTitleRow: boolean,
    isIsolatedEditableRow?: (sectionLabel: string) => boolean,
    tooltipByRow?: Record<string, string>
  ): Row[] {
    let rowByLabel: Record<string, Row> = {};
    this.periodesFinancieres.forEach((periodeFinanciere: PeriodeFinanciere, pfIndex: number) => {
      const periode: string = periodeFinanciere.periode;
      periodeFinanciere.sections.forEach((section: SectionFinanciere, secIndex) => {
        const sectionLabel: string = section.label;
        const saisiesFinancieres: SaisieFinanciere[] = section.saisies;
        const periodeFinanciereOrderQuotient = 100;
        const sectionOrderQuotient = 10;
        let order: number = pfIndex * periodeFinanciereOrderQuotient + secIndex * sectionOrderQuotient;
        saisiesFinancieres.forEach((saisieFinanciere: SaisieFinanciere) => {
          rowByLabel = this.buildSectionSaisiesRows(rowByLabel, saisieFinanciere, order, periode, sectionLabel, tooltipByRow);
          order++;
        });
        if (upTitleRow) {
          order -= saisiesFinancieres.length;
        }
        // Si ce n'est pas une ligne de titre ou si c'est une ligne de titre mais qu'on a dépassé la première colonne (car les lignes de titre n'ont qu'une colonne)
        if (!upTitleRow || pfIndex > 0) {
          rowByLabel = this.buildIsolatedRow(
            upTitleRow,
            rowByLabel,
            order,
            periode,
            section,
            sectionLabel,
            isIsolatedEditableRow,
            tooltipByRow
          );
        }
      });
    });
    return Object.values(rowByLabel).sort((a, b) => a.order - b.order);
  }

  private buildSectionSaisiesRows(
    rowByLabel: Record<string, Row>,
    saisieFinanciere: SaisieFinanciere,
    order: number,
    periode: string,
    sectionLabel: string,
    tooltipByRow?: Record<string, string>
  ): Record<string, Row> {
    const { label, saisie } = saisieFinanciere;
    let row: Row = this.initSaisieRow(rowByLabel, label, order, sectionLabel, tooltipByRow);
    row = this.completeRowWithPeriodCell(row, periode, saisie, label, sectionLabel);
    rowByLabel[label] = row;
    return rowByLabel;
  }

  private initSaisieRow(
    rowByLabel: Record<string, Row>,
    label: string,
    order: number,
    sectionLabel: string,
    tooltipByRow?: Record<string, string>
  ): Row {
    return (
      rowByLabel[label] || {
        order,
        sectionLabel,
        rowType: RowType.EDITABLE,
        headerRowLabel: label,
        [ROW_HEADER_COLUMN_DEF]: {
          value: label,
          isEditable: false,
          rowDef: label,
          colDef: ROW_HEADER_COLUMN_DEF,
          rowType: RowType.EDITABLE,
          sectionLabel: sectionLabel,
          tooltip: tooltipByRow && tooltipByRow[label],
        },
      }
    );
  }

  private completeRowWithPeriodCell(row: Row, periode: string, saisie: number | undefined, label: string, sectionLabel: string): Row {
    return {
      ...row,
      [periode]: {
        value: saisie,
        isEditable: true,
        rowDef: label,
        colDef: periode,
        rowType: RowType.EDITABLE,
        sectionLabel: sectionLabel,
      },
    };
  }

  private buildIsolatedRow(
    upTitleRow: boolean,
    rowByLabel: Record<string, Row>,
    order: number,
    periode: string,
    section: SectionFinanciere,
    sectionLabel: string,
    isIsolatedEditableRow?: (sectionLabel: string) => boolean,
    tooltipByRow?: Record<string, string>
  ): Record<string, Row> {
    let isolatedRow: Row = rowByLabel[sectionLabel] || {
      order,
      sectionLabel,
      rowType: RowType.RESULT,
      headerRowLabel: sectionLabel,
      style: 'font-weight: 700;',
      [ROW_HEADER_COLUMN_DEF]: {
        value: sectionLabel,
        isEditable: false,
        rowDef: sectionLabel,
        colDef: ROW_HEADER_COLUMN_DEF,
        rowType: upTitleRow ? RowType.TITLE : RowType.RESULT,
        sectionLabel: sectionLabel,
        tooltip: tooltipByRow && tooltipByRow[sectionLabel],
      },
    };
    let isEditable = false;
    let rowType: RowType = upTitleRow ? RowType.TITLE : RowType.RESULT;
    let value: number | string | undefined = '';
    if (isIsolatedEditableRow && isIsolatedEditableRow(sectionLabel)) {
      const saisies = section.saisies;
      if (saisies.length) {
        value = saisies[0].saisie;
      }
      isEditable = true;
      rowType = RowType.ISOLATED_EDITABLE;
    }
    isolatedRow = {
      ...isolatedRow,
      [periode]: {
        value,
        isEditable,
        rowDef: sectionLabel,
        colDef: periode,
        rowType,
        sectionLabel,
      },
    };
    rowByLabel[sectionLabel] = isolatedRow;
    return rowByLabel;
  }

  protected initDataRecord(): TableDataRecord {
    return this.periodesFinancieres.reduce((acc: TableDataRecord, periodeFinanciere: PeriodeFinanciere) => {
      const { periode, sections } = periodeFinanciere;
      const valueBySaisieBySection: ValueBySaisieBySection = sections.reduce(
        (acc: ValueBySaisieBySection, sectionFinanciere: SectionFinanciere) => {
          const { label, saisies } = sectionFinanciere;
          if (!saisies.length) {
            return acc;
          }
          const valueBySaisie: ValueBySaisie = saisies.reduce((acc: ValueBySaisie, saisieFinanciere: SaisieFinanciere) => {
            const { label, saisie } = saisieFinanciere;
            return { ...acc, [label]: new CellNumberValue(saisie).value };
          }, {});
          return { ...acc, [label]: valueBySaisie };
        },
        {}
      );
      return { ...acc, [periode]: valueBySaisieBySection };
    }, {});
  }

  protected updateDataSource(colDef: string, sectionFinanciereLabel: string, newValue: number) {
    const rowIndex: number = this.rows.findIndex((row: Row) => row.headerRowLabel === sectionFinanciereLabel);
    const rowToUpdate: Row = this.rows[rowIndex];
    (rowToUpdate[colDef] as Cell).value = newValue;
    this.rows[rowIndex] = rowToUpdate;
    this.dataSource.setData(this.rows);
  }

  protected isWarningTriggered(colDef: string): Observable<boolean> {
    const warningAmountsByPeriod: WarningAmountsByPeriod = this.service.getWarningAmountsByPeriod();
    const { idpSaisie$, mpSaisie$, mpceSaisie$ } = warningAmountsByPeriod[colDef];
    return combineLatest([idpSaisie$, mpSaisie$, mpceSaisie$]).pipe(
      map((amounts: number[]) => {
        const [idp, mp, mpce] = amounts;
        return mp !== mpce + idp;
      })
    );
  }

  protected displayTooltipIcon(cell: Cell | undefined): boolean {
    return !!cell?.tooltip && cell.colDef === ROW_HEADER_COLUMN_DEF;
  }

  protected onKeyPress($event: KeyboardEvent): boolean {
    const key: string = $event.key;
    if (this.specialKeys.includes(key) || key.match(this.onlyDigitsRegex)) {
      return true;
    }
    return false;
  }
}

export class DynamicTableDataSource extends DataSource<Row> {
  private _dataStream = new ReplaySubject<Row[]>();

  constructor(initialData: Row[]) {
    super();
    this.setData(initialData);
  }

  connect(): Observable<Row[]> {
    return this._dataStream;
  }

  disconnect() {}

  setData(rows: Row[]) {
    this._dataStream.next(rows);
  }
}
