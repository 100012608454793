<form [formGroup]="structureForm">
  <!-- Type de la structure -->
  <div
    id="input-type-structure"
    class="ds-input-group"
    [class.ds-input-group--error]="
      structureForm.get('typeStructure')?.invalid &&
      structureForm.get('typeStructure')?.enabled &&
      structureForm.get('typeStructure')?.touched
    "
  >
    <label for="type-structure" class="ds-input-group__label"> Type de structure<lib-asterisk></lib-asterisk> </label>
    <div class="ds-input-group__select ds-input-group__select--with-icon">
      <select id="type-structure" name="typeStructure" formControlName="typeStructure">
        <option [ngValue]="null" selected disabled hidden>Merci de sélectionner votre type de structure</option>
        <option *ngFor="let item of EnumTypeStructure.forPersonneMorale()" [value]="item">{{ EnumTypeStructure.toString(item) }}</option>
      </select>
    </div>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-type-structure"
      *ngIf="
        structureForm.get('typeStructure')?.invalid &&
        structureForm.get('typeStructure')?.enabled &&
        structureForm.get('typeStructure')?.touched
      "
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un type de structure valide</div>
    </ng-container>
  </div>

  <div *ngIf="effectifSiege && effectifGroupe">
    <!-- Effectif du siège  -->
    <div
      id="input-effectif-siege"
      class="ds-input-group"
      [class.ds-input-group--error]="structureForm.get('effectifSiege')?.invalid && structureForm.get('effectifSiege')?.touched"
    >
      <label for="effectif-siege" class="ds-input-group__label"> Effectif du siège<lib-asterisk></lib-asterisk> </label>
      <div class="ds-input-group__input">
        <input
          (keydown)="onkeypress($event)"
          [attr.maxlength]="6"
          id="effectif-siege"
          name="effectifSiege"
          formControlName="effectifSiege"
          type="text"
          placeholder="Effectif du siège"
        />
      </div>
      <div *ngIf="effectifSiege?.invalid && effectifSiege?.touched && effectifSiege?.errors?.['required']" class="ds-input-group__feedback">
        Merci de renseigner l'effectif du siège.
      </div>
    </div>

    <!-- Effectif du groupe le cas échéant  -->
    <div
      id="input-effectif-groupe"
      class="ds-input-group"
      [class.ds-input-group--error]="structureForm.get('effectifGroupe')?.invalid && structureForm.get('effectifGroupe')?.touched"
    >
      <label for="effectif-groupe" class="ds-input-group__label"> Effectif du groupe le cas échéant<lib-asterisk></lib-asterisk> </label>
      <div class="ds-input-group__input">
        <input
          (keydown)="onkeypress($event)"
          [attr.maxlength]="6"
          id="effectif-groupe"
          name="effectifGroupe"
          formControlName="effectifGroupe"
          type="text"
          placeholder="Effectif du groupe"
        />
      </div>
      <div
        *ngIf="effectifGroupe?.invalid && effectifGroupe?.touched && effectifGroupe?.errors?.['required']"
        class="ds-input-group__feedback"
      >
        Merci de renseigner l'effectif groupe.
      </div>
    </div>
  </div>

  <!-- Siret de la structure -->
  <div
    id="input-siret"
    class="ds-input-group siret"
    [class.ds-input-group--error]="
      structureForm.get('siret')?.invalid && structureForm.get('siret')?.enabled && structureForm.get('siret')?.touched
    "
  >
    <label for="siret" class="ds-input-group__label"> SIRET<lib-asterisk *ngIf="isSiretMandatory"></lib-asterisk> </label>
    <span class="ds-input-group__input">
      <input
        libSiret
        id="siret"
        name="siret"
        type="text"
        placeholder="000 000 000 0000"
        class="ds-input-group__input"
        formControlName="siret"
      />
    </span>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-siret"
      *ngIf="structureForm.get('siret')?.invalid && structureForm.get('siret')?.enabled && structureForm.get('siret')?.touched"
    >
      <div class="ds-input-group__feedback">
        Merci de bien vouloir renseigner un SIRET valide ou de sélectionner la raison de son absence.
      </div>
    </ng-container>
  </div>

  <!-- Raison sociale -->
  <div
    id="input-raison"
    class="ds-input-group"
    [class.ds-input-group--error]="
      structureForm.get('raisonSocial')?.invalid && structureForm.get('raisonSocial')?.enabled && structureForm.get('raisonSocial')?.touched
    "
  >
    <label for="raison" class="ds-input-group__label">Raison Sociale<lib-asterisk></lib-asterisk></label>
    <span class="ds-input-group__input">
      <input
        id="raison"
        name="raisonSocial"
        type="text"
        maxlength="100"
        placeholder="Votre Raison Sociale"
        class="input_raison ds-input-group__input"
        formControlName="raisonSocial"
      />
    </span>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-raison"
      *ngIf="
        structureForm.get('raisonSocial')?.invalid &&
        structureForm.get('raisonSocial')?.enabled &&
        structureForm.get('raisonSocial')?.touched
      "
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une raison sociale valide</div>
    </ng-container>
  </div>

  <!-- Activité (code naf ou ape) -->
  <div
    *ngIf="isPaysFrance"
    id="input-activite"
    class="ds-input-group"
    [class.ds-input-group--error]="
      structureForm.get('activite')?.invalid && structureForm.get('activite')?.enabled && structureForm.get('activite')?.touched
    "
  >
    <label for="raison" class="ds-input-group__label">
      Activité (code NAF ou APE)<lib-asterisk *ngIf="isActiviteMandatory"></lib-asterisk>
    </label>
    <span class="ds-input-group__input">
      <input
        id="activite"
        name="activite"
        type="text"
        maxlength="5"
        placeholder="Votre code NAF ou APE"
        class="input_activite ds-input-group__input"
        formControlName="activite"
      />
    </span>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-activite"
      *ngIf="structureForm.get('activite')?.invalid && structureForm.get('activite')?.enabled && structureForm.get('activite')?.touched"
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une activite (code NAF ou APE)</div>
    </ng-container>
  </div>

  <lib-structure-adresse [adresseForm]="structureForm.get('adresse')" [isPersonnePhysique]="false" [readOnly]="readOnly">
  </lib-structure-adresse>

  <div id="informations_incorrect_button" *ngIf="readOnly && canRequestRectification">
    <button type="button" class="ds-btn ds-btn--tertiary" (click)="handleRectificationRequest()">Ces informations sont incorrectes</button>
  </div>
</form>
