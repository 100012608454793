<nav>
  <ol class="ds-stepper">
    <li
      *ngFor="let step of steps; index as i"
      class="ds-stepper__item"
      [ngClass]="{
        'ds-stepper__item--inprogress': step.status && isStepInProgress(step.status),
        'ds-stepper__item--validate': step.status && !isStepInProgress(step.status),
      }"
      aria-current="true"
    >
      <a class="ds-stepper__item-title" [ngClass]="{ 'disable-pointer': !step.clickable }" (click)="onStepClicked(i)">
        <span>{{ step.title }}</span>
      </a>
    </li>
  </ol>
</nav>
