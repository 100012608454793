import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AapService } from '@services-candidat/aap.service';
import { EvaluateurService } from '@services-candidat/evaluateur.service';
import { Aap, DateTimeUtils } from '@shared-ui';
import { ProjetListViewService } from '@services-candidat/projet-list-view.service';

@Component({
  selector: 'pxl-projet-list',
  templateUrl: './projet-list.component.html',
  styleUrls: ['./projet-list.component.scss'],
})
export class ProjetListComponent implements OnInit, OnDestroy {
  prochaineReleves = new Map<string, string>();
  today: string;
  subscriptions: Subscription[] = [];
  aaps: Aap[];

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public projetService: ProjetListViewService,
    private aapService: AapService,
    private evaluateurService: EvaluateurService
  ) {
    this.today = DateTimeUtils.format(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.getAaps();
  }

  goToProjetParcours(): void {
    this.router.navigate(['projet-parcours']);
  }

  getAaps(): void {
    this.subscriptions.push(
      this.aapService.getAap().subscribe(response => {
        if (response) {
          this.aaps = response.body;
        }
      })
    );
  }

  /**
   * Redirige vers la page evaluateur
   */
  redirectEvaluateur(): void {
    this.subscriptions.push(this.evaluateurService.goToEvaluateurPage());
  }

  /*
   * Envoi sur la page des informations du projet - projet aperçu
   * */
  goToProjet(projetId: string, isProjetEnSuivi: boolean): void {
    const segment = isProjetEnSuivi ? `suivi-projet/${projetId}` : `projet-creation/${projetId}/projet-apercu`;
    this.router.navigate([segment]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      if (sub?.unsubscribe) {
        sub.unsubscribe();
      }
    });
  }
}
