import { Injectable } from '@angular/core';
import { PeriodeFinanciere } from '@shared-ui';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PrevisionsEconomiquesSharedService {
  private previsionsEconomiques = new BehaviorSubject<PeriodeFinanciere | null>(null);
  private tabActive = new BehaviorSubject<string>('');

  setPrevisionsEconomiques(data: PeriodeFinanciere) {
    this.previsionsEconomiques.next(data);
  }

  getPrevisionsEconomiques() {
    return this.previsionsEconomiques.asObservable();
  }

  setTabActive(tab: string) {
    this.tabActive.next(tab);
  }
  getTabActive() {
    return this.tabActive.asObservable();
  }
}
