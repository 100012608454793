import { Injectable } from '@angular/core';
import { EnumQualifRue } from '../enums/enum.qualifRue';
import { EnumTypeVoie } from '../enums/enum.typeVoie';
import { Structure } from '../models/structure.model';
import { StructureRoleType } from '../types/structureRole.type';

@Injectable({
  providedIn: 'root',
})
export class StructureHelperService {
  static structureHasRole(structure: Structure, roles: StructureRoleType[]): boolean {
    return !!structure && roles && roles.includes(structure?.role?.toString().toUpperCase() as StructureRoleType);
  }

  getContractualisationAdresseSiege(structure: Structure): string {
    const voie = this.getAdresseVoie(structure.adresse?.voie);
    const cp = this.getInfoItem(structure.adresse?.cp);
    const ville = this.getInfoItem(structure.adresse?.ville)?.toUpperCase();
    if (structure.adresse?.codePays === 'FR') {
      const numero = this.getInfoItem(structure.adresse?.numero);
      const complement = this.getInfoItem((EnumQualifRue as any)[structure.adresse?.complement]?.toLowerCase());
      const typeVoie = this.getInfoItem((EnumTypeVoie as any)[structure.adresse?.typeVoie]?.toLowerCase());
      return [numero, complement, typeVoie, voie, cp, ville].filter(val => val).join(' ');
    } else {
      return [voie, cp, ville].join(' ');
    }
  }

  private getInfoItem(infoItem: string | null, defaultValue: string = ''): string {
    return infoItem || defaultValue;
  }

  private getAdresseVoie(value: string): string {
    return value ? value + ',' : '';
  }
}
