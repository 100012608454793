import { Component, DestroyRef, HostListener, inject, OnInit } from '@angular/core';
import {
  Aap,
  DocumentFileManagerService,
  DocumentProjet,
  EnumScanDocument,
  EnumStatutCertificationIban,
  EnumTypeDocument,
  EnumTypePaiement,
  InformationBancaire,
  InformationsBancairesHttpService,
  Projet,
  ShowToastrService,
  Structure,
  StructureValidationFunction,
  Utilisateur,
} from '@shared-ui';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjetService } from '@services-candidat/projet.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import { combineLatest, EMPTY, Observable, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, map } from 'rxjs/operators';
import { AapService } from '@services-candidat/aap.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import { InformationBancaireModalComponent } from '@shared-candidat/components/modals/information-bancaire-modal/information-bancaire-modal.component';

@Component({
  selector: 'pxl-projet-consortium-information-bancaire',
  templateUrl: './projet-consortium-information-bancaire.component.html',
  styleUrls: ['./projet-consortium-information-bancaire.component.scss'],
})
export class ProjetConsortiumInformationBancaireComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  readonly RIB_SUBDIRECTORY = 'rib';

  utilisateur!: Utilisateur;
  aap!: Aap;
  projet!: Projet;
  structure?: Structure;
  structureRibDocuments: DocumentProjet[] = [];
  informationsBancairesDeposant: InformationBancaire[] = [];
  noInformationBancaire = true;
  canUserWrite = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private sharedFunction: SharedFunction,
    private aapService: AapService,
    private projetService: ProjetService,
    private structureService: StructureService,
    private userService: UserService,
    private informationBancaireService: InformationsBancairesHttpService,
    private showToastrService: ShowToastrService,
    public structureValidationFunction: StructureValidationFunction,
    private downloadFileService: DocumentFileManagerService
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }

  ngOnInit(): void {
    combineLatest([this.loadAap(), this.loadUtilisateur(), this.loadProjet(), this.loadStructure()]).subscribe(() => {
      this.setUserPermissions();

      this.reloadInformationsBancaires();
    });
  }

  loadUtilisateur(): Observable<Utilisateur> {
    return this.userService.getUserObservable().pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(user => {
        this.utilisateur = user;
      })
    );
  }

  loadAap(): Observable<Aap> {
    return this.aapService.loadAapSubject().pipe(
      takeUntilDestroyed(this.destroyRef),
      map(response => response?.body),
      tap(aap => {
        this.aap = aap;
      })
    );
  }

  loadProjet(): Observable<Projet> {
    return this.projetService.getProjetObservable().pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(projet => {
        this.projet = projet;
      })
    );
  }

  loadStructure(): Observable<Structure> {
    return this.structureService.getStructureById(this.structureId).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(response => response.body!),
      tap(structure => {
        this.structure = structure;
      })
    );
  }

  reloadInformationsBancaires(): void {
    this.loadInformationBancaireDocuments();
    this.loadInformationsBancaires()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.noInformationBancaire = this.informationsBancairesDeposant.length === 0;
        if (this.noInformationBancaire) {
          this.createInformationBancaire();
        }
      });
  }

  loadInformationsBancaires(): Observable<any> {
    return this.informationBancaireService.getInformationsBancaires(this.structureId).pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError(err => this.handleError(err)),
      map(result => result.body || []),
      tap(fromPxl => {
        this.informationsBancairesDeposant = fromPxl.filter(
          informationBancaire => informationBancaire.soumisParDeposant || informationBancaire.selectionne
        );
      })
    );
  }

  loadInformationBancaireDocuments(): void {
    this.projetService
      .getDocumentsStructure(this.projetId, this.structureId, [EnumTypeDocument.RIB])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(documents => documents.body || [])
      )
      .subscribe(documents => {
        this.structureRibDocuments = documents;
      });
  }

  createInformationBancaire(): void {
    if (this.canUserWrite) {
      const dialogRef = this.matDialog.open(InformationBancaireModalComponent, {
        data: {
          projet: this.projet,
          structure: this.structure,
          utilisateur: this.utilisateur,
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((informationBancaire: InformationBancaire) => {
        if (informationBancaire) {
          this.reloadInformationsBancaires();
        } else if (this.noInformationBancaire) {
          this.onGoToConsortiumInfo();
        }
      });
    }
  }

  editInformationBancaire(informationBancaire: InformationBancaire): void {
    if (this.canUserWrite) {
      const dialogRef = this.matDialog.open(InformationBancaireModalComponent, {
        data: {
          projet: this.projet,
          structure: this.structure,
          utilisateur: this.utilisateur,
          informationBancaire,
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((informationBancaire: InformationBancaire) => {
        if (informationBancaire) {
          this.reloadInformationsBancaires();
        }
      });
    }
  }

  selectInformationBancaire(informationBancaire: InformationBancaire) {
    if (
      this.canUserWrite &&
      !informationBancaire.selectionne &&
      informationBancaire.statutCertification !== EnumStatutCertificationIban.CERTIFICATION_REJETEE_BPI
    ) {
      this.informationBancaireService.selectInformationBancaire(informationBancaire).subscribe(() => {
        this.informationsBancairesDeposant.forEach(info => {
          info.selectionne = false;
        });
        informationBancaire.selectionne = true;
      });
    }
  }

  canEditInformationBancaire(informationBancaire: InformationBancaire): boolean {
    return (
      this.canUserWrite &&
      informationBancaire?.auteur?.matricule === this.utilisateur.matricule &&
      informationBancaire.statutCertification === EnumStatutCertificationIban.NON_CERTIFIE
    );
  }

  onGoToConsortiumInfo(): void {
    this.router.navigate(['projet-creation', this.projetId, 'projet-consortium-info', this.structureId]);
  }

  isValidDocument(document: DocumentProjet): boolean {
    return document?.scan === EnumScanDocument.SAFE;
  }

  isScannedDocument(document: DocumentProjet): boolean {
    return document?.scan === EnumScanDocument.SAFE || document?.scan === EnumScanDocument.UNSAFE;
  }

  isUserDocumentAuthor(document: DocumentProjet): boolean {
    return document.createur === this.utilisateur.matricule;
  }

  downloadDocument(document: DocumentProjet): void {
    if (this.isValidDocument(document) && this.isScannedDocument(document) && this.isUserDocumentAuthor(document)) {
      this.downloadFileService.downloadDocument(document, false).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }
  }

  private setUserPermissions() {
    this.canUserWrite = this.sharedFunction.isStructureUpdatableByUser(this.aap, this.projet, this.structure, this.utilisateur);
  }

  get structureId(): string {
    return this.route.snapshot.params.structureId;
  }

  get projetId(): string {
    return this.route.snapshot.parent?.params.id;
  }

  getDocuments(informationBancaire: InformationBancaire, typeDocument: EnumTypeDocument): DocumentProjet[] {
    return this.structureRibDocuments.filter(
      document => document.informationBancaireId === informationBancaire.id && document.typeDoc === typeDocument
    );
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    this.showToastrService.checkCodeError(err?.error);
    return EMPTY;
  }

  protected readonly EnumStatutCertificationIban = EnumStatutCertificationIban;
  protected readonly EnumTypeDocument = EnumTypeDocument;
  protected readonly EnumTypePaiement = EnumTypePaiement;
}
