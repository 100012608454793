import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface CommentaireModalData {
  title: string;
  description: string;
  fieldInput: string;
  textGoButton: string;
  textReturnButton: string;
  maxLength?: number;
  placeholder?: string;
}

@Component({
  selector: 'lib-pxl-commentaire-modal',
  templateUrl: './commentaire-modal.component.html',
  styleUrls: ['./commentaire-modal.component.scss'],
})
export class CommentaireModalComponent implements OnInit {
  inputForm!: UntypedFormGroup;
  maxLength = 500;

  get formInputField(): UntypedFormControl {
    return this.inputForm.get('inputField') as UntypedFormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<CommentaireModalComponent>,
    @Inject(MAT_DIALOG_DATA) public commentaireModalData: CommentaireModalData,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.inputForm = this.formBuilder.group({
      inputField: [null, [Validators.required]],
    });

    if (this.commentaireModalData.fieldInput != null && typeof this.commentaireModalData.fieldInput === 'string') {
      this.formInputField.setValue(this.commentaireModalData.fieldInput);
    }
    if (this.commentaireModalData.maxLength) {
      this.maxLength = this.commentaireModalData.maxLength;
    }
    this.dialogRef.disableClose = true;
  }

  returnValue(): void {
    if (this.inputForm.valid) {
      const finalValue = this.formInputField.value.trim();
      this.dialogRef.close(finalValue);
    } else {
      this.formInputField.markAsTouched();
    }
  }

  cancelModal(): void {
    if (this.inputForm.dirty) {
      const cn = confirm('Les modifications apportées ne seront peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page ?');
      if (cn) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }
}
