import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import { Aap, Projet, ProjetInfo, ShowToastrService, SubscriptionDestroyerComponent, ToNumberPipe } from '@shared-ui';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-projet-info-budget',
  templateUrl: './projet-info-budget.component.html',
  styleUrls: ['./projet-info-budget.component.scss'],
})
export class ProjetInfoBudgetComponent extends SubscriptionDestroyerComponent implements OnInit {
  disableValidBtn = false;
  isProjectHasBudget = false;
  budgetForm: UntypedFormGroup;
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  projetInfo: ProjetInfo = new ProjetInfo();

  infoAide =
    "Assiette des dépenses éligibles présentées à l'échelle du projet (se référer au cahier des charges correspondant pour connaître les types de dépenses éligibles et les montants d'assiettes minimum et/ou maximum attendus)";
  infoAideDemande =
    "Montant de l'aide à l'échelle du projet que vous souhaitez obtenir dans la limite des taux maximum indiqués dans le cahier des charges correspondant";
  infoAideTotal =
    "Ensemble du budget prévisionnel envisagé à l'échelle du projet, incluant les dépenses éligibles et les dépenses que vous réaliserez pour la bonne conduite de votre projet";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toNumberPipe: ToNumberPipe,
    private projetService: ProjetService,
    private aapService: AapService,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    public sharedFunction: SharedFunction
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForms();
    this.getProjet();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.budgetForm.dirty;
  }

  /*
   * Initialise le formulaire
   * */
  createForms(): void {
    this.budgetForm = this.formBuilder.group({
      montant: [null, Validators.required],
      montantAideDemande: [null, Validators.required],
      budgetTotal: [null],
    });
  }

  /*
   * Modifie le budget d'un projet
   * */
  modifyProjetInfo(): void {
    if (this.sharedFunction.isProjectUpdatable(this.aap, this.projet)) {
      if (this.budgetForm.valid) {
        this.projetInfo.dateModification = this.projet.dateModification;
        this.projetInfo.acronyme = this.projet.acronyme;
        this.projetInfo.description = this.projet.description;
        this.projetInfo.nom = this.projet.nom;
        this.projetInfo.budget = {
          montant: this.budgetForm.value.montant?.replace(/\s/g, ''),
          montantAideDemande: this.budgetForm.value.montantAideDemande?.replace(/\s/g, ''),
          budgetTotal: this.budgetForm.value.budgetTotal?.replace(/\s/g, ''),
        };

        this.projetService
          .upsertInfoGeneProjet(this.projet.id, this.projetInfo)
          .pipe(this.takeUntilDestroyed())
          .subscribe({
            next: (response: any) => {
              if (response) {
                this.budgetForm.reset();
                this.budgetForm.markAsPristine();
                this.showToastrService.success('Projet modifié');
                this.onGoToProjetInfo();
              }
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
      } else {
        this.budgetForm.markAllAsTouched();
      }
    }
  }

  onFocus(): void {
    if (this.budgetForm.value.montant && this.budgetForm.value.montant !== null) {
      this.budgetForm.patchValue({
        montant: this.budgetForm.value.montant.replace(/\s/g, ''),
      });
    }
    if (this.budgetForm.value.montantAideDemande && this.budgetForm.value.montantAideDemande !== null) {
      this.budgetForm.patchValue({
        montantAideDemande: this.budgetForm.value.montantAideDemande.replace(/\s/g, ''),
      });
    }
    if (this.budgetForm.value.budgetTotal && this.budgetForm.value.budgetTotal !== null) {
      this.budgetForm.patchValue({
        budgetTotal: this.budgetForm.value.budgetTotal.replace(/\s/g, ''),
      });
    }
  }

  onBlur(): void {
    if (this.budgetForm.value.montant && this.budgetForm.value.montant !== null) {
      this.budgetForm.patchValue({
        montant: this.toNumberPipe.transform(Math.abs(parseInt(this.budgetForm.value.montant.replace(/\s/g, ''), 10)).toString()),
      });
    }
    if (this.budgetForm.value.montantAideDemande && this.budgetForm.value.montantAideDemande !== null) {
      this.budgetForm.patchValue({
        montantAideDemande: this.toNumberPipe.transform(
          Math.abs(parseInt(this.budgetForm.value.montantAideDemande.replace(/\s/g, ''), 10)).toString()
        ),
      });
    }
    if (this.budgetForm.value.budgetTotal && this.budgetForm.value.budgetTotal !== null) {
      this.budgetForm.patchValue({
        budgetTotal: this.toNumberPipe.transform(Math.abs(parseInt(this.budgetForm.value.budgetTotal.replace(/\s/g, ''), 10)).toString()),
      });
    }
  }

  onkeypress($event: any): boolean {
    if ($event.key) {
      if (/[^\d]/.test($event.key)) {
        return false;
      }
    }
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    const projetId = this.route.snapshot.parent.params.id;
    if (projetId) {
      this.projetService
        .getProjetObservable()
        .pipe(this.takeUntilDestroyed())
        .pipe(
          concatMap(responseProjet => {
            if (responseProjet) {
              this.projet = responseProjet;
              this.budgetForm.patchValue(this.projet.budget);
              this.isProjectHasBudget = !this.checkBudgetInfo();
              return this.aapService.loadAapSubject();
            }
          })
        )
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: responseAap => {
            if (responseAap) {
              this.aap = responseAap.body;
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  /*
   * Vérifie si le budget est present
   * */
  checkBudgetInfo(): boolean {
    return this.projet.budget == null;
  }

  /*
   * Cette méthode permet de retourner à la page d'information projet
   * */
  onGoToProjetInfo(): void {
    this.router.navigate(['../projet-info'], { relativeTo: this.route });
  }
}
