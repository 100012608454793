<div class="border-grid">
  <div class="file-upload-grid">
    <lib-pxl-icon class="icon" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></lib-pxl-icon>
    <div class="infos">
      <span class="grid-title">{{ title }}</span>
    </div>
    <label class="file-upload__custom-input" *ngIf="canUserUpload">
      <input
        type="file"
        class="file-input"
        id="file"
        onclick="this.value=null;"
        (change)="onDocumentFileSelected($event)"
        [disabled]="!canUserUpload"
        accept="{{ extensions }}"
      />
      <input type="hidden" class="file-input" name="fileHidden" />
      <div id="add-fichier" class="ds-btn ds-btn--secondary" [ngClass]="{ disabled: !canUserUpload }">
        <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
        {{ buttonTitle }}
      </div>
    </label>
  </div>
  <div class="documents-container">
    <div class="document-rapport-instruction" *ngFor="let document of documents; let i = index">
      <lib-document-light
        [projetId]="projetId"
        [document]="document"
        [subDirectory]="undefined"
        [canUserWrite]="canUserUpload"
        [hideType]="true"
        [isSiteAdmin]="isSiteAdmin"
        (deleteDocument)="deleteDocument($event, i)"
      >
      </lib-document-light>
    </div>
  </div>
</div>
