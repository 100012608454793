<!-- Equipe-->
<section class="page-section">
  <h3 class="page-section__title">Equipe<span>(Ensemble des membres de l’équipe, dont un responsable projet obligatoire)</span></h3>
  <ng-container *ngIf="!responsableIsValid">
    <div class="card--info margin-botton--12px card--red">
      <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
      Les données du responsable projet déclaré dans les sections contacts projet et équipe doivent être les mêmes.
    </div>
  </ng-container>

  <!-- membres équipe -->
  <ng-container *ngFor="let membre of membresEquipe; index as i">
    <!-- Carte 'equipe' -->
    <div
      id="membre-equipe"
      class="card card--equipe"
      [lang]="!isProjectUpdatable"
      [class.card--inactive]="!isProjectUpdatable || !consortiumAccessGranted"
      (click)="goToMembreEquipe(i)"
    >
      <h4 id="membre-equipe-titre" class="card__title card__title_equipe_overwrite">
        <p class="card__email_overwrite">{{ membre.identite.nom }} {{ membre.identite.prenom }}</p>
        <ng-container *ngIf="membre?.identite?.responsableProjet">
          <div class="card__tag card__tag--blue">Responsable projet</div>
        </ng-container>
        <ng-container *ngIf="roleEquipeExist(membre)">
          <div class="card__tag card__tag--yellow">
            {{ membre.infosProjet.roleEquipe }}
          </div>
        </ng-container>
      </h4>
      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatable && consortiumAccessGranted"
      >
      </lib-pxl-icon>
      <lib-pxl-icon
        class="card_visible visibility_icon clickable-icon"
        icon="icon-visibility"
        aria-hidden="true"
        *ngIf="!consortiumAccessGranted && !isProjectUpdatable"
      >
      </lib-pxl-icon>
    </div>
    <!-- FIN de Carte 'equipe' -->
  </ng-container>

  <!--Ajout membre équipe -->
  <ng-container>
    <div
      id="equipe-supplementaire"
      class="card card--message"
      (click)="addMembreEquipe()"
      [class.card--inactive]="!isProjectUpdatable || !consortiumAccessGranted"
      [class.card--warning]="isMembreMandatory()"
    >
      <h4 *ngIf="isMembreMandatory()" class="card__title">Vous devez ajouter un membre de l'équipe.</h4>

      <h4 *ngIf="isMembreOptional()" class="card__title">Vous pouvez ajouter un membre de l'équipe.</h4>

      <lib-pxl-icon
        class="card__arrow"
        icon="icon-arrow-arrow-forward-ios"
        aria-hidden="true"
        *ngIf="isProjectUpdatable && consortiumAccessGranted"
      >
      </lib-pxl-icon>
    </div>
  </ng-container>
  <!--Fin Ajouter membre équipe-->
</section>
