<!-- Contenu de la page -->
<div class="page-content">
  <section class="page-section" *ngIf="loaded && isStructureUpdatableByUser && !btnProduit">
    <div class="d-flex float-end">
      <div class="link d-flex align-items-center">
        <button
          id="valid-btn"
          (click)="addProduit()"
          class="ds-btn mb-5"
          [ngClass]="dataSource.length !== 0 ? 'ds-btn--secondary' : 'ds-btn--primary'"
        >
          <lib-pxl-icon class="icon mt-2" icon="icon-action-add-produit"></lib-pxl-icon>
          Ajouter un produit
        </button>
      </div>
    </div>
  </section>
</div>

<lib-table-donnees-financieres-et-pe
  [dataArray]="dataSource"
  [columns]="displayedColumns"
  [previsionEconomiqueStatut]="structure?.previsionsEconomiques?.statut"
  [isStructureUpdatableByUser]="isStructureUpdatableByUser"
  [responsive]="true"
  (dataChange)="updateData($event)"
  [title]="title"
  (keyPress)="onKeyPress($event.event)"
  (deleteArray)="deleteProduit($event)"
  (updateName)="updateProduit($event)"
  [btnAction]="true"
></lib-table-donnees-financieres-et-pe>
