import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, tap, forkJoin, map, catchError, of } from 'rxjs';

import { environment } from '@environments-candidat/environment';
import {
  DateTimeUtils,
  EnumFeatureFlipping,
  EnumProjetEtape,
  EnumProjetStatut,
  FeatureFlagService,
  Projet,
  ProjetListViewDto,
  ShowToastrService,
  SuiviProjet,
  getProjectEtapeName,
} from '@shared-ui';

@Injectable()
export class ProjetListViewService {
  projetUrl = environment.apiUrl + 'projets';
  suiviProjetUrl = environment.apiSuiviUrl + 'suivi-projets';
  private readonly combinedProjetsSubject: BehaviorSubject<ProjetListViewDto[]> = new BehaviorSubject<ProjetListViewDto[]>(undefined);
  public readonly combinedProjets$: Observable<ProjetListViewDto[]> = this.combinedProjetsSubject.asObservable();
  private readonly combinedProjetsCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly combinedProjetsCount$: Observable<number> = this.combinedProjetsCountSubject.asObservable();

  constructor(private httpClient: HttpClient, private toastrService: ShowToastrService, private featureFlagService: FeatureFlagService) {}

  getCombinedProjets(): Observable<ProjetListViewDto[]> {
    return forkJoin({
      suiviProjets: this.getSuiviProjets(),
      projets: this.getProjets()
    }).pipe(
      map(({ suiviProjets, projets }) => {
        const suiviProjetIds = new Set(suiviProjets.map(p =>  p.id));
        const filteredMappedProjets = projets.filter(p  => !suiviProjetIds.has(p.id)).map(p => this.mapProjetToListView(p));
        const mappedSuiviProjets = suiviProjets.map(sp => this.mapSuiviProjetToListView(sp));
        const combinedProjects = [...mappedSuiviProjets, ...filteredMappedProjets];

        return combinedProjects
          .sort((a, b) => DateTimeUtils.compareDates(a.dateCreation, b.dateCreation));
      }),
      tap(result => this.combinedProjetsSubject.next(result)),
      tap(result => this.combinedProjetsCountSubject.next(result.length)),
      catchError(this.handleError.bind(this))
    );
  }

  private getProjets(): Observable<Projet[]> {
    return this.httpClient.get<Projet[]>(this.projetUrl, { observe: 'response' })
      .pipe(
        map(response => response.body)
      );
  }

  private getSuiviProjets(): Observable<SuiviProjet[]> {
    if (this.featureFlagService.featureOff(EnumFeatureFlipping.SUIVI_DES_PROJETS)) {
      return of([]);
    }

    return this.httpClient.get<SuiviProjet[]>(this.suiviProjetUrl, { observe: 'response' })
      .pipe(
        map(response => response.body)
      );
  }

  private mapProjetToListView(projet:  Projet): ProjetListViewDto {
    return {
      id: projet.id,
      nom: projet.acronyme || projet.nom,
      nomAap: projet.aap?.nom,
      dateCreation: projet.dateCreation,
      dateProcaineReleveOuEC: this.getProchaineReleve(projet),
      tag: projet.tag,
      isEnSuivi: false,
      etape: EnumProjetEtape.toString(getProjectEtapeName(projet.etapes)),
      statut: EnumProjetStatut.toString(projet.statut, EnumProjetEtape.hasNomFeminin(getProjectEtapeName(projet.etapes))),
      displayStatut: true
    };
  }
  
  private mapSuiviProjetToListView(projet:  SuiviProjet): ProjetListViewDto {    
    return {
      id: projet.id,
      nom: projet.acronyme,
      nomAap: projet.nomAap,
      dateProcaineReleveOuEC: projet.dateEcSuiv ? DateTimeUtils.format(projet.dateEcSuiv) : '-',
      isEnSuivi: true,
      etape: 'Suivi',
      statut: EnumProjetStatut.toString(projet.statut, false),
      displayStatut: projet.statut && projet.statut !== EnumProjetStatut.EC_VALIDEE,
      tooltipText: EnumProjetStatut.toTooltipText(projet.statut, false)
    };
  }

  /*
   * Cette méthode permet de calculer la prochaine relève de chaque projet cad
   * la futur date la plus proche de la date d'aujourd'hui
   *  */
  private getProchaineReleve(projet: Projet): string {
    const today = new Date();

    // classement des dates par ordre croissant
    const datesAutres = projet.aap.dateAutres;
    datesAutres.sort((b, a) => {
      return (new Date(b.valeur) as any) - (new Date(a.valeur) as any);
    });
    if (datesAutres.length !== 0) {
      if (new Date(datesAutres[datesAutres.length - 1].valeur) < today) {
        return DateTimeUtils.format(datesAutres[datesAutres.length - 1].valeur);
      }
      // recupération de la furture date la plus proche
      else {
        for (const date of datesAutres) {
          if (new Date(date.valeur) >= today) {
            return DateTimeUtils.format(date.valeur);
          }
        }
      }
    } else {
      return '-';
    }
  }

  private handleError(err: HttpErrorResponse): Observable<ProjetListViewDto[]> {
    this.toastrService.checkCodeError(err?.error);
    return of([]);
  }
}
