export enum SectionFinanciere_PlanFinancement {
  TOTAL_BESOINS = 'TOTAL DES BESOINS',
  TOTAL_RESSOURCES = 'TOTAL DES RESSOURCES',
  SOLDE_TRESORERIE = 'SOLDE DE TRESORERIE',
  CUMUL_TRESORERIE = 'CUMUL DE TRESORERIE',
}

export namespace SectionFinanciere_NameSpace_PlanFinancement {
  export function toEnum(sfString: string): SectionFinanciere_PlanFinancement | undefined {
    return Object.values(SectionFinanciere_PlanFinancement).find((value: SectionFinanciere_PlanFinancement) => value === sfString);
  }
}

export enum SaisieLabel_PlanFinancement {
  IDP = 'Immobilisation des dépenses du projet',
  ILICRP = 'Investissements liés au lancement industriel et commercial des résultats du projet',
  IC = 'Investissements courants',
  BFRAUG = 'Besoin en fonds de roulement : augmentation (+)',
  BFRDIM = 'Besoin en fonds de roulement : diminution (-)',
  RC = 'Remboursement de crédit',
  DIVERS = 'Divers (dont distribution de dividendes)',
  AC = 'Augmentation de capital',
  ACC = 'Apports en comptes courants',
  CA = "Capacité d'autofinancement",
  EDN = 'Emprunts déjà négociés',
  ERN = 'Emprunts restant à négocier',
  AE = 'Aide envisagée',
}

export const TOOLTIP_BY_ROW_PLAN_FINANCEMENT: Record<string, string> = {
  [SaisieLabel_PlanFinancement.IDP]:
    'Les dépenses du projet peuvent être immobilisées et amorties, ou bien passées pour tout ou partie en charges d’exploitation. Dans l’un ou l’autre cas, il doit en être tenu compte.',
  [SaisieLabel_PlanFinancement.ILICRP]:
    "Investissements matériels (machines de production, ...) et immatériels (promotions, salons, marketing, stocks de démonstration, ...) liés au lancement industriel et commercial des résultats du projet i-Démo, non passés en charges d'exploitation dans le compte de résultats prévisionnel.",
  [SaisieLabel_PlanFinancement.IC]:
    'Tous programmes d’investissements (hors dépenses relevant de l’aide demandée), prévus en terrains, bâtiments, matériels, immobilisations incorporelles.',
  [SaisieLabel_PlanFinancement.BFRAUG]: 'Augmentation du besoin en fonds de roulement lié au cycle d’exploitation',
  [SaisieLabel_PlanFinancement.BFRDIM]: 'Diminution du besoin en fonds de roulement lié au cycle d’exploitation',
  [SectionFinanciere_PlanFinancement.CUMUL_TRESORERIE]:
    'Cumul à réaliser en prenant en compte le solde de trésorerie du dernier bilan produit au dossier.',
};
