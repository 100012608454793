import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { FicheDemandeAideService } from '@services-candidat/fiche-demande-aide.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  Contact,
  DownloadUtils,
  DsMultiselectComponent,
  EnumProjetEtape,
  EnumRoleContact,
  EnumRoleStructure,
  EnumTypeStructure,
  FicheDemandeAide,
  FilterDataInterface,
  Projet,
  ShowToastrService,
  Structure,
  StructureValidationFunction,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { Observable } from 'rxjs';

export interface DataContactsMultiselect {
  matricule: string;
  prenomNom: string;
}

@Component({
  selector: 'app-projet-consortium-fiche-demande-aide',
  templateUrl: './projet-consortium-fiche-demande-aide.component.html',
  styleUrls: ['./projet-consortium-fiche-demande-aide.component.scss'],
})
export class ProjetConsortiumFicheDemandeAideComponent extends SubscriptionDestroyerComponent implements OnInit {
  @ViewChild('contactsComponent') contactsComponent: DsMultiselectComponent;

  structureId: string;
  projetId: string;
  structures: Structure[];
  currentStructure: Structure;
  isStructureCanAddActionnaires = false;
  dataListContacts: FilterDataInterface[] = [];
  utilisateurMatricule: string;
  aap: Aap;
  projet: Projet;

  etapeProjet: EnumProjetEtape;
  ficheDemandeAide: FicheDemandeAide = new FicheDemandeAide();
  ficheDemandeAideForm: FormGroup;
  isDataLoaded = false;

  readonly CREATION_SUCCESS = "La fiche de demande d'aide a bien été créée";
  readonly SAVE_SUCCESS = "Votre fiche de demande d'aide a été enregistrée";
  readonly DOWNLOAD_SUCCESS =
    "Document généré avec succès, merci de l'imprimer, le signer et le charger dans la section Fiche de demande d'aide";
  readonly GENERATE_PDF_ERROR =
    'Certaines données ont été ajoutées récemment et nécessitent d\'être renseignées. Merci de vérifier que les données "Effectif du siège" et "Effectif du groupe" sont renseignées dans la section "Informations du siège / de la structure" ; que les données "Budget présenté" et "Montant d\'aide demandée" sont renseignées dans la partie "Budget" et que la donnée "Fonction" est renseignée dans la section Représentant.e légal.e. Ces données sont obligatoires pour pouvoir générer la fiche de demande d\'aide';

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.ficheDemandeAideForm.dirty;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private structureService: StructureService,
    private userService: UserService,
    private projetService: ProjetService,
    private aapService: AapService,
    private ficheDemandeAideService: FicheDemandeAideService,
    private sharedFunction: SharedFunction,
    public downloadUtils: DownloadUtils,
    public structureValidationFunction: StructureValidationFunction,
    private showToastrService: ShowToastrService,
    private datePipe: DatePipe
  ) {
    super();
    this.projetId = this.route.snapshot.parent.params.id;
    this.structureId = this.route.snapshot.params.structureId;

    this.createForm();
  }

  createForm() {
    this.ficheDemandeAideForm = this.fb.group({
      contactsAutorisesMatricules: new FormControl<string[]>(null, Validators.required),

      correspondantSignatureManuelle: this.fb.group({
        signeeParRepresentantLegal: new FormControl<boolean>(null, Validators.required),
        identite: new FormGroup({
          civilite: new FormControl<string>(null, Validators.required),
          representantLegal: new FormControl<boolean>(null, Validators.required),
          prenom: new FormControl<string>(null, Validators.required),
          nom: new FormControl<string>(null, Validators.required),
          fonction: new FormControl<string>(null, Validators.required),
          email: new FormControl<string>(null, Validators.required),
          telephone: new FormControl<string>(null, Validators.required),
        }),
      }),

      correspondantSignatureElectronique: this.fb.group({
        signatureElectronique: new FormControl<boolean>(null, Validators.required),
        signataireElectroniqueIdentique: new FormControl<boolean>(null, Validators.required),
        identite: new FormGroup({
          civilite: new FormControl<string>('', Validators.required),
          representantLegal: new FormControl<boolean>(null, Validators.required),
          prenom: new FormControl<string>('', Validators.required),
          nom: new FormControl<string>('', Validators.required),
          fonction: new FormControl<string>('', Validators.required),
          email: new FormControl<string>('', Validators.required),
          telephone: new FormControl<string>('', Validators.required),
        }),
      }),

      actionnairesGroup: this.fb.group({
        actionnairesPhysique: this.fb.array([]),
        actionnairesMoral: this.fb.array([]),
      }),

      aidesGroup: this.fb.group({
        obtenuAidesTroisDernieresAnnees: [null, Validators.required],
        autresDemandesAidesDeposees: [null, Validators.required],
        aidesObtenues: this.fb.array([]),
        aidesEnAttente: this.fb.array([]),
      }),
    });
  }

  ngOnInit(): void {
    this.getUtilisateur();
    this.getProjetEtape();
    this.loadAap();
    this.getStructuresAndFicheDemandeAide();
  }

  getStructuresAndFicheDemandeAide(): void {
    this.structureService
      .getStructuresListObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe((listStructures: Structure[]) => {
        if (listStructures) {
          this.structures = listStructures;
          const structuresAllowed = this.structures.filter(structure => {
            return structure.id === this.structureId || structure.role === EnumRoleStructure.MANDATAIRE;
          });
          this.currentStructure = structuresAllowed.find(structure => structure.id === this.structureId);
          if (
            this.currentStructure.typeStructure === EnumTypeStructure.PETITE_ENTREPRISE_NON_COTEE ||
            this.currentStructure.typeStructure === EnumTypeStructure.MOYENNE_ENTREPRISE_NON_COTEE ||
            this.currentStructure.typeStructure === EnumTypeStructure.GRANDE_ENTREPRISE_NON_COTEE
          ) {
            this.isStructureCanAddActionnaires = true;
          }
          this.buildDataListContacts(
            this.extractDataContactsMultiselect(this.structureValidationFunction.getContactsStructuresAap(structuresAllowed))
          );
          this.getFicheDemandeAide();
        }
      });
  }

  loadAap() {
    this.aapService
      .loadAapSubject()
      .pipe(this.takeUntilDestroyed())
      .subscribe((response: HttpResponse<Aap>) => {
        this.aap = response?.body;
      });
  }

  /**
   * Construire la liste des contacts pour le multi-select
   * L'utilisateur est sélectionné par défaut
   */
  buildDataListContacts(contacts: DataContactsMultiselect[]): void {
    contacts.forEach((contact: DataContactsMultiselect) => {
      let isSelected = false;
      if (contact.matricule === this.utilisateurMatricule) {
        isSelected = true;
      }
      this.dataListContacts.push({
        id: contact.matricule,
        value: contact.prenomNom,
        isSelected: isSelected,
      });
    });
  }

  /**
   * Extraire le matricules, le nom et le prénom des contacts actifs d'un projet
   */
  extractDataContactsMultiselect(contacts: Contact[]): DataContactsMultiselect[] {
    return Array.from(
      new Set<DataContactsMultiselect>(
        contacts
          .filter(contact => !(contact.roles.indexOf(EnumRoleContact[EnumRoleContact.REPRESENTANT_LEGAL.toString()]) !== -1))
          .filter(contact => contact.nom !== null && contact.prenom !== null)
          .map(contact => {
            return {
              matricule: contact.matricule,
              prenomNom: contact.prenom + ' ' + contact.nom,
            } as DataContactsMultiselect;
          })
      )
    ).sort((a, b) => {
      return a.prenomNom.localeCompare(b.prenomNom);
    });
  }

  getFicheDemandeAide(): void {
    this.ficheDemandeAideService
      .getFicheDemandeAideByStructureId(this.structureId)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (reponse: HttpResponse<FicheDemandeAide>) => {
          if (reponse) {
            this.ficheDemandeAide = reponse.body;
            this.updateForm();
            this.isDataLoaded = true;
          }
        },

        error: (err: HttpErrorResponse) => {
          this.isDataLoaded = true;
          if (err?.status !== 404) {
            this.showToastrService.checkCodeError(err?.error);
          }
        },
      });
  }

  updateForm(): void {
    this.revertContactsAutorisesMatricules();
    this.ficheDemandeAideForm.patchValue({
      contactsAutorisesMatricules: this.ficheDemandeAide.contactsAutorisesMatricules,

      correspondantSignatureManuelle: {
        signeeParRepresentantLegal: this.ficheDemandeAide.signeeParRepresentantLegal,
        identite: {
          civilite: this.ficheDemandeAide.correspondantSignatureManuelle?.civilite,
          representantLegal: this.ficheDemandeAide.signeeParRepresentantLegal
            ? null
            : this.ficheDemandeAide.correspondantSignatureManuelle?.representantLegal,
          prenom: this.ficheDemandeAide.correspondantSignatureManuelle?.prenom,
          nom: this.ficheDemandeAide.correspondantSignatureManuelle?.nom,
          fonction: this.ficheDemandeAide.correspondantSignatureManuelle?.fonction,
          email: this.ficheDemandeAide.correspondantSignatureManuelle?.email,
          telephone: this.ficheDemandeAide.correspondantSignatureManuelle?.telephone,
        },
      },

      correspondantSignatureElectronique: {
        signatureElectronique: this.ficheDemandeAide.signatureElectronique,
        signataireElectroniqueIdentique: !this.ficheDemandeAide.signatureElectronique
          ? null
          : this.ficheDemandeAide.signataireElectroniqueIdentique,
        identite: {
          civilite: this.ficheDemandeAide.correspondantSignatureElectronique?.civilite,
          representantLegal:
            !this.ficheDemandeAide.signatureElectronique || this.ficheDemandeAide.signataireElectroniqueIdentique
              ? null
              : this.ficheDemandeAide.correspondantSignatureElectronique?.representantLegal,
          prenom: this.ficheDemandeAide.correspondantSignatureElectronique?.prenom,
          nom: this.ficheDemandeAide.correspondantSignatureElectronique?.nom,
          fonction: this.ficheDemandeAide.correspondantSignatureElectronique?.fonction,
          email: this.ficheDemandeAide.correspondantSignatureElectronique?.email,
          telephone: this.ficheDemandeAide.correspondantSignatureElectronique?.telephone,
        },
      },
      aidesGroup: {
        obtenuAidesTroisDernieresAnnees: this.ficheDemandeAide.obtenuAidesTroisDernieresAnnees,
        autresDemandesAidesDeposees: this.ficheDemandeAide.autresDemandesAidesDeposees,
      },
    });
  }

  revertContactsAutorisesMatricules(): void {
    this.dataListContacts.forEach((data: FilterDataInterface) => {
      if (this.checkMatriculeIsAuthorized(data.id as string)) {
        data.isSelected = true;
      }
    });
  }

  checkMatriculeIsAuthorized(matricule: string): boolean {
    return this.ficheDemandeAide?.contactsAutorisesMatricules
      ? Array.from(this.ficheDemandeAide.contactsAutorisesMatricules).includes(matricule)
      : false;
  }

  getUtilisateur(): void {
    this.userService
      .getUserObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe((utilisateur: Utilisateur) => {
        if (utilisateur) {
          this.utilisateurMatricule = utilisateur.matricule;

          const contactsAutorisesControl = this.ficheDemandeAideForm.get('contactsAutorisesMatricules');
          contactsAutorisesControl.addValidators(
            this.structureValidationFunction.utilisateurMatriculeExistedValidator(this.utilisateurMatricule)
          );
          contactsAutorisesControl.setValue([this.utilisateurMatricule]);
        }
      });
  }

  getProjetEtape(): void {
    this.projetService
      .getProjetObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe((projet: Projet) => {
        this.projet = projet;
        this.etapeProjet = this.sharedFunction.getProjectEtapeName(projet);
      });
  }

  validFicheDemandeAide(formResult: any): void {
    this.ficheDemandeAideForm.markAllAsTouched();
    if (this.ficheDemandeAideForm.valid) {
      this.saveAndGenerateFicheDemandeAide(formResult, false);
    }
  }

  public saveAndGenerateFicheDemandeAide(formResult: any, isSaved: boolean): void {
    this.ficheDemandeAide = this.buildFicheToSave(formResult);
    this.ficheDemandeAideService
      .saveFicheDemandeAide(this.structureId, this.ficheDemandeAide)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (response: HttpResponse<FicheDemandeAide>) => {
          this.ficheDemandeAide = response.body;
          this.ficheDemandeAideForm.markAsPristine();
          if (isSaved) {
            this.showToastrService.success(this.ficheDemandeAide.id ? this.SAVE_SUCCESS : this.CREATION_SUCCESS);
          } else {
            this.downloadPdf();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  buildFicheToSave(formResult: any): FicheDemandeAide {
    const newFiche = new FicheDemandeAide();

    if (this.ficheDemandeAide) {
      newFiche.id = this.ficheDemandeAide.id;
    }
    newFiche.projetId = this.projetId;
    newFiche.structureId = this.structureId;

    newFiche.contactsAutorisesMatricules = formResult.contactsAutorisesMatricules;
    newFiche.dateT0 = this.datePipe.transform(formResult.dateT0, 'yyyy-MM-dd');
    newFiche.dateTfinal = this.datePipe.transform(formResult.dateTfinal, 'yyyy-MM-dd');

    newFiche.signeeParRepresentantLegal = formResult.correspondantSignatureManuelle.signeeParRepresentantLegal;
    if (!newFiche.signeeParRepresentantLegal) {
      newFiche.correspondantSignatureManuelle = formResult.correspondantSignatureManuelle.identite;
    }

    newFiche.signatureElectronique = formResult.correspondantSignatureElectronique.signatureElectronique;
    if (newFiche.signatureElectronique) {
      newFiche.signataireElectroniqueIdentique = formResult.correspondantSignatureElectronique.signataireElectroniqueIdentique;
      if (!newFiche.signataireElectroniqueIdentique) {
        newFiche.correspondantSignatureElectronique = formResult.correspondantSignatureElectronique.identite;
      }
    }

    newFiche.actionnaires = formResult.actionnairesGroup?.actionnairesPhysique.concat(formResult.actionnairesGroup?.actionnairesMoral);

    newFiche.aides = (formResult.aidesGroup?.aidesObtenues || []).concat(formResult.aidesGroup?.aidesEnAttente || []);
    newFiche.obtenuAidesTroisDernieresAnnees = formResult.aidesGroup?.obtenuAidesTroisDernieresAnnees;
    newFiche.autresDemandesAidesDeposees = formResult.aidesGroup?.autresDemandesAidesDeposees;

    return newFiche;
  }

  downloadPdf(): void {
    if (
      this.structureValidationFunction.checkStructureBeforeGenerateFicheAide(this.currentStructure, this.projet, this.aap, this.etapeProjet)
    ) {
      this.ficheDemandeAideService
        .generateFicheDemandeAide(this.structureId)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: response => {
            if (response?.body) {
              this.downloadUtils.download(response.body, this.downloadUtils.extractFileNameFromHeaders(response.headers));
              this.showToastrService.success(this.DOWNLOAD_SUCCESS, undefined, {
                timeOut: 13000,
              });
              this.onGoToConsortiumInfo();
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    } else {
      this.showToastrService.error(this.GENERATE_PDF_ERROR, undefined, {
        timeOut: 13000,
      });
    }
  }

  /**
   * Récupérer les valeurs du miltiselect
   */
  selectContacts(): void {
    const contactsControl = this.ficheDemandeAideForm.get('contactsAutorisesMatricules');
    contactsControl.setValue(this.contactsComponent.getSelectedValues().map(contact => contact.id));
    contactsControl.markAsTouched();
  }

  /*
   * Permet de retourner à la page d'information consortium
   * */
  onGoToConsortiumInfo(): void {
    this.router.navigate(['projet-creation', this.projetId, 'projet-consortium-info', this.structureId]);
  }
}
