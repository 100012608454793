export const ROW_HEADER_COLUMN_DEF = 'ROW_HEADER_COLUMN_DEF';

export enum RowType {
  EDITABLE = 'EDITABLE',
  TITLE = 'TITLE',
  RESULT = 'RESULT',
  ISOLATED_EDITABLE = 'ISOLATED_EDITABLE',
}

export class CellNumberValue {
  value: number;
  constructor(input: string | number | undefined) {
    if (!Number.isNaN(Number(input))) {
      this.value = Number(input);
    } else {
      this.value = 0;
    }
  }
}

export interface Cell {
  value: string | number | undefined;
  isEditable: boolean;
  rowDef: string;
  colDef: string;
  sectionLabel: string;
  rowType: RowType;
  tooltip?: string;
}

export class RowBase {
  sectionLabel: string;
  rowType: RowType;
  order: number;
  headerRowLabel: string;
  style?: string;
}

export class Row extends RowBase {
  [key: string]: Cell | string | number | undefined;
}

export type RowAndIndex = {
  index: number;
  row: Row;
};
