import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './components/footer/footer.component';
import { InformationsStructureComponent } from './components/forms/informations-structure/informations-structure.component';
import { HeaderComponent } from './components/header/header.component';
import { InviteStructureModalComponent } from './components/modals/invite-structure-modal/invite-structure-modal.component';
import { SidebarButtonComponent } from './components/sidebar-button/sidebar-button.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { MaterialModule } from './modules/material.module';
import { SharedUiModule } from '@shared-ui';
import { PageWrapperComponent } from './components/page-wrapper/page-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    NgbPopoverModule,
    MatSelectCountryModule,
    SharedUiModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    SpinnerOverlayComponent,
    SidebarButtonComponent,
    PageWrapperComponent,
    InviteStructureModalComponent,
    InformationsStructureComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    HeaderComponent,
    FooterComponent,
    SpinnerOverlayComponent,
    SidebarButtonComponent,
    PageWrapperComponent,
    InformationsStructureComponent,
  ],
})
export class SharedModule {}
