import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DateTimeUtils,
  DonneesFinancieres,
  DonneesFinancieresHelperService,
  DonneesFinancieresHttpService,
  PeriodeFinanciere,
  ShowToastrService,
  SignalService,
  Status,
  Structure,
} from '@shared-ui';
import { Observable } from 'rxjs';

declare const dsLib: any;

@Component({
  selector: 'lib-projet-consortium-donnees-financieres',
  templateUrl: './projet-consortium-donnees-financieres.component.html',
  styleUrls: ['./projet-consortium-donnees-financieres.component.scss'],
})
export class ProjetConsortiumDonneesFinancieresComponent implements OnInit {
  tabs = new dsLib.Tabs('dstab');

  protected comptesResultats: PeriodeFinanciere[] = [];
  protected plansFinancement: PeriodeFinanciere[] = [];
  protected plansTresorerie: PeriodeFinanciere[] = [];
  protected status: Status;
  protected isQuestionEnabled = false;
  protected isOlderThanHeightYears = false;
  protected readonly EUROS_AMOUNTS_MESSAGE = "Les montants sont en milliers d'euros TTC";
  protected readonly OLDER_THAN_HEIGHT_MESSAGE = "Votre entreprise a plus de 8 ans. Le reste de ce tableau n'est pas nécessaire.";

  private structure: Structure | undefined;
  private structureId: string;
  private donneesFinancieres: DonneesFinancieres;

  constructor(
    private service: DonneesFinancieresHelperService,
    private httpService: DonneesFinancieresHttpService,
    private signalService: SignalService,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService
  ) {
    ({ structureId: this.structureId } = this.route.snapshot.params);
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }

  ngOnInit(): void {
    this.structure = this.signalService.getStructureById(this.structureId);
    this.handleCreationDate();
    this.donneesFinancieres = this.service.buildDonneesFinancieres(this.structure?.donneesFinancieres);
    ({ statut: this.status = 'VALIDE' } = this.donneesFinancieres);
    this.comptesResultats = this.donneesFinancieres.comptesResultats || [];
    this.plansFinancement = this.donneesFinancieres.planFinancements || [];
    this.plansTresorerie = this.donneesFinancieres.planTresoreries || [];
  }

  private handleCreationDate(): void {
    const organisationCreationDate: string | undefined = this.structure?.informationsReferentiel?.organisationCreationDate || '';
    const creationDate: Date | undefined = DateTimeUtils.stringToDate(organisationCreationDate);
    if (creationDate) {
      // S'il y a une date de création, on désactive la question pour vérifier si la structure a plus de 8 ans
      this.isQuestionEnabled = false;
      const now = new Date();
      const delta: number = DateTimeUtils.calculateNbOfYearsBetweenToDate(creationDate, now);
      this.isOlderThanHeightYears = delta > 8;
    } else {
      // S'il n'y a pas de date de création, on active la question
      this.isQuestionEnabled = true;
    }
  }

  protected onSave() {
    this.saveData();
  }

  private saveData() {
    // Récupération de toutes les données des onglets
    const comptesResultats: PeriodeFinanciere[] = this.signalService.getComptesResultats();
    const plansFinancement: PeriodeFinanciere[] = this.signalService.getPlanFinancement();
    const plansTresorerie: PeriodeFinanciere[] = this.signalService.getPlansTresorerie();
    this.donneesFinancieres = {
      ...this.donneesFinancieres,
      comptesResultats,
      planFinancements: plansFinancement,
      planTresoreries: plansTresorerie,
    };
    if (this.structure) {
      this.structure = {
        ...this.structure,
        donneesFinancieres: this.donneesFinancieres,
      };
    }
    this.httpService.putStructureDonneesFinancieres(this.structureId, this.donneesFinancieres).subscribe({
      next: (response: Structure) => {
        this.showToastrService.success('Les éléments financiers de la structure ont bien été modifiés');
        this.signalService.setStructure(response);
      },
      error: (err: HttpErrorResponse) => this.showToastrService.checkCodeError(err?.error),
    });
  }

  protected onGoToConsortiumInfo(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
