import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActionnaireDemandeAide, EnumTypeActionnaire, FicheDemandeAide } from '@shared-ui';

@Component({
  selector: 'lib-fiche-demande-aide-actionnaires',
  templateUrl: './fiche-demande-aide-actionnaires.component.html',
  styleUrls: ['./fiche-demande-aide-actionnaires.component.scss'],
})
export class FicheDemandeAideActionnairesComponent implements OnInit {
  @Input() ficheDemandeAideActionnairesForm: FormGroup;
  @Input() ficheDemandeAide: FicheDemandeAide;

  selectionPhysique = new SelectionModel<number>(true, []);
  selectionMoral = new SelectionModel<number>(true, []);

  displayedColumnsActionnairesPhysique: string[] = ['select', 'prenom', 'nom', 'pays', 'pourcentageDetention'];
  dataSourceActionnairesPhysique = new MatTableDataSource<ActionnaireDemandeAide>();
  dataSourceActionnairesPhysiqueArray: ActionnaireDemandeAide[] = [];

  displayedColumnsActionnairesMoral: string[] = ['select', 'raisonSociale', 'siren', 'pays', 'pourcentageDetention'];
  dataSourceActionnairesMoral = new MatTableDataSource<ActionnaireDemandeAide>();
  dataSourceActionnairesMoralArray: ActionnaireDemandeAide[] = [];

  totalPourcentageDetention = 0;

  DIGITS_NUMBER = 2;

  dataSourcePourcentageCalcul = new MatTableDataSource<ActionnaireDemandeAide>();

  get actionnairesPhysiqueFormArray(): FormArray {
    return this.ficheDemandeAideActionnairesForm.get('actionnairesPhysique') as FormArray;
  }

  get actionnairesMoralFormArray(): FormArray {
    return this.ficheDemandeAideActionnairesForm.get('actionnairesMoral') as FormArray;
  }

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    const actionnairesPhysique = this.ficheDemandeAide.actionnaires?.filter(actionnaire => actionnaire.type === 'PHYSIQUE');
    const actionnairesMoral = this.ficheDemandeAide.actionnaires?.filter(actionnaire => actionnaire.type === 'MORAL');

    actionnairesPhysique?.forEach(actionnaire => {
      (this.ficheDemandeAideActionnairesForm.get('actionnairesPhysique') as FormArray).push(
        this.createFormGroup(EnumTypeActionnaire.PHYSIQUE, actionnaire)
      );
    });

    actionnairesMoral?.forEach(actionnaire => {
      (this.ficheDemandeAideActionnairesForm.get('actionnairesMoral') as FormArray).push(
        this.createFormGroup(EnumTypeActionnaire.MORAL, actionnaire)
      );
    });

    if ((this.ficheDemandeAideActionnairesForm.get('actionnairesPhysique') as FormArray).length === 0) {
      this.addActionnairePhysique();
    }

    if ((this.ficheDemandeAideActionnairesForm.get('actionnairesMoral') as FormArray).length === 0) {
      this.addActionnaireMoral();
    }

    this.checkValidators();
    this.updateTable();
  }

  updateTable(): void {
    this.dataSourceActionnairesPhysiqueArray = this.ficheDemandeAideActionnairesForm.get('actionnairesPhysique')?.value;

    this.dataSourceActionnairesPhysique = new MatTableDataSource<ActionnaireDemandeAide>(this.dataSourceActionnairesPhysiqueArray);

    this.dataSourceActionnairesMoralArray = this.ficheDemandeAideActionnairesForm.get('actionnairesMoral')?.value;

    this.dataSourceActionnairesMoral = new MatTableDataSource<ActionnaireDemandeAide>(this.dataSourceActionnairesMoralArray);
  }

  addActionnairePhysique(): void {
    const actionnairePhysiqueArray = this.ficheDemandeAideActionnairesForm.get('actionnairesPhysique') as FormArray;

    actionnairePhysiqueArray.push(this.createFormGroup(EnumTypeActionnaire.PHYSIQUE));

    this.updateTable();
  }

  addActionnaireMoral(): void {
    const actionnaireMoralArray = this.ficheDemandeAideActionnairesForm.get('actionnairesMoral') as FormArray;

    actionnaireMoralArray.push(this.createFormGroup(EnumTypeActionnaire.MORAL));

    this.updateTable();
  }

  removeActionnairePhysique(): void {
    const actionnairePhysiqueArray = this.ficheDemandeAideActionnairesForm.get('actionnairesPhysique') as FormArray;

    if (this.selectionPhysique.selected.length > 0) {
      this.selectionPhysique.selected.forEach((selectedindex, index) => {
        actionnairePhysiqueArray.removeAt(selectedindex - index);
      });
      this.selectionPhysique.clear();
    } else if (actionnairePhysiqueArray.length > 0) {
      actionnairePhysiqueArray.removeAt(actionnairePhysiqueArray.length - 1);
    }

    if (actionnairePhysiqueArray.length === 0) {
      this.addActionnairePhysique();
      this.checkValidators();
    }

    this.updateTable();
  }

  removeActionnaireMoral(): void {
    const actionnaireMoralArray = this.ficheDemandeAideActionnairesForm.get('actionnairesMoral') as FormArray;

    if (this.selectionMoral.selected.length > 0) {
      this.selectionMoral.selected.forEach((selectedindex, index) => {
        actionnaireMoralArray.removeAt(selectedindex - index);
      });
      this.selectionMoral.clear();
    } else if (actionnaireMoralArray.length > 0) {
      actionnaireMoralArray.removeAt(actionnaireMoralArray.length - 1);
    }

    if (actionnaireMoralArray.length === 0) {
      this.addActionnaireMoral();
      this.checkValidators();
    }

    this.updateTable();
  }

  isAllSelectedPhysique(): boolean {
    const numSelected = this.selectionPhysique.selected.length;
    const numRows = this.dataSourceActionnairesPhysique.data.length;
    return numSelected === numRows;
  }

  masterTogglePhysique(): void {
    this.isAllSelectedPhysique()
      ? this.selectionPhysique.clear()
      : this.dataSourceActionnairesPhysique.data.forEach((_row, index) => this.selectionPhysique.select(index));
  }

  isAllSelectedMoral(): boolean {
    const numSelected = this.selectionMoral.selected.length;
    const numRows = this.dataSourceActionnairesMoral.data.length;
    return numSelected === numRows;
  }

  masterToggleMoral(): void {
    this.isAllSelectedMoral()
      ? this.selectionMoral.clear()
      : this.dataSourceActionnairesMoral.data.forEach((_row, index) => this.selectionMoral.select(index));
  }

  onkeypress($event: KeyboardEvent): boolean {
    if ($event.key) {
      if (/^[0-9,]+$/.test($event.key)) {
        return true;
      }
    }
    return false;
  }

  calculPourcentageDetention(): number {
    let totalPourcentageDetentionActionnairePhysique = 0;
    let totalPourcentageDetentionActionnaireMoral = 0;
    const actionnaireMoralArray = this.ficheDemandeAideActionnairesForm.get('actionnairesMoral') as FormArray;
    const actionnairePhysiqueArray = this.ficheDemandeAideActionnairesForm.get('actionnairesPhysique') as FormArray;

    actionnairePhysiqueArray.controls.forEach((control: AbstractControl) => {
      totalPourcentageDetentionActionnairePhysique += isNaN(parseFloat(control.get('pourcentageDetention')?.value))
        ? 0
        : parseFloat(control.get('pourcentageDetention')?.value);
    });

    actionnaireMoralArray.controls.forEach((control: AbstractControl) => {
      totalPourcentageDetentionActionnaireMoral += isNaN(parseFloat(control.get('pourcentageDetention')?.value))
        ? 0
        : parseFloat(control.get('pourcentageDetention')?.value);
    });

    this.totalPourcentageDetention = totalPourcentageDetentionActionnaireMoral + totalPourcentageDetentionActionnairePhysique;
    if (this.totalPourcentageDetention > 100) {
      this.ficheDemandeAideActionnairesForm.setErrors({ invalidPercentage: true });
    } else {
      this.ficheDemandeAideActionnairesForm.setErrors(null);
    }

    return this.totalPourcentageDetention;
  }

  createFormGroup(type: EnumTypeActionnaire, actionnaire?: ActionnaireDemandeAide): FormGroup {
    const formGroup = new FormGroup({
      prenom: new FormControl(actionnaire?.prenom || ''),
      nom: new FormControl(actionnaire?.nom || ''),
      raisonSociale: new FormControl(actionnaire?.raisonSociale || ''),
      siren: new FormControl(actionnaire?.siren || ''),
      pays: new FormControl(actionnaire?.pays || '', Validators.required),
      pourcentageDetention: new FormControl(actionnaire?.pourcentageDetention || '', Validators.required),
      type: new FormControl(type, Validators.required),
    });

    if (type === EnumTypeActionnaire.MORAL) {
      formGroup.get('raisonSociale')?.setValidators([Validators.required]);
      formGroup.get('siren')?.setValidators([Validators.required]);
    }
    if (type === EnumTypeActionnaire.PHYSIQUE) {
      formGroup.get('prenom')?.setValidators([Validators.required]);
      formGroup.get('nom')?.setValidators([Validators.required]);
    }

    return formGroup;
  }

  checkValidators(event?: Event, type?: string, index?: any): void {
    let totalPourcentageDetentionActionnairePhysique = 0;
    let totalPourcentageDetentionActionnaireMoral = 0;

    const actionnaireMoralArray = this.ficheDemandeAideActionnairesForm.get('actionnairesMoral') as FormArray;
    const actionnairePhysiqueArray = this.ficheDemandeAideActionnairesForm.get('actionnairesPhysique') as FormArray;

    if (type === 'physique') {
      totalPourcentageDetentionActionnairePhysique = parseFloat((event?.target as HTMLInputElement)?.value);
      totalPourcentageDetentionActionnairePhysique -= actionnairePhysiqueArray.controls[index]?.get('pourcentageDetention')?.value || 0;
    }
    if (type === 'moral') {
      totalPourcentageDetentionActionnaireMoral = parseFloat((event?.target as HTMLInputElement)?.value);
      totalPourcentageDetentionActionnaireMoral -= actionnaireMoralArray.controls[index]?.get('pourcentageDetention')?.value || 0;
    }

    actionnairePhysiqueArray.controls.forEach((control: AbstractControl) => {
      control.get('prenom')?.setValidators([Validators.required]);
      control.get('nom')?.setValidators([Validators.required]);
      control.get('pays')?.setValidators([Validators.required]);
      control.get('pourcentageDetention')?.setValidators([Validators.required]);
      control.get('prenom')?.updateValueAndValidity();
      control.get('nom')?.updateValueAndValidity();
      control.get('pays')?.updateValueAndValidity();
      control.get('pourcentageDetention')?.updateValueAndValidity();
      totalPourcentageDetentionActionnairePhysique += isNaN(parseFloat(control.get('pourcentageDetention')?.value))
        ? 0
        : parseFloat(control.get('pourcentageDetention')?.value);
    });

    actionnaireMoralArray.controls.forEach((control: AbstractControl) => {
      control.get('raisonSociale')?.setValidators([Validators.required]);
      control.get('siren')?.setValidators([Validators.required]);
      control.get('pays')?.setValidators([Validators.required]);
      control.get('pourcentageDetention')?.setValidators([Validators.required]);
      control.get('raisonSociale')?.updateValueAndValidity();
      control.get('siren')?.updateValueAndValidity();
      control.get('pays')?.updateValueAndValidity();
      control.get('pourcentageDetention')?.updateValueAndValidity();
      totalPourcentageDetentionActionnaireMoral += isNaN(parseFloat(control.get('pourcentageDetention')?.value))
        ? 0
        : parseFloat(control.get('pourcentageDetention')?.value);
    });

    if (totalPourcentageDetentionActionnaireMoral === 100) {
      actionnairePhysiqueArray.controls.forEach((control: AbstractControl) => {
        control.get('prenom')?.setValidators(null);
        control.get('nom')?.setValidators(null);
        control.get('pays')?.setValidators(null);
        control.get('pourcentageDetention')?.setValidators(null);
        control.get('prenom')?.updateValueAndValidity();
        control.get('nom')?.updateValueAndValidity();
        control.get('pays')?.updateValueAndValidity();
        control.get('pourcentageDetention')?.updateValueAndValidity();
      });
    }

    if (totalPourcentageDetentionActionnairePhysique === 100) {
      actionnaireMoralArray.controls.forEach((control: AbstractControl) => {
        control.get('raisonSociale')?.setValidators(null);
        control.get('siren')?.setValidators(null);
        control.get('pays')?.setValidators(null);
        control.get('pourcentageDetention')?.setValidators(null);
        control.get('raisonSociale')?.updateValueAndValidity();
        control.get('siren')?.updateValueAndValidity();
        control.get('pays')?.updateValueAndValidity();
        control.get('pourcentageDetention')?.updateValueAndValidity();
      });
    }

    this.changeDetectorRef.detectChanges();
  }
}
