import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ProjetListComponent } from '@features-candidat/projet-list/projet-list.component';
import { ProjetParcoursComponent } from '@features-candidat/projet-parcours/projet-parcours.component';
import { AccueilComponent } from '@features-candidat/accueil/accueil.component';
import { AuthorizationGuard } from './core/authentification.guard';
import { AutoLoginComponent } from './core/auth-login.component';
import { ProjetRejoindreComponent } from '@features-candidat/projet-rejoindre/projet-rejoindre.component';
import { EspaceSelectionComponent } from '@features-candidat/espace/espace-selection.component';
import { ProjetRejoindrePartenaireComponent } from '@features-candidat/projet-rejoindre-partenaire/projet-rejoindre-partenaire.component';
import { InterneGuard } from './core/interne.guard';
import { InterneComponent } from '@features-candidat/interne/interne.component';
import { NoAuthGuard } from '@core-candidat/no-authentification.guard';
import { utilisateurResolver } from '@features-candidat/suivi-projet/service/utilisateur.resolver';
import { suiviProjetResolver } from '@features-candidat/suivi-projet/service/suivi-projet.resolver';
import { projetListViewResolver } from './resolvers/projet-list-view.resolver';

export const AppRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'accueil',
        pathMatch: 'full',
      },
      {
        path: 'accueil',
        component: AccueilComponent,
        canActivate: [NoAuthGuard],
        data: { title: 'Accueil - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'autologin',
        component: AutoLoginComponent,
      },
      {
        path: 'espace',
        component: EspaceSelectionComponent,
        canActivate: [AuthorizationGuard],
        data: { title: 'Choisir mon espace - Picxel | Extranet des Projets Innovants Collaboratifs' },
        resolve: {
          combinedProjets: projetListViewResolver
        }
      },
      {
        path: 'projets',
        component: ProjetListComponent,
        canActivate: [AuthorizationGuard, InterneGuard],
        data: { title: 'Dashboard projets - Picxel | Extranet des Projets Innovants Collaboratifs' },
        resolve: {
          combinedProjets: projetListViewResolver
        }
      },
      {
        path: 'rejoindre-projet',
        component: ProjetRejoindreComponent,
        canActivate: [AuthorizationGuard],
        data: { title: 'Rejoindre projet - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'rejoindre-projet-partenaire',
        component: ProjetRejoindrePartenaireComponent,
        canActivate: [AuthorizationGuard],
        data: { title: 'Rejoindre projet - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-parcours',
        component: ProjetParcoursComponent,
        canActivate: [AuthorizationGuard],
        data: { title: 'Pré-parcours - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
      {
        path: 'projet-creation/:id',
        canActivate: [AuthorizationGuard],
        loadChildren: async () => (await import('./features/projet-creation/projet-creation.module')).ProjetCreationModule,
      },
      {
        path: 'suivi-projet/:id',
        canActivate: [AuthorizationGuard],
        loadChildren: async () => (await import('./features/suivi-projet/suivi-projet.module')).ProjetSuiviModule,
        resolve: {
          suiviProjet: suiviProjetResolver,
          utilisateur: utilisateurResolver,
        },
      },
      {
        path: 'interne',
        component: InterneComponent,
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'rejoindre-projet-evaluateur',
        component: ProjetRejoindreComponent,
        canActivate: [AuthorizationGuard],
        data: { title: 'Rejoindre projet - Picxel | Extranet des Projets Innovants Collaboratifs' },
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
