<!-- Entête de la page -->
<header id="header-contact" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToConsortiumInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Ma structure
  </a>
  <h2 id="title-new-signataire" class="page-header__title" *ngIf="!isEdit">Nouveau signataire</h2>
  <h2 id="title-contact-name" class="page-header__title" *ngIf="isEdit">{{ signataire?.prenom }} {{ signataire?.nom }}</h2>
</header>

<!-- Contenu de la page -->
<div class="page-content">
  <!-- Message d'information read only -->
  <div class="card--info card--blue card--readonly" *ngIf="signataire?.isFromFicheDemandeAide || !isStructureUpdatableByUser">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    Vous êtes en lecture seule sur cette page. Vous ne pouvez pas éditer son contenu.
  </div>
  <!-- Message d'information signataire procedure en cours -->
  <div class="card--info card--blue card--readonly" *ngIf="isSignataireSelectedInOngoingProcedure">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    Pour modifier les données d'un signataire, veuillez au préalable annuler la procédure et le désélectionner de la liste des signataires
    de la structure.
  </div>
  <lib-signataire-form
    *ngIf="(isEdit && signataire && isSignataireSelectedInOngoingProcedure !== undefined) || !isEdit"
    #signataireFormComponent
    (canceled)="onCancel()"
    (formSubmitted)="onSubmit($event)"
    [signataire]="signataire"
    [canWrite]="isStructureUpdatableByUser && !isSignataireSelectedInOngoingProcedure"
  ></lib-signataire-form>
</div>
