import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { SharedUiModule } from '@shared-ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ProjetConsortiumPeEffectifModule } from './projet-consortium-pe-effectif/projet-consortium-pe-effectif.module';
import { ProjetConsortiumPeProduitModule } from './projet-consortium-pe-produit/projet-consortium-pe-produit.module';
import { ProjetConsortiumPrevisionsEconomiquesComponent } from './projet-consortium-previsions-economiques.component';

@NgModule({
  declarations: [ProjetConsortiumPrevisionsEconomiquesComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ProjetConsortiumPeEffectifModule,
    ProjetConsortiumPeProduitModule,
    SharedModule,
    SharedUiModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true,
    }),
  ],
  exports: [ProjetConsortiumPrevisionsEconomiquesComponent],
})
export class ProjetConsortiumPrevisionsEconomiquesModule {}
