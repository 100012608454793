import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpRequestInterceptor } from '@core-candidat/interceptor';
import { environment } from '@environments-candidat/environment';
import { AccueilModule } from '@features-candidat/accueil/accueil.module';
import { EspaceModule } from '@features-candidat/espace/espace.module';
import { ProjetConsortiumPrevisionsEconomiquesModule } from '@features-candidat/projet-creation/component/projet-consortium-previsions-economiques/projet-consortium-previsions-economiques.module';
import { ProjetCreationModule } from '@features-candidat/projet-creation/projet-creation.module';
import { ProjetListModule } from '@features-candidat/projet-list/projet-list.module';
import { ProjetParcoursModule } from '@features-candidat/projet-parcours/projet-parcours.module';
import { ProjetRejoindrePartenaireModule } from '@features-candidat/projet-rejoindre-partenaire/projet-rejoindre-partenaire.module';
import { ProjetRejoindreModule } from '@features-candidat/projet-rejoindre/projet-rejoindre.module';
import { SuiviProjetService } from '@features-candidat/suivi-projet/service/suivi-projet.service';
import { AapService } from '@services-candidat/aap.service';
import { DemandeDeDocumentComplementaireService } from '@services-candidat/demandeDeDocumentComplementaire.service';
import { DepensesService } from '@services-candidat/depenses.service';
import { EvaluateurService } from '@services-candidat/evaluateur.service';
import { FicheCommunicationService } from '@services-candidat/fiche-communication.service';
import { FicheDemandeAideService } from '@services-candidat/fiche-demande-aide.service';
import { PoleService } from '@services-candidat/pole.service';
import { ProjetService } from '@services-candidat/projet.service';
import { PxlOidcService } from '@services-candidat/pxl-oidc.service';
import { StructureService } from '@services-candidat/structure.service';
import { UploadDocumentService } from '@services-candidat/upload-document.service';
import { UserService } from '@services-candidat/user.service';
import { CguModalModule } from '@shared-candidat/components/modals/cgu-modal/cgu-modal.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  FeatureFlagService,
  IbanPipe,
  PrevisionsEconomiquesSharedService,
  ProjetValidationFunction,
  ShowToastrService,
  StructureValidationFunction,
  ToNumberPipe,
} from '@shared-ui';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { AppConfig } from '../app.config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AutoLoginComponent } from './core/auth-login.component';
import { AuthConfigModule } from './core/core.module';
import { CanDeactivateGuard } from './guards/can-deactivate-guard';
import { SidebarStateService } from './services/sidebar-state.service';
import { ContractualisationService } from '@services-candidat/contractualisation.service';

registerLocaleData(localeFr);

function initializeApp(appConfig: AppConfig): any {
  return () => appConfig.load();
}

@NgModule({
  declarations: [AppComponent, AutoLoginComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    ProjetListModule,
    EspaceModule,
    ProjetParcoursModule,
    ProjetCreationModule,
    ProjetRejoindreModule,
    ProjetRejoindrePartenaireModule,
    AccueilModule,
    BrowserAnimationsModule,
    SharedModule,
    AuthConfigModule,
    CguModalModule,
    ProjetConsortiumPrevisionsEconomiquesModule,
    ToastrModule.forRoot(),
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    CanDeactivateGuard,
    AppConfig,
    { provide: 'appConfig', useExisting: AppConfig },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    FeatureFlagService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    UserService,
    UploadDocumentService,
    AapService,
    ProjetService,
    ProjetValidationFunction,
    StructureService,
    DatePipe,
    ToNumberPipe,
    IbanPipe,
    ShowToastrService,
    SharedFunction,
    StructureValidationFunction,
    SidebarStateService,
    NgxPermissionsService,
    PoleService,
    EvaluateurService,
    DemandeDeDocumentComplementaireService,
    DepensesService,
    FicheCommunicationService,
    FicheDemandeAideService,
    PxlOidcService,
    PrevisionsEconomiquesSharedService,
    { provide: 'environment', useValue: environment },
    SuiviProjetService,
    ContractualisationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
