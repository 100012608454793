import { Thematique } from '@shared-ui';

export class ProjetUpdateThematiqueModel {
  thematique: Thematique;
  dateModification: Date;
  constructor(thematique: Thematique, dateModification: Date) {
    this.thematique = thematique;
    this.dateModification = dateModification;
  }
}
