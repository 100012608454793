import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  ConfirmModalComponent,
  EnumValidation,
  PrevisionsEconomiques,
  Projet,
  ShowToastrService,
  Structure,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { combineLatest, Observable } from 'rxjs';
import { PrevisionEconomiqueHelperService as Helper } from './prevision-economique.helper.service';
import { AapService } from '@services-candidat/aap.service';

declare const dsLib: any;

@Component({
  selector: 'app-projet-consortium-previsions-economiques',
  templateUrl: './projet-consortium-previsions-economiques.component.html',
  styleUrls: ['./projet-consortium-previsions-economiques.component.scss'],
})
export class ProjetConsortiumPrevisionsEconomiquesComponent extends SubscriptionDestroyerComponent implements OnInit {
  projet: Projet | undefined;
  structure: Structure;
  projetId: string;
  structureId: string;
  user: Utilisateur;
  aap: Aap | undefined;

  tabs = new dsLib.Tabs('dstab');
  currentTab = 'produits';
  dfEtPeForm: UntypedFormGroup;
  previsionEconomique: PrevisionsEconomiques;

  infoMessage = "Les montants sont en milliers d'euros TTC";
  isStructureUpdatableByUser = false;
  btnProduit = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projetService: ProjetService,
    private showToastrService: ShowToastrService,
    public sharedFunction: SharedFunction,
    private structureService: StructureService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    public matDialog: MatDialog,
    private aapService: AapService
  ) {
    super();
    this.projetId = this.route.snapshot.parent.params.id;
    this.structureId = this.route.snapshot.params.structureId;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }

  ngOnInit(): void {
    this.getProjet();
    this.selectTab('produits');
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    if (!this.projetId) {
      return;
    }

    combineLatest([
      this.structureService.getStructureById(this.structureId),
      this.userService.getUserObservable(),
      this.projetService.getProjetObservable(),
      this.aapService.loadAapSubject(),
    ])
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: ([structure, user, responseProjet, responseAap]) => {
          if (responseProjet) {
            this.projet = responseProjet;
            this.user = user;
          }
          this.structure = structure?.body;
          this.aap = responseAap.body;

          this.createForms(this.structure);
          this.dfEtPeForm.get('previsionEconomique').patchValue(Helper.getStructureForm(this.structure));

          this.isStructureUpdatableByUser = this.sharedFunction.isStructureUpdatableByUser(
            this.aap,
            this.projet,
            this.structure,
            this.user
          );
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  createForms(structure: Structure): void {
    this.dfEtPeForm = this.formBuilder.group({
      previsionEconomique: Helper.getStructureForm(structure),
    });
  }

  /*
   * Permet de retourner à la page d'information consortium
   * */
  onGoToConsortiumInfo(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  selectTab(value: string) {
    this.currentTab = value;
  }

  cancelBtn(): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Annulation',
        description: `<p>Valider cette action conduira à la perte de toutes les informations.</p>`,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onGoToConsortiumInfo();
      }
    });
  }

  validBtn(value: EnumValidation, modalValidation: boolean): void {
    if (modalValidation) {
      const dialogRef = this.matDialog.open(ConfirmModalComponent, {
        data: {
          title: String(value) === EnumValidation[EnumValidation.VALIDE] ? 'Validation' : 'Modifier',
          description:
            String(value) === EnumValidation[EnumValidation.VALIDE]
              ? `<p>Vous êtes sur le point de valider les données existantes. Cette action nécessite une validation de votre part. Souhaitez-vous continuer?</p>`
              : `<p>Vous êtes sur le point de modifier les données existantes. Cette action nécessite une validation de votre part, et toute modification sera définitive. Souhaitez-vous continuer?</p>`,
          textGoButton: 'Confirmer',
          textReturnButton: 'Annuler',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.save(value);
        }
      });
    } else {
      this.save(value);
    }
  }

  save(value: EnumValidation): void {
    this.previsionEconomique = Helper.getPrevisionEconomiqueDTO(this.dfEtPeForm.value.previsionEconomique, value);
    this.btnProduit = String(value) === EnumValidation[EnumValidation.VALIDE];

    this.structureService.updatePrevisionsEconomiques(this.structureId, this.previsionEconomique).subscribe({
      next: () => {
        this.structure.previsionsEconomiques = this.previsionEconomique;
        this.showToastrService.success('Les modifications ont bien été enregistrées');
      },
      error: (err: HttpErrorResponse) => {
        this.handleHttpRequestError(err);
      },
    });
  }

  private handleHttpRequestError(err: HttpErrorResponse) {
    if (err.statusText === 'Unknown Error') {
      this.showToastrService.error("Une erreur s'est produite lors de l'opération. Veuillez réessayer ultérieurement");
    } else {
      this.showToastrService.checkCodeError(err?.error);
    }
  }
}
