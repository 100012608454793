import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  Adresse,
  EnumMotifNonRattachement,
  EnumQualifRue,
  EnumRoleStructure,
  LieuRD,
  Projet,
  ShowToastrService,
  siretValidator,
  Structure,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-projet-consortium-adresse-rd',
  templateUrl: './projet-consortium-adresse-rd.component.html',
  styleUrls: ['./projet-consortium-adresse-rd.component.scss'],
})
export class ProjetConsortiumAdresseRDComponent extends SubscriptionDestroyerComponent implements OnInit {
  changeStructureInfoFormStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  updatedStructureInfoForm$: BehaviorSubject<Partial<Structure>> = new BehaviorSubject(null);
  structureForm: UntypedFormGroup;
  roles = EnumRoleStructure;
  qualifRue = EnumQualifRue;
  disableValidBtn = false;
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  structure: Structure = new Structure();
  structures: Structure[];
  structureId: string;
  projetId: string;
  lengthSiret = 17;
  utilisateur: Utilisateur;

  roleSelectable = true;
  structureInfoFormDirty = false;

  structureEnCreation = false;
  formSubmited = false;
  isAdresseRdSameAsAdresseStructure = false;
  isStructureUpdatableByUser: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private projetService: ProjetService,
    private structureService: StructureService,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    public sharedFunction: SharedFunction,
    private aapService: AapService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForms();
    this.getProjet();
    this.getStructure();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.structureForm.dirty && !this.structureInfoFormDirty;
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    this.projetId = this.route.snapshot.parent.params.id;
    this.projetService
      .getProjetObservable()
      .pipe(
        concatMap(responseProjet => {
          if (responseProjet) {
            this.projet = responseProjet;

            this.userService
              .getUserObservable()
              .pipe(this.takeUntilDestroyed())
              .subscribe(response => {
                if (response) {
                  this.utilisateur = response;
                }
              });

            return this.aapService.loadAapSubject();
          }
        })
      )
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: responseAap => {
          if (responseAap) {
            this.aap = responseAap.body;
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  getStructure(): void {
    this.structureId = this.route.snapshot.params.structureId;
    this.structureService
      .getStructuresListObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe(response => {
        if (response) {
          this.structures = response;
          this.structure = response.find(structure => structure.id === this.structureId);

          this.isStructureUpdatableByUser = this.sharedFunction.isStructureUpdatableByUser(
            this.aap,
            this.projet,
            this.structure,
            this.utilisateur
          );

          if (!this.structure.lieuRD) {
            this.structure.lieuRD = new LieuRD();
          }
          if (!this.structure.lieuRD.adresse) {
            this.structure.lieuRD.adresse = new Adresse();
          }
          this.structureForm.patchValue(this.structure.lieuRD);
          this.manageStructureInfo(this.structure.lieuRD);
          if (this.structureForm.controls['lieuRDisSiege'].value) {
            this.isSameAdress();
          }
        }
      });
  }

  createForms(): void {
    this.structureForm = this.formBuilder.group({
      lieuRDisSiege: [false],
      typeStructure: [null, Validators.required],
      siret: [null, [Validators.required, siretValidator()]],
      raisonSiret: [null, Validators.required],
      raisonSocial: [null, Validators.required],
      adresse: this.formBuilder.group({
        codePays: [null, Validators.required],
        pays: [null, Validators.required],
        cp: [null, Validators.required],
        ville: [null, Validators.required],
        mentionDistribution: [null],
        hameau: [null],
        numero: [null, Validators.required],
        complement: [null],
        typeVoie: [null, Validators.required],
        voie: [null, Validators.required],
        complementInfo: [null],
        divisionAdministrative: [null],
      }),
    });
  }

  setStructureDirty(event: boolean) {
    this.structureInfoFormDirty = event;
  }

  structureInfoChanged(info: Partial<Structure>): void {
    this.structureForm.patchValue(info);
    this.manageStructureInfo(info);
  }

  submitForm(formStructure: any): void {
    if (this.isStructureUpdatableByUser) {
      if (this.structureForm.valid) {
        formStructure.raisonSocial = formStructure.raisonSocial.trim();
        if (formStructure.siret) {
          formStructure.siret = formStructure.siret.replace(/\s/g, '');
        }
        this.updateProjetConsortium(formStructure);
      } else {
        this.formSubmited = true;
        this.structureForm.markAllAsTouched();
      }
    }
  }

  updateProjetConsortium(formStructure: any): void {
    this.structureInfoFormDirty = false;
    Object.assign(this.structure.lieuRD, formStructure);
    if (this.structureForm['controls'].adresse?.value.codePays !== 'FR') {
      this.structure.lieuRD.adresse.mentionDistribution = null;
      this.structure.lieuRD.adresse.hameau = null;
      this.structure.lieuRD.adresse.numero = null;
      this.structure.lieuRD.adresse.complement = null;
      this.structure.lieuRD.adresse.typeVoie = null;
      this.structure.lieuRD.adresse.complementInfo = null;
      this.structure.lieuRD.adresse.cp = this.structure.lieuRD.adresse.cp.trim();
    }
    if (formStructure.raisonSiret) {
      this.structure.lieuRD.siret = null;
    } else {
      this.structure.lieuRD.raisonSiret = null;
    }

    if (!this.structureEnCreation) {
      this.structure.lieuRD.adresse.cp = formStructure.adresse.cp.trim();
    } else {
      this.structure.lieuRD.adresse = new Adresse();
      this.structure.lieuRD.adresse.cp = formStructure.adresse.cp.trim();
      this.structure.lieuRD.adresse.ville = formStructure.adresse.ville;
      this.structure.lieuRD.typeStructure = null;
    }
    this.structureService
      .updateLieuRD(this.structure.id, this.structure.lieuRD)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: response => {
          if (response) {
            this.showToastrService.success('La structure ' + this.structure.lieuRD.raisonSocial + ' a bien été modifiée');
            this.structureForm.reset();
            this.formSubmited = false;
            this.structureService.setUniqueStructureListObservable(response.body, this.structures);
            this.onGoToConsortium();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  isSameAdress(): void {
    if (this.structure.adresse?.cp) {
      if (this.structureForm.controls['lieuRDisSiege'].value) {
        this.structureEnCreation = false;
        this.structureForm.patchValue({
          typeStructure: this.structure.typeStructure,
          siret: this.structure.siret
            ?.replace(/\s/g, '')
            .replace(/.{13}(?=.)/, m => m.replace(/.{10}(?=.)/, n => n.replace(/.{3}(?=.)/g, '$& '))),
          raisonSiret: this.structure.raisonSiret,
          raisonSocial: this.structure.raisonSocial,
          adresse: {
            codePays: this.structure.adresse.codePays,
            pays: this.structure.adresse.pays,
            cp: this.structure.adresse.cp,
            ville: this.structure.adresse.ville,
            mentionDistribution: this.structure.adresse.mentionDistribution,
            hameau: this.structure.adresse.hameau,
            numero: this.structure.adresse.numero,
            complement: this.structure.adresse.complement,
            typeVoie: this.structure.adresse.typeVoie,
            voie: this.structure.adresse.voie,
            complementInfo: this.structure.adresse.complementInfo,
          },
        });

        this.refreshInfoStructureForm();
        this.isAdresseRdSameAsAdresseStructure = true;
        this.structureForm.controls['lieuRDisSiege'].enable();
      } else {
        this.isAdresseRdSameAsAdresseStructure = false;
        this.changeStructureInfoFormStatus$.next(false);
        if (this.structureForm.controls['raisonSiret'].value) {
          this.structureForm.controls['siret'].disable();
          this.manageStructureInfo(this.structure.lieuRD);
        } else {
          this.structureForm.controls['raisonSiret'].disable();
        }
      }
    } else {
      this.showToastrService.error("Vous devez renseigner au préalable l'adresse de votre siège.");
      this.structureForm.controls['lieuRDisSiege'].reset();
    }
  }

  refreshInfoStructureForm(): void {
    this.updatedStructureInfoForm$.next({
      typeStructure: this.structure.typeStructure,
      siret: this.structure.siret
        ?.replace(/\s/g, '')
        .replace(/.{13}(?=.)/, m => m.replace(/.{10}(?=.)/, n => n.replace(/.{3}(?=.)/g, '$& '))),
      raisonSiret: this.structure.raisonSiret,
      raisonSocial: this.structure.raisonSocial,
    });

    this.changeStructureInfoFormStatus$.next(true);
  }

  /*
   * Permet de retourner à la page d'information consortium
   * */
  onGoToConsortium(): void {
    this.router.navigate(['projet-creation', this.projetId, 'projet-consortium-info', this.structureId]);
  }

  manageStructureInfo(info: Partial<Structure>): void {
    this.structureEnCreation = info.raisonSiret === EnumMotifNonRattachement.EN_COURS_DE_CREATION;
    if (this.structureEnCreation) {
      this.structureForm.controls['typeStructure'].disable();
      this.structureForm.controls['adresse'].disable();
      this.structureForm.controls['adresse'].get('cp').enable();
      this.structureForm.controls['adresse'].get('ville').enable();
    } else {
      this.structureForm.controls['typeStructure'].enable();
      this.structureForm.controls['adresse'].enable();
    }
    this.manageSiretAndRaisonSiret(info?.raisonSiret);
  }

  manageSiretAndRaisonSiret(raisonSiret: EnumMotifNonRattachement): void {
    if (raisonSiret) {
      this.structureForm.controls['raisonSiret'].enable();
      this.structureForm.controls['siret'].disable();
    } else if (!this.structureEnCreation) {
      this.structureForm.controls['siret'].enable();
      this.structureForm.controls['raisonSiret'].disable();
    }
  }

  protected readonly EnumMotifNonRattachement = EnumMotifNonRattachement;
}
