export enum EnumTypeDocument {
  PROJET = 'PROJET',
  STRUCTURE = 'STRUCTURE',
  STRUCTURE_ACTIONARIAL = 'STRUCTURE_ACTIONARIAL',
  POLE = 'POLE',
  NOTATION_EVALUATION = 'NOTATION_EVALUATION',
  NOTATION_AUDITION = 'NOTATION_AUDITION',
  CV = 'CV',
  ALERTE_FINANCIERE = 'ALERTE_FINANCIERE',
  MEDIA_VIDEO = 'MEDIA_VIDEO',
  DEMANDE_CORRECTION = 'DEMANDE_CORRECTION',
  DOCUMENT_COMPLEMENTAIRE = 'DOCUMENT_COMPLEMENTAIRE',
  RIB = 'RIB',
  JUSTIFICATIF_FLAMINEM = 'JUSTIFICATIF_FLAMINEM',
  RAPPORT_INSTRUCTION = 'RAPPORT_INSTRUCTION',
  FICHE_DEMANDE_AIDE = 'FICHE_DEMANDE_AIDE',
  JUSTIFICATIF_VALIDATION_RIB = 'JUSTIFICATIF_VALIDATION_RIB',
  CONTRAT = 'CONTRAT',
  MANDAT = 'MANDAT',
  DOCUMENT_NON_FOURNI = 'DOCUMENT_NON_FOURNI',
  SUIVI = 'SUIVI_ETAPE_CLE',
}
