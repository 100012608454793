import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PxlOidcService } from '@services-candidat/pxl-oidc.service';
import { Utilisateur } from '@shared-ui';

@Injectable({ providedIn: 'root' })
export class NoAuthGuard {
  utilisateur: Utilisateur = new Utilisateur();
  url: string;

  constructor(private router: Router, private oidcSecurityService: PxlOidcService) {}

  canActivate(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      map(({ isAuthenticated }) => {
        if (isAuthenticated) {
          this.router.navigate(['/espace']);
          return false;
        }
        return true;
      })
    );
  }
}
