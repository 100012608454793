import { UtilisateurInterface } from '../interfaces/utilisateur.interface';
import { EnumUserGroup } from '../enums/enum.userGroup';
import { Permission } from './permission.model';

export class Utilisateur implements UtilisateurInterface {
  id: string;
  matricule: string;
  matriculeIsam: string;
  email: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  groupe: EnumUserGroup;
  cgus: any;
  perms: Permission[] = [];
  dateDernierLogin: Date;
  dateCreation: Date;
  dateModification: Date;
  aaps: any[];
  prenom: string;
  nom: string;
  lastNps: Date;
  favoriteAapIds: string[] = [];
}
