<!-- Entête de la page -->
<div class="page-header">
  <h2 id="titre_page" class="page-header__title">Contrat</h2>
</div>

<div class="page-content">
  <div class="mb-3" *ngFor="let synthese of syntheses">
    <lib-card-text [inputText]="synthese.raisonSocial" (clickEmitter)="onGoToStructure(synthese.id)"></lib-card-text>
  </div>
</div>
