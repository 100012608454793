import { Injectable } from '@angular/core';
import { ContractualisationService } from '@services-candidat/contractualisation.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContractualisationGuard {
  constructor(private contractualisationService: ContractualisationService) {}

  canActivate(): Observable<boolean> {
    return this.contractualisationService.canUserSeeContractPage();
  }
}
