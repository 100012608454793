import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-table-dialog-edit',
  templateUrl: './table-dialog-edit.component.html',
  styleUrls: ['./table-dialog-edit.component.scss'],
})
export class TableDialogEditComponent {
  public selected: string;

  constructor(public dialogRef: MatDialogRef<TableDialogEditComponent>, @Inject(MAT_DIALOG_DATA) public modalData: any) {}

  closeDialog() {
    this.dialogRef.close(this.selected);
  }
}
