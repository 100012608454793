import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Aap,
  CommentaireModalComponent,
  DocumentConfigModel,
  DocumentProjet,
  EnumDocumentComplementaireType,
  EnumProjetStatut,
  EnumScope,
  EnumTypeDocument,
  Projet,
  UploadDocModalResultModel,
  Utilisateur,
  UtilisateurUtils,
} from '@shared-ui';

@Component({
  selector: 'lib-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent {
  @Input() scope: string;
  @Input() documents: DocumentProjet[];
  @Input() documentConfigs: DocumentConfigModel[];
  @Input() aap: Aap;
  @Input() projet: Projet;
  @Input() projectEtapeName: any;
  @Input() canUserWrite: boolean;
  @Input() utilisateur: Utilisateur;
  @Input() showTag: boolean;
  @Input() hidden?: boolean;
  @Input() fileTooBig?: boolean;
  @Input() fileNotSupported?: boolean;
  @Input() connaissanceClientFileNotSupported?: boolean;
  @Input() videoTooBig?: boolean;
  @Input() nameFileNotSupported?: string[];
  @Input() isCandidat = false;
  @Input() showEdit = true;

  @Output() uploadDocument = new EventEmitter<UploadDocModalResultModel>();
  @Output() deleteDocument: EventEmitter<DocumentProjet> = new EventEmitter<DocumentProjet>();

  private static readonly VIDEO_EXTENSIONS = '.mp4, .wmv, .mov, .flv, .avi, .3gp';
  private static readonly DOC_EXTENSIONS = '.pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx';
  private static readonly CONNAISSANCE_CLIENT_EXTENSIONS = '.pdf, .gif, .png, .jpg, .jpeg, .bmp, .tif, .tiff';

  accept: string | undefined;

  error = false;
  errorName: string;

  constructor(public matDialog: MatDialog) {}

  canDownload(document: DocumentProjet): boolean {
    return (
      // is project document
      this.scope === EnumScope.PROJET ||
      // is User interne
      UtilisateurUtils.hasUserAnyGroupe(this.utilisateur) ||
      // Is user the owner of the document
      this.utilisateur.matricule === document.createur
    );
  }

  canSeeCommentaire(docAap: DocumentConfigModel): boolean {
    const document = this.documents.find(document => {
      return (
        document.typeDoc === EnumTypeDocument.DOCUMENT_NON_FOURNI &&
        (document?.demandeComplementId
          ? EnumDocumentComplementaireType.toString(document.type) === docAap.nomDocument
          : document.type === docAap.nomDocument)
      );
    });

    return (
      (this.scope !== EnumScope.STRUCTURE && this.scope !== EnumScope.STRUCTURE_ACTIONARIAL) ||
      this.utilisateur.matricule === document?.createur
    );
  }

  onDeleteDocument(document: DocumentProjet) {
    this.documents = this.documents.filter(doc => doc.id !== document.id);
    this.deleteDocument.emit(document);
  }

  formatAccept(doc: DocumentConfigModel) {
    if (doc?.connaissanceClient) {
      this.accept = DocumentListComponent.CONNAISSANCE_CLIENT_EXTENSIONS;
    } else if (doc?.mediaTypes?.includes(EnumTypeDocument.MEDIA_VIDEO)) {
      this.accept = DocumentListComponent.VIDEO_EXTENSIONS;
    } else {
      this.accept = DocumentListComponent.DOC_EXTENSIONS;
    }
    return this.accept;
  }

  onFileDropped($event: any, doc: DocumentConfigModel) {
    this.formatAccept(doc);
    this.prepareFilesList($event, doc);
  }

  fileBrowseHandler(event: Event, doc: DocumentConfigModel) {
    const target = event.target as HTMLInputElement;
    const file: FileList = target.files as FileList;
    this.formatAccept(doc);
    this.prepareFilesList(file, doc);
  }

  checkAtLeastOneDocUploaded(docAap: DocumentConfigModel): boolean {
    return this.documents.some(document => {
      return document?.demandeComplementDocumentId
        ? document.demandeComplementDocumentId === docAap.demandeComplementDocumentId
        : document.type === docAap.nomDocument;
    });
  }

  checkDocNonFourni(docAap: DocumentConfigModel): boolean {
    return this.documents.some(document => {
      return (
        document.typeDoc === EnumTypeDocument.DOCUMENT_NON_FOURNI &&
        (document?.demandeComplementId
          ? EnumDocumentComplementaireType.toString(document.type) === docAap.nomDocument
          : document.type === docAap.nomDocument)
      );
    });
  }

  getPasDeDocCommentaire(docAap: DocumentConfigModel): string {
    const document = this.documents.find(document => {
      return (
        document.typeDoc === EnumTypeDocument.DOCUMENT_NON_FOURNI &&
        (document?.demandeComplementId
          ? EnumDocumentComplementaireType.toString(document.type) === docAap.nomDocument
          : document.type === docAap.nomDocument)
      );
    });
    return document?.commentaire || '';
  }

  prepareFilesList(file: FileList, doc: any) {
    this.error = false;
    doc = {
      nom: doc.nomDocument,
      mediaTypes: doc.mediaTypes,
      connaissanceClient: doc.connaissanceClient,
      demandeComplementId: doc?.demandeComplementId,
      demandeComplementDocumentId: doc?.demandeComplementDocumentId,
      codeGed: doc?.codeGed,
      domaineGed: doc?.domaineGed,
    };

    if (doc && file) {
      const result: UploadDocModalResultModel = {
        document: doc,
        files: file,
      };

      if (!this.fileNotSupported || !this.fileTooBig || !this.videoTooBig || !this.connaissanceClientFileNotSupported) {
        this.error = true;
        this.errorName = result.document.nom;
      }
      this.documents = this.documents.filter(doc => doc.typeDoc !== EnumTypeDocument.DOCUMENT_NON_FOURNI);
      this.uploadDocument.emit(result);
    }
  }

  openModalNoDocument(doc: any): void {
    if (!this.isCandidat) {
      return;
    }

    const dialogRef = this.matDialog.open(CommentaireModalComponent, {
      data: {
        fieldInput: this.getPasDeDocCommentaire(doc),
        title: "Je n'ai pas ce document",
        description: 'Commentaire',
        placeholder: 'Saisir votre texte ici.',
        maxLength: 250,
        textGoButton: 'Enregistrer',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe((commentaire: string) => {
      if (!commentaire) {
        return;
      }

      doc = {
        nom: doc.nomDocument,
        mediaTypes: doc.mediaTypes,
        connaissanceClient: doc.connaissanceClient,
        demandeComplementId: doc?.demandeComplementId,
        codeGed: doc?.codeGed,
        domaineGed: doc?.domaineGed,
      };

      const result: UploadDocModalResultModel = {
        document: doc,
        commentaire: commentaire,
      };

      this.uploadDocument.emit(result);
    });
  }

  protected readonly EnumProjetStatut = EnumProjetStatut;
  protected readonly EnumTypeDocument = EnumTypeDocument;
  protected readonly EnumDocumentComplementaireType = EnumDocumentComplementaireType;
}
