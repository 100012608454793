<!-- Entête de la page -->
<header id="header-rib-iban" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToConsortiumInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Ma Structure
  </a>
  <h2 class="page-header__title">RIB - IBAN</h2>
</header>

<!-- Corps de la page -->
<div class="page-content">
  <div class="card--info card--blue card--readonly" *ngIf="!canUserWrite">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    Vous êtes en lecture seule sur cette page. Vous ne pouvez pas éditer son contenu.
  </div>

  <section class="page-section">
    <div class="card">
      <p *ngIf="informationsBancairesDeposant.length === 0">Il n'y aucun IBAN renseigné</p>
      <ng-container *ngFor="let informationBancaire of informationsBancairesDeposant">
        <div
          class="card information-bancaire-card"
          (click)="selectInformationBancaire(informationBancaire)"
          [ngClass]="{
            checked: informationBancaire.selectionne,
            disabled: informationBancaire.statutCertification === EnumStatutCertificationIban.CERTIFICATION_REJETEE_BPI
          }"
        >
          <div class="information-bancaire-content">
            <div class="segment">
              <h4>
                {{ informationBancaire.libelle }}
                <button
                  *ngIf="canEditInformationBancaire(informationBancaire)"
                  matTooltip="Modifier"
                  class="edit-btn"
                  (click)="editInformationBancaire(informationBancaire); $event.stopPropagation()"
                >
                  <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
                </button>
              </h4>
              <span>{{ informationBancaire.iban | iban }}</span>
              <span>{{ informationBancaire.bic }}</span>
              <span>{{ informationBancaire.nomBanque }}</span>
              <span>
                {{ informationBancaire.nomAgence }}
                <ng-container *ngIf="informationBancaire.adresseAgence"> , {{ informationBancaire.adresseAgence }} </ng-container>
              </span>
              <span *ngIf="informationBancaire.auteur"
                >Renseigné par
                <span class="fw-bold">{{ informationBancaire.auteur.prenom }} {{ informationBancaire.auteur.nom }}</span>
              </span>
            </div>

            <div *ngIf="informationBancaire.commentaire" class="segment">
              <h4>Commentaire</h4>
              <span>{{ informationBancaire.commentaire }}</span>
            </div>

            <div *ngIf="getDocuments(informationBancaire, EnumTypeDocument.RIB).length > 0" class="segment">
              <h4>Fichier(s) joint(s)</h4>

              <div class="div_list_documents" (click)="$event.stopPropagation()">
                <div *ngFor="let document of getDocuments(informationBancaire, EnumTypeDocument.RIB)" class="card__action-button-wrapper">
                  <div
                    class="div_document_item"
                    [ngClass]="{
                      'card__action-button--error': isScannedDocument(document) && !isValidDocument(document),
                      disabled: !isValidDocument(document) || !isScannedDocument(document)
                    }"
                    (click)="downloadDocument(document)"
                  >
                    <lib-pxl-icon class="icon_document" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></lib-pxl-icon>
                    <span class="span_nom_document">{{ document.nom }}</span>
                  </div>
                  <div class="card__action-button-tooltip" *ngIf="!isScannedDocument(document)">
                    <p>Le test antimalware est en cours.</p>
                  </div>
                  <div class="card__action-button-tooltip" *ngIf="isScannedDocument(document) && !isValidDocument(document)">
                    <p>Ce fichier a été identifié comme étant potentiellement dangereux et ne peut donc pas être téléchargé.</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="segment tags">
              <span
                *ngIf="
                  informationBancaire.validationManuelle ||
                  informationBancaire.statutCertification === EnumStatutCertificationIban.CERTIFIE_BPI
                "
                class="ds-tag ds-tag-status--success-100"
              >
                Certifié Bpifrance
              </span>
              <span
                *ngIf="
                  !informationBancaire.validationManuelle &&
                  informationBancaire.statutCertification === EnumStatutCertificationIban.EN_COURS_DE_CERTIFICATION_BPI
                "
                class="ds-tag ds-tag-status--primary-100"
              >
                En cours de certification Bpifrance
              </span>
              <span
                *ngIf="
                  !informationBancaire.validationManuelle &&
                  informationBancaire.statutCertification === EnumStatutCertificationIban.CERTIFICATION_REJETEE_BPI
                "
                class="ds-tag ds-tag-status--danger-100"
              >
                Non certifié Bpifrance
              </span>
              <span *ngIf="informationBancaire.validationManuelle" class="ds-tag ds-tag-status--disabled-100"> Validation manuelle </span>
              <span
                *ngIf="informationBancaire.typesPaiements?.includes(EnumTypePaiement.PRELEVEMENT)"
                class="ds-tag ds-tag-status--secondary-100"
              >
                {{ EnumTypePaiement.toString(EnumTypePaiement.PRELEVEMENT) }}
              </span>
              <span
                *ngIf="informationBancaire.typesPaiements?.includes(EnumTypePaiement.VIREMENT)"
                class="ds-tag ds-tag-status--secondary-100"
              >
                {{ EnumTypePaiement.toString(EnumTypePaiement.VIREMENT) }}
              </span>
            </div>
          </div>
          <input type="radio" [value]="informationBancaire" [checked]="informationBancaire.selectionne" />
        </div>
      </ng-container>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <button *ngIf="canUserWrite" type="button" class="ds-btn ds-btn--tertiary no-iban-btn" (click)="createInformationBancaire()">
        {{ noInformationBancaire ? 'Ajouter un IBAN' : "L'IBAN n’est pas dans la liste" }}
      </button>
    </div>
  </section>
</div>
