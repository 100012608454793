import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'lib-cancel-save-validate-buttons',
  templateUrl: './cancel-save-validate-buttons.component.html',
  styleUrls: ['./cancel-save-validate-buttons.component.css'],
})
export class CancelSaveValidateButtonsComponent {
  @Output() private cancelEventEmitter = new EventEmitter();
  @Output() private saveEventEmitter = new EventEmitter();
  @Output() private validateEventEmitter = new EventEmitter();

  protected onCancel() {
    this.cancelEventEmitter.emit();
  }

  protected onSave() {
    this.saveEventEmitter.emit();
  }

  protected onValidate() {
    this.validateEventEmitter.emit();
  }
}
