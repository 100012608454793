import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DisplayColumn, LotLightModel, TableCell } from '@shared-ui';

@Component({
  selector: 'lib-depenses-projet-table-header',
  templateUrl: './depenses-projet-table-header.component.html',
  styleUrls: ['./depenses-projet-table-header.component.scss'],
})
export class DepensesProjetTableHeaderComponent {
  @Input() public cellParam: DisplayColumn;
  @Input() public infoText: string;
  @Input() set columnData(data: { map: Map<string, Set<string>>; col: string }) {
    const filterDataForCol = data.map.get(data.col);
    if (filterDataForCol) {
      this.dataList = [...filterDataForCol];
    }
  }

  @Input() set updateFilter(taches: LotLightModel[]) {
    if (this.cellParam.filterEnable && taches?.length > 0) {
      const result = taches.map((tache: LotLightModel) => (tache[this.cellParam.def as keyof LotLightModel] as TableCell)?.value + '');
      this.dataList = [...new Set(result)];
    }
    this.allChecked = false;
  }

  @Output() public filterChange: EventEmitter<{ columnKey: string; filterValue: string[] }> = new EventEmitter();
  @Output() public actionClicked: EventEmitter<string> = new EventEmitter();
  @Output() public selectAllClicked: EventEmitter<boolean> = new EventEmitter();

  public filterValues: string[] = [];
  public dataList: string[] = [];
  public allChecked = false;

  readonly ALL = 'Selectionner tout';
  readonly DUPLIQUER = 'Dupliquer';
  readonly SUPPRIMER = 'Supprimer';
  readonly MODIFIER = 'Modifier';

  onChange($event: MatCheckboxChange) {
    if ($event.checked) {
      this.filterValues.push($event.source.value);
    } else {
      this.filterValues = this.filterValues.filter(value => value !== $event.source.value);
    }
    this.filterChange.emit({ columnKey: this.cellParam?.def, filterValue: this.filterValues });
  }

  onActionClicked(actionType: string) {
    this.actionClicked.emit(actionType);
  }

  onSelectAll() {
    this.allChecked = !this.allChecked;
    this.selectAllClicked.emit(this.allChecked);
  }
}
