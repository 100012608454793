import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Feature } from '../models/feature.model';
import { Environment } from '../models/environment.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(
    @Inject('appConfig') private appConfig: any,
    @Inject('environment') private environment: Environment,
    private http: HttpClient
  ) {}

  featureOn(featureName: string): boolean {
    return !this.featureOff(featureName);
  }

  featureOff(featureName: string): boolean {
    const ff: Feature[] = this.appConfig.features?.filter((ff: Feature) => featureName === ff.name);
    return !ff || !ff.length || !ff[0].enabled; // if feature not found, default to turned off
  }

  getFeatures(): Observable<Feature[]> {
    return of(this.appConfig.features);
  }

  getAllFeatures(): Observable<Feature[]> {
    return this.http.get<Feature[]>(`${this.environment.apiUrl}features`);
  }
  
  updateFeatureState(featureId: string, state: boolean): Observable<Feature> {
    const ff: Feature[] = this.appConfig.features?.filter(
      (ff: Feature) => featureId === ff.id && ff.site === this.environment.site?.toUpperCase()
    );
    if (ff.length) {
      ff[0].enabled = state;
    }
    return this.http.patch<Feature>(`${this.environment.apiAdminUrl}features/${featureId}`, state);
  }
}
