import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments-candidat/environment';
import { SuiviProjet } from '@shared-ui';

@Injectable()
export class SuiviProjetService {
  private readonly projetSuiviUrl = environment.apiSuiviUrl + 'suivi-projets';
  private readonly projetSubject = new BehaviorSubject<SuiviProjet>(null);

  constructor(private httpClient: HttpClient) {}

  getById(id: string): Observable<SuiviProjet> {
    return this.httpClient.get<SuiviProjet>(`${this.projetSuiviUrl}/${id}`).pipe(tap(p => this.projetSubject.next(p)));
  }
}
