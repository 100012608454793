import { Injectable } from '@angular/core';
import {
  EnumProcedureSignatureStatut,
  EnumStatutContractualisation,
  EnumStatutSignature,
  EnumTypeSignataire,
  ProcedureSignatureStructure,
  SyntheseContractualisationProjet,
  SyntheseContractualisationStructure,
  Utilisateur,
} from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class StructureSyntheseHelperService {
  retrieveLastDateSignatureElectroniqueConsortium(currentProcedure: ProcedureSignatureStructure): Date | null {
    const datesSignatures = currentProcedure.signataires
      ?.filter(signataire => !!signataire.signature)
      .map(signataire => signataire.signature.dateSignature)
      .filter(dateSignature => !!dateSignature);
    if (datesSignatures?.length > 0) {
      return datesSignatures.reduce((latest, item) => (item > latest ? item : latest));
    }
    return null;
  }

  sortProcedures(procedures: ProcedureSignatureStructure[]): void {
    procedures?.sort(this.compareProcedures);
  }

  private compareProcedures(procedure1: ProcedureSignatureStructure, procedure2: ProcedureSignatureStructure): number {
    if (!procedure1.dateCreation) {
      return -1;
    } else if (!procedure2.dateCreation) {
      return 1;
    }

    return new Date(procedure1.dateCreation).getTime() - new Date(procedure2.dateCreation).getTime();
  }

  getCurrentProcedure(structureSynthese: SyntheseContractualisationStructure): ProcedureSignatureStructure | null {
    if (!structureSynthese.proceduresSignatures?.length) {
      return null;
    }

    this.sortProcedures(structureSynthese.proceduresSignatures);
    return structureSynthese.proceduresSignatures[structureSynthese.proceduresSignatures.length - 1];
  }

  getNbSignataire(synthese: SyntheseContractualisationStructure): number {
    return (
      synthese.proceduresSignatures?.find(procedure => procedure.statut === EnumProcedureSignatureStatut.EN_COURS)?.signataires?.length || 0
    );
  }

  getNbSignatureEffectuee(synthese: SyntheseContractualisationStructure): number {
    const currentProcedureSignataires = synthese.proceduresSignatures?.find(
      procedure => procedure.statut === EnumProcedureSignatureStatut.EN_COURS
    )?.signataires;
    const allowedSignatairesType = [
      EnumTypeSignataire.SIGNATAIRE_EXTERNE,
      EnumTypeSignataire.SIGNATAIRE_INTERNE,
      EnumTypeSignataire.SOLIDAIRE_EXTERNE,
    ];
    return (
      currentProcedureSignataires?.filter(
        signataire => allowedSignatairesType.includes(signataire.type) && signataire.signature?.statut === EnumStatutSignature.TERMINEE
      )?.length || 0
    );
  }

  initSyntheseStructureCustomFields(structureSynthese: SyntheseContractualisationStructure, utilisateur?: Utilisateur): void {
    const currentProcedure = this.getCurrentProcedure(structureSynthese);
    if (currentProcedure) {
      structureSynthese.isProcedureEnCours = currentProcedure.statut === EnumProcedureSignatureStatut.EN_COURS;
      structureSynthese.isProcedureTerminee = currentProcedure.statut === EnumProcedureSignatureStatut.TERMINEE;
      structureSynthese.isUserASigner = utilisateur ? this.isUserASigner(currentProcedure, utilisateur.email) : undefined;
    }
  }

  isProcedureEnCours(synthese: SyntheseContractualisationStructure): boolean {
    return this.getCurrentProcedure(synthese)?.statut === EnumProcedureSignatureStatut.EN_COURS;
  }

  isSignataireSelectedInOngoingProcedure(scStructure: SyntheseContractualisationStructure, signataireId: string): boolean {
    const ongoingProcedure = scStructure.proceduresSignatures.find(p => p.statut === EnumProcedureSignatureStatut.EN_COURS);
    return ongoingProcedure !== undefined && ongoingProcedure.signataires.some(signataire => signataire.id === signataireId);
  }

  isProcedureSignatureLunchable(
    syntheseProjet: SyntheseContractualisationProjet,
    syntheseStructure: SyntheseContractualisationStructure
  ): boolean {
    return (
      !!syntheseStructure.contractualisation?.validationDocumentsContrat &&
      syntheseStructure.contractualisation?.statutSignatairesExternes === EnumStatutContractualisation.VALIDE &&
      syntheseProjet?.contractualisation?.statutSignatairesInternes === EnumStatutContractualisation.VALIDE
    );
  }

  private isUserASigner(currentProcedure: ProcedureSignatureStructure | null, email: string) {
    return currentProcedure?.signataires.some(signataire => signataire.email === email);
  }

  getCurrentSigner(currentProcedure: ProcedureSignatureStructure | null, utilisateur: Utilisateur) {
    return currentProcedure?.signataires.find(signataire => signataire.email === utilisateur.email);
  }
}
