import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Aap,
  DocumentAapModel,
  DocumentConfigModel,
  DocumentHelper,
  DocumentProjet,
  EnumScope,
  enumValues,
  FileUtils,
  SharedFunction,
  Structure,
} from '@shared-ui';

export interface ModalData {
  title: string;
  structures: Structure[];
  documentConfigs: DocumentConfigModel[];
  projet: any;
  aap: Aap;
  textGoButton: string;
  textReturnButton: string;
  document: DocumentProjet;
}

@Component({
  selector: 'lib-change-document-modal',
  templateUrl: './change-document-modal.component.html',
  styleUrls: ['./change-document-modal.component.scss'],
})
export class ChangeDocumentModalComponent implements OnInit {
  projectToggleState = false;
  toggleLabels: string[];
  changeDocForm: UntypedFormGroup;
  documentsAapForPrj: DocumentAapModel[] = [];
  documentsAapForStr: DocumentAapModel[] = [];
  documentsAapForStrActio: DocumentAapModel[] = [];
  constructor(
    public dialogRef: MatDialogRef<ChangeDocumentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    private formBuilder: UntypedFormBuilder,
    public sharedFunction: SharedFunction
  ) {
    this.toggleLabels = enumValues(ToggleValues);
  }

  ngOnInit(): void {
    this.createForms();
    this.setListDocProjet();
  }

  get projetControl() {
    return this.changeDocForm.controls['projet'];
  }

  get structureCibleControl() {
    return this.changeDocForm.controls['structureCible'];
  }

  get documentAapCibleControl() {
    return this.changeDocForm.controls['documentAapCible'];
  }

  createForms(): void {
    if (this.changeDocForm) {
      this.changeDocForm.reset();
      this.changeDocForm.updateValueAndValidity();
    }
    if (!this.projectToggleState) {
      this.changeDocForm = this.formBuilder.group({
        projet: [null, Validators.required],
      });
    } else {
      this.changeDocForm = this.formBuilder.group({
        structureCible: [null, Validators.required],
        documentAapCible: [null, Validators.required],
      });
    }
  }

  submitForm(changeDocForm: UntypedFormGroup): void {
    if (changeDocForm.valid) {
      this.dialogRef.close(changeDocForm.value);
    } else {
      this.changeDocForm.markAllAsTouched();
    }
  }

  onChangeToggle($event: string): void {
    this.projectToggleState = $event === ToggleValues.STRUCTURES;
    this.createForms();
  }

  setListDocProjet() {
    const etapeProjet = this.modalData.projet.etapes[this.modalData.projet.etapes.length - 1].nom;
    const allDocsAapForProjet = this.modalData.aap.documentAaps
      ?.filter(
        document =>
          document.etapes.includes(etapeProjet) &&
          document.typePartenaires?.includes(this.modalData.projet.partenaireType) &&
          document.scope === EnumScope.PROJET
      )
      .sort((a, b) => (a.nomDocument > b.nomDocument ? 1 : -1));

    this.documentsAapForPrj = this.filterDocumentsByExtension(allDocsAapForProjet);
  }

  refreshDocumentsAapForSelectedStructure() {
    if (this.projectToggleState) {
      const strSelected = this.structureCibleControl.value;
      const allDocsAapForStr = this.sharedFunction.getDocumentsAapForStructure(this.modalData.aap, strSelected, this.modalData.projet, EnumScope.STRUCTURE);
      const allDocsAapForStrActio = this.sharedFunction.getDocumentsAapForStructure(this.modalData.aap, strSelected, this.modalData.projet, EnumScope.STRUCTURE_ACTIONARIAL);

      this.documentsAapForStr = this.filterDocumentsByExtension(allDocsAapForStr);
      this.documentsAapForStrActio = this.filterDocumentsByExtension(allDocsAapForStrActio);
    }
  }

  /**
   * Filters the given list of documents based on the file extension and the `connaissanceClient` property.
   *
   * The method first retrieves the file extension of the current document. It then checks if the extension
   * is available in either the `KYC_EXTENSIONS` or `FINNO_EXTENSIONS` list. Based on this check, it filters
   * the documents:
   * - If the extension is in `KYC_EXTENSIONS` and the document is a `connaissanceClient` document.
   * - If the extension is in `FINNO_EXTENSIONS` and the document is not a `connaissanceClient` document.
   */
  filterDocumentsByExtension(documentsAap: DocumentAapModel[]): DocumentAapModel[] {
    const documentExtension = FileUtils.getFileExtension(this.modalData.document.nom);
    const isKycExtension = FileUtils.KYC_EXTENSIONS.includes(documentExtension);
    const isFinnoExtension = FileUtils.FINNO_EXTENSIONS.includes(documentExtension);
    const isVideoExtension = FileUtils.VIDEO_EXTENSIONS.includes(documentExtension);

    return documentsAap.filter(
      docAap => {
        const docAapIsVideo = DocumentHelper.isVideoMediaTypes(docAap.mediaTypes);
        const kycAvailable = isKycExtension && docAap.connaissanceClient && !docAapIsVideo;
        const finnoAvailable = isFinnoExtension && !docAap.connaissanceClient && !docAapIsVideo;
        const videoAvailable = isVideoExtension && docAapIsVideo;
        return kycAvailable || finnoAvailable || videoAvailable;
      });
  }

  protected readonly ToggleValues = ToggleValues;
}

enum ToggleValues {
  PROJET = 'Projet',
  STRUCTURES = 'Structure(s)',
}
